import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleMessage } from 'shared/api/ErrorMessages';
import { deleteUserAPI, getAllUsersAPI, getUserAPI, saveUserAPI, updateUserAPI } from 'services/userManagement';

interface Error {
    data: any;
  }
    
export const getAllUsers = createAsyncThunk(
  'GET_ALL_USERS',
  async (data: any,  { rejectWithValue }) => {
    try {
      const response = await getAllUsersAPI();
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);
  
export const getUser = createAsyncThunk(
  'GET_USER',
  async (id: any,  { rejectWithValue }) => {
    try {
      const response = await getUserAPI(id);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const saveUser = createAsyncThunk(
  'SAVE_USER',
  async (data: any,  { rejectWithValue }) => {
    try {
      const response = await saveUserAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  'UPDATE_USER',
  async (data: any,  { rejectWithValue }) => {
    try {
      const response = await updateUserAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const deleteUser = createAsyncThunk(
  'DELETE_USER',
  async (id: any,  { rejectWithValue }) => {
    try {
      const response = await deleteUserAPI(id);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);