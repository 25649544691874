import { apiInterceptor } from './axios';
import { API_URL } from 'shared/api/Constants';
  
export const getTransactionHistoryAPI=(patientId:any)=>{
  return apiInterceptor.get(`${API_URL.GET_PAYMENT_HISTORY}/${patientId}`)
    .then((response:any) => {
      return response;
    });
};

export const chargePaymentAPI=(data:any)=>{
  return apiInterceptor.post(`${API_URL.CHARGE_PAYMENT}`, data)
    .then((response:any) => {
      return response;
    });
};

export const paymentActionAPI=(data:any)=>{
  return apiInterceptor.post(`${API_URL.PAYMENT_ACTION}`, data)
    .then((response:any) => {
      return response;
    });
};