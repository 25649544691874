import React, { useEffect, useState } from 'react';
import { Box, Grid, InputLabel, TextField, Typography, Button, MenuItem } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { detailsSchema } from 'utils/schema';
import { addDrChronoChartDetails, getDrChronoChartDetails, 
  addPaymentStatus,  getPaymentStatus } from 'store/slices/patientInfoSlice';
import { useAppDispatch } from 'store/hooks';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { ROUTE } from 'route/RouteEnums';

const Details = (props: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [disableChartId, setDisableChartId] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(detailsSchema),
    mode: 'all',
  });

  useEffect(() => {
    dispatch(getDrChronoChartDetails(props.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          const chartId = response?.data?.chartId === 'Not Available'  ? '' : response?.data?.chartId;
          setValue('chartId', chartId, { shouldValidate: false, shouldDirty: false });
          setValue('pricingPlan', response.data.pricingPlan, { shouldValidate: false, shouldDirty: false });
          if (response?.data?.chartId) {
            setDisableChartId(true); 
          }
        }
      });

    dispatch(getPaymentStatus(props.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200 && response.data !== null) {
          setValue('creditCardAvailable', response.data.creditCardOnfileStatus, 
            { shouldValidate: false, shouldDirty: false });
          setValue('creditCardStatus', response.data.creditCardStatus, { shouldValidate: false, shouldDirty: false });
          setValue('billingStatus', response.data.billingStatus, { shouldValidate: false, shouldDirty: false });
          setValue('insCardStatus', response.data.insuranceCardStatus, { shouldValidate: false, shouldDirty: false });
          setValue('coPayAmount', response.data.coPayAmount, {shouldValidate:false, shouldDirty:false});  
        }
      });
    //eslint-disable-next-line
  }, []);

  const getPayload = (fromData: any) => {
    return {
      patientId: props.patientId,
      ...fromData,
    };
  };

  const onSubmitForm = (formData: any) => {
    const payload = getPayload(formData);
    dispatch(addDrChronoChartDetails(payload))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          setDisableChartId(true);
          const message = 'Patient details updated successfully.';
          toast(message, {
            type: 'success',
            icon: true,
          });
          reset(formData);
        }
      });

    dispatch(addPaymentStatus({
      'patientId': props.patientId,
      'creditCardOnfileStatus': watch('creditCardAvailable') ? watch('creditCardAvailable') : '',
      'creditCardStatus': watch('creditCardStatus') ? watch('creditCardStatus') : '',
      'billingStatus': watch('billingStatus') ? watch('billingStatus') : '',
      'insuranceCardStatus': watch('insCardStatus') ? watch('insCardStatus') : '',
      'coPayAmount': watch('coPayAmount') ? watch('coPayAmount') : ''
    }))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          setDisableChartId(true);
          const message = 'Patient details updated successfully.';
          toast(message, {
            type: 'success',
            icon: true,
          });
          reset(formData);
        }
      });
  };

  return (
    <Box component='form' width={'100%'} onSubmit={handleSubmit((formData) => onSubmitForm(formData))}>
      <Grid container spacing={2}>
        <Grid item xs={12} display={'flex'} justifyContent='space-between' alignItems={'center'}>
          <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500', lineHeight: 1.4 }}>
            Details
          </Typography>
          <Button
            sx={{ fontSize: 14 }}
            variant='text'
            onClick={() => navigate(ROUTE.ADMIN_PATIENT_PROFILE, { state: { patientId: props.patientId } })}
          >
            VIEW PATIENT PROFILE
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor='chartId'>Dr Chrono Chart ID<span style={{color:'red'}}>*</span></InputLabel>
          <TextField
            fullWidth
            id='email'
            placeholder='CRMA000000'
            autoComplete='chartId'
            {...register('chartId')}
            disabled={disableChartId}
            error={errors?.chartId ? true : false}
            helperText={errors?.chartId ? errors?.chartId?.message : ''}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor='pricingPlan'>Care Plan</InputLabel>
          <TextField
            id='pricingPlan'
            select // tell TextField to render select
            fullWidth
            {...register('pricingPlan')}
            value={watch('pricingPlan') ? watch('pricingPlan') : ''}
            error={errors?.pricingPlan ? true : false}
            helperText={errors?.pricingPlan ? errors?.pricingPlan?.message : ''}
          >
            <MenuItem key={0} value='select_treatment_plan' disabled>
              Select Care Plan
            </MenuItem>
            <MenuItem key={1} value='Psychiatry'>
              Psychiatry
            </MenuItem>
            <MenuItem key={2} value='Therapy'>
              Therapy
            </MenuItem>
            <MenuItem key={3} value='Psychiatry & Therapy'>
              Psychiatry & Therapy
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor='creditCardAvailable'>Credit Card On File</InputLabel>
          <TextField
            id='creditCardAvailable'
            select // tell TextField to render select
            fullWidth
            {...register('creditCardAvailable')}
            value={watch('creditCardAvailable') ? watch('creditCardAvailable') : ''}
            error={errors?.creditCardAvailable ? true : false}
            helperText={errors?.creditCardAvailable ? errors?.creditCardAvailable?.message : ''}
          >
            <MenuItem key={1} value='Yes'>
              Yes
            </MenuItem>
            <MenuItem key={2} value='No'>
              No
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor='creditCardStatus'>Credit Card Status</InputLabel>
          <TextField
            id='creditCardStatus'
            select // tell TextField to render select
            fullWidth
            {...register('creditCardStatus')}
            value={watch('creditCardStatus') ? watch('creditCardStatus') : ''}
            error={errors?.creditCardStatus ? true : false}
            helperText={errors?.creditCardStatus ? errors?.creditCardStatus?.message : ''}
          >
            <MenuItem key={1} value='Active'>
              Active
            </MenuItem>
            <MenuItem key={2} value='Inactive'>
              Inactive
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor='billingStatus'>Billing Status</InputLabel>
          <TextField
            id='billingStatus'
            select // tell TextField to render select
            fullWidth
            {...register('billingStatus')}
            value={watch('billingStatus') ? watch('billingStatus') : ''}
            error={errors?.billingStatus ? true : false}
            helperText={errors?.billingStatus ? errors?.billingStatus?.message : ''}
          >
            <MenuItem key={1} value='Profit Recovery'>
              Profit Recovery
            </MenuItem>
            <MenuItem key={2} value='Collections'>
              Collections
            </MenuItem>
            <MenuItem key={2} value='Current'>
              Current
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor='insCardStatus'>Payment Type</InputLabel>
          <TextField
            id='insCardStatus'
            select // tell TextField to render select
            fullWidth
            {...register('insCardStatus')}
            value={watch('insCardStatus') ? watch('insCardStatus') : ''}
            error={errors?.insCardStatus ? true : false}
            helperText={errors?.insCardStatus ? errors?.insCardStatus?.message : ''}
          >
            <MenuItem key={1} value='Insurance Card Active'>
              Insurance Card Active
            </MenuItem>
            <MenuItem key={2} value='Insurance Card Inactive'>
              Insurance Card Inactive
            </MenuItem>
            <MenuItem key={2} value='Out of Pocket'>
              Out of Pocket
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor='coPayAmount'>Insurance Co-Pay Amount</InputLabel>
          <TextField
            id='coPayAmount'
            fullWidth
            {...register('coPayAmount')}
            inputProps={{maxLength:6}}
            value={watch('coPayAmount') ? watch('coPayAmount') : ''}
            error={errors?.coPayAmount ? true : false}
            helperText={errors?.coPayAmount ? errors?.coPayAmount?.message : ''}
          />
        </Grid>
        <Grid item xs={12} display='flex' justifyContent={{ xs: 'center', sm: 'end' }}>
          <Button sx={{ minWidth: { xs: 200, md: 150 } }} disabled={!isDirty} type='submit'>
            SAVE
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Details;
