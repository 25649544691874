import React, { createContext, useContext, useEffect } from 'react';
import { useSessionStorage } from 'route/SessionStorage';
import Chatbox from 'components/Chatbox';
import { CometChat } from '@cometchat/chat-sdk-javascript';
import { CometChatUIKit } from '@cometchat/chat-uikit-react';
import { toast } from 'react-toastify';


export const AuthContext = createContext({
  loginToMindWeal: (_params: any, refreshToken:string) => {
    console.log('loginToMindWeal');
  },
  logoutFromMindWeal: () => {
    console.log('logoutFromMindWeal');
  },
  setUserDetails: (_params: any) => {
    console.log('setUserDetails');
  },
  useUserRoles: (_params: any) => {
    console.log('userUserRoles');
  },
  isAuthenticated: false,
  user: Object(),
});

interface AuthContextProps {
  children?: React.ReactNode;
}

export const AuthContextProvider = ({ children }: AuthContextProps) => {
  const [isAuthenticated, setAuthenticated] = useSessionStorage('access-token', null);
  const [user, setUser] = useSessionStorage('user', null);
  const [cometLogin, setCometLogin] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [unreadMessageCount, setUnreadMessageCount] = React.useState(0);
  const [cometUserId, setCometUserId] = React.useState<string>('');
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const authChannel = new BroadcastChannel('logout');
 
  const handleOpen = () => setOpen(!open);

  const getCometLogin= async(userDetails:any)=>{
    const groupName =  userDetails?.secureMessageUserId;
    if(cometUserId === ''){
      setCometUserId(groupName);
    }else if(groupName !== cometUserId){
      setShowLoader(true);
      setCometUserId(groupName);
    }
    await CometChatUIKit.login(groupName).then(
      (user: any) => {
        if(user.authToken){
          setCometLogin(true);
          if(userDetails?.isAdmin){
            CometChat.getUnreadMessageCountForAllGroups().then(
              (array:any) => {
                const sumValues = (obj:any) => Object.keys(obj).reduce((acc, value) => acc + obj[value], 0);
                setUnreadMessageCount(sumValues(array));
              }, error => {
                console.log('Error in getting message count', error);
              }
            );
          }
          setShowLoader(false);
        }
      })
      .catch((e: any) => {
        console.log('Comet Login Err', e);
      });
  };

  
  useEffect(() => {
    authChannel.addEventListener('message', (e) => {
      if (e.data === 'Logout') {
        logoutFromMindWeal();
        authChannel.close();
      }
      if (e.data.event === 'login') {
        setAuthenticated(e.data.token);
      }
      if (e.data.event === 'user') {
        setUser(e.data.user);
      }
    });
  });

  useEffect(() => {
    if(unreadMessageCount > 0){         
      toast(unreadMessageCount > 1 ? `You have ${unreadMessageCount} new messages`
        : `You have ${unreadMessageCount} new message`, {
        type:'info',
        icon:true,
      }); 
    }
  }, [unreadMessageCount]);

  const loginToMindWeal = async (token: any, refreshToken:string) => {
    authChannel.postMessage({ event: 'login', token });
    setAuthenticated(token);
    sessionStorage.setItem('refresh-token', refreshToken);
  };

  const logoutFromMindWeal = async () => {
    CometChatUIKit.logout();
    setAuthenticated(null);
    setUser(null);
    sessionStorage.clear();
    localStorage.removeItem('onIdle');
    authChannel.postMessage('Logout');
    window.location.reload();
  };

  const setUserDetails = async(user: any) => {
    setUser(user);
    await getCometLogin(user);
    authChannel.postMessage({ event: 'user', user });
  };

  const useUserRoles = () => {
    return user.permissions;
  };

  return (
    <AuthContext.Provider
      value={{
        loginToMindWeal,
        logoutFromMindWeal,
        setUserDetails,
        useUserRoles,
        isAuthenticated,
        user,
      }}
    >
      {children}
      {user !== null && !user?.isAdmin && cometLogin ?
        <>          
          <Chatbox open={open} close={handleOpen} showLoader={showLoader}/>
        </>
        : 
        null
      }
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
