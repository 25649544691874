import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  saveMcatAnswersAPI, getMCATStatusAPI, getMFSHLinkAPI,
  saveWellbeingAnswersAPI,
  getWellbeingStatusAPI,
  getMCATScoreAPI,
  getWellbeingScoreAPI,
  getWellbeingTrackerDetailsAPI,
  getMCATTrackerDetailsAPI,
  getMFSHStatusAPI,
  getMCATReportDetailsAPI,
  getMCATReportTilesAPI,
  saveSuggestionsAPI,
  saveRatingsAPI,
  downloadMCATReportAPI
} from 'services/mcatServices';
import { handleMessage } from 'shared/api/ErrorMessages';

interface Error {
  data: any;
}



export const saveMcatAnswers = createAsyncThunk(
  'GET_MCAT_QUESTIONS',
  async (data: any,  { rejectWithValue }) => {
    try {
      const response = await saveMcatAnswersAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const saveWellbeingAnswers = createAsyncThunk(
  'GET_WELLBEING_QUESTIONS',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await saveWellbeingAnswersAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getMCATStatus = createAsyncThunk(
  'GET_MCAT_STATUS',
  async (patientId: number, { rejectWithValue }) => {
    try {
      const response = await getMCATStatusAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getWellbeingStatus = createAsyncThunk(
  'GET_WELLBEING_STATUS',
  async (patientId: number, { rejectWithValue }) => {
    try {
      const response = await getWellbeingStatusAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getMFSHStatus = createAsyncThunk(
  'GET_MFSH_STATUS',
  async (patientId: number, { rejectWithValue }) => {
    try {
      const response = await getMFSHStatusAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getWellbeingTrackerDetails = createAsyncThunk(
  'GET_WELLBEING_TRACKER',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getWellbeingTrackerDetailsAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getMCATTrackerDetails = createAsyncThunk(
  'GET_MCAT_TRACKER',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getMCATTrackerDetailsAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getMFSHLink = createAsyncThunk(
  'GET_JOTFORM_ID',
  async (patientId: number, { rejectWithValue }) => {
    try {
      const response = await getMFSHLinkAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);



export const getMCATScore = createAsyncThunk(
  'GET_MCAT_SCORE',
  async (patientId: number, { rejectWithValue }) => {
    try {
      const response = await getMCATScoreAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


export const getWellbeingScore = createAsyncThunk(
  'GET_WELLBEING_SCORE',
  async (patientId: number, { rejectWithValue }) => {
    try {
      const response = await getWellbeingScoreAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getMCATReportTiles = createAsyncThunk(
  'GET_REPORT_TILES',
  async(patientId:number, {rejectWithValue}) =>{
    try{
      const response = await getMCATReportTilesAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
); 

export const getMCATReportDetails = createAsyncThunk(
  'GET_REPORT_DETAILS',
  async(data:any, {rejectWithValue}) =>{
    try{
      const response = await getMCATReportDetailsAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
); 

export const saveRatings = createAsyncThunk(
  'SAVE_RATINGS',
  async(data:any, {rejectWithValue}) =>{
    try{
      const response = await saveRatingsAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
); 
export const saveSuggestions = createAsyncThunk(
  'SAVE_SUGGESTIONS',
  async(data:any, {rejectWithValue}) =>{
    try{
      const response = await saveSuggestionsAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
); 

export const downloadMCATReport = createAsyncThunk(
  'DOWNLOAD_MCAT_REPORT',
  async(userId:number, {rejectWithValue}) =>{
    try{
      const response = await downloadMCATReportAPI(userId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);  

interface Loader {
  isQuestionFetched: boolean;
}


interface StateI {
  loaders: Loader;
  error: undefined;
}

const initialState = {
  loaders: {
    isQuestionFetched: false,
  },
  error: undefined,
};

const mcatQuestionSlice = createSlice({
  name: 'mcatQuestions',
  initialState,
  reducers: {
    clearError(state: StateI) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveMcatAnswers.pending, (state: StateI) => {
      state.loaders.isQuestionFetched = true;
    });
    builder.addCase(saveMcatAnswers.fulfilled, (state: StateI) => {
      state.loaders.isQuestionFetched = false;
    });
    builder.addCase(saveMcatAnswers.rejected, (state: StateI) => {
      state.loaders.isQuestionFetched = false;
    });
  },
});

export default mcatQuestionSlice.reducer;