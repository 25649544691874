import React, { useEffect } from 'react';
import { Box, Grid, InputLabel, Typography, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { upcomingAppointmentSchema } from 'utils/schema';
import { useAppDispatch } from 'store/hooks';
import { getUpcomingAppointmentDetails, scheduleUpcomingAppointment } from 'store/slices/patientInfoSlice';
import { toast } from 'react-toastify';
import utc from 'dayjs/plugin/utc';

const UpcomingAppointment = (props: any) => {
  const dispatch = useAppDispatch();
  dayjs.extend(utc);
  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    clearErrors,
  } = useForm({
    resolver: yupResolver(upcomingAppointmentSchema),
    mode: 'all',
  });

  useEffect(() => {
    dispatch(getUpcomingAppointmentDetails(props.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          if (dayjs(response?.data?.upcomingAppointmentDateTime) > dayjs()){
            const res = response?.data?.upcomingAppointmentDateTime + 'z';
            setValue('scheduledDateTime', dayjs(res).toString(), {
              shouldValidate: true,
              shouldDirty: false,
            });
            clearErrors();
          }
        }
      });
    //eslint-disable-next-line
  }, []);

  const getPayload = (formData: any) => {
    dayjs.extend(utc);

    return {
      patientId: props.patientId,
      scheduledDateTime:
        formData.scheduledDateTime === '' ? '' : dayjs(formData.scheduledDateTime).utc().format().toString(),
    };
  };

  const onSubmitForm = (formData: any) => {
    const payload = getPayload(formData);
    if (formData?.scheduledDateTime === '' || dayjs(formData.scheduledDateTime) > dayjs()) {
      dispatch(scheduleUpcomingAppointment(payload))
        .unwrap()
        .then((response: any) => {
          if (response.status === 200) {
            const message =
              formData?.scheduledDateTime !== ''
                ? 'Appointment has been scheduled.'
                : 'Appointment date has been cleared.';
            toast(message, {
              type: 'success',
              icon: true,
            });
            reset(formData);
          }
        });
    } else {
      const message = 'You have selected past date. Please select another.';
      toast(message, {
        type: 'info',
        icon: false,
      });
    }
  };

  const handleDateChange = (val: any) => {
    setValue('scheduledDateTime', dayjs(val).toString(), { shouldValidate: true, shouldDirty: true });
  };


  return (
    <Box
      component='form'
      width={'100%'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      onSubmit={handleSubmit((formData: any) => onSubmitForm(formData))}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} display={'flex'} justifyContent='space-between' alignItems={'center'}>
          <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500', lineHeight: 1.4 }}>
            Upcoming Appointment
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel htmlFor='date'>Date & Time</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              sx={{
                width: '100%',
                '&.MuiButtonBase-root .MuiButtonBase-contained': {
                  minWidth: { xs: 50, sm: 100 },
                },
              }}
              disablePast
              slotProps={{
                actionBar: { actions: ['accept'] },
                tabs: {
                  hidden: false,
                },
              }}             
              onChange={(e) => handleDateChange(e)}
              value={watch('scheduledDateTime') ? dayjs(watch('scheduledDateTime')) : null}
              timezone='CST'
              disabled
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpcomingAppointment;
