import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getGuardianDetailsAPI, sendRequestAPI, getAllRequestsAPI, actionRequestAPI,
  getRequestsDetailsAPI, getContactDetailsAPI, getAllRequestsForAdminAPI,
  checkDuplicateRequestAPI} from 'services/accountManagementServices';
import { handleMessage } from 'shared/api/ErrorMessages';

interface Error {
    data: any;
  }

export const getGuardianDetails = createAsyncThunk(
  'GET_GUARDIAN_DETAILS',
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await getGuardianDetailsAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const checkDuplicateRequest = createAsyncThunk(
  'CHECK_DUPLICATE_REQUEST',
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await checkDuplicateRequestAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const sendRequest = createAsyncThunk(
  'SEND_REQUEST',
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await sendRequestAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


export const getAllRequests = createAsyncThunk(
  'GET_ALL_REQUESTS',
  async (id: any, { rejectWithValue }: any) => {
    try {
      const response = await getAllRequestsAPI(id);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getRequestDetails = createAsyncThunk(
  'GET_ALL_REQUESTS',
  async (ticketId: any, { rejectWithValue }: any) => {
    try {
      const response = await getRequestsDetailsAPI(ticketId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getContactDetails = createAsyncThunk(
  'GET_CONTACT_DETAILS',
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await getContactDetailsAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


export const getAllRequestsForAdmin = createAsyncThunk(
  'GET_ALL_REQUESTS',
  async (id: any, { rejectWithValue }: any) => {
    try {
      const response = await getAllRequestsForAdminAPI();
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


export const actionRequestForAdmin = createAsyncThunk(
  'SEND_ACTION',
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await actionRequestAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);