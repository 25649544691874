import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardProps,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { useNavigate } from 'react-router';
import VerifiedIcon from '@mui/icons-material/Verified';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

import {
  getGuardianDetails, getInsuranceDetails, getPatientDetails,
  getCurrentTreatmentPlan, updatePin
} from 'store/slices/userSlice';

import { useAppDispatch } from 'store/hooks';
import { AuthContext } from 'utils/AuthContext';
import { ROUTE } from 'route/RouteEnums';
import COLORS from 'theme/colors';
import PrimaryCard from './InsuranceDetails/PrimaryCard';
import dayjs from 'dayjs';
import { getDocumentList } from 'store/slices/documentSlice';
import { getProfileImageAPI } from 'services/profileServices';
import { getVitalsDetails } from 'store/slices/vitalSlice';
import { getAppointmentConfirmationDetails, getAddCardLink } from 'store/slices/appointmentSlice';
import PinInput from 'react-pin-input';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { chargePaymentSchema, checkPasswordCriteria } from 'utils/schema';
import { formatPhoneNumber } from 'utils/functions';
import { chargePayment } from 'store/slices/paymentSlice';
import ChangePassword from 'components/ChangePassword';
import Payments from 'pages/Admin/PatientInfo/Payments';


const ProfileDashboard = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useContext(AuthContext);

  const [patientInfo, setPatientInfo] = useState<string>('');
  const [primaryInsuranceData, setPrimaryInsuranceData] = useState<any>(null);
  const [secondaryInsuranceData, setSecondaryInsuranceData] = useState<any>(null);
  const [preferredName, setPreferredName] = useState<string>('');
  const [childName, setChildName] = useState<string>('');
  const [parentName, setParentName] = useState<string>('');
  const [mobileNumber, setMobileNumber] = useState<any>();
  const [birthDate, setBirthDate] = useState<string>('');
  const [clinicalDocument, setClinicalDocuments] = useState<string>('incomplete');
  const [currentPlan, setCurrentPlan] = useState<string>('-');
  const [vitalDetails, setVitalDetails] = useState<any>(null);
  const [profilePicture, setProfilePicture] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [pin, setPin] = useState<string>();
  const [showPinConfirm, setShowPinConfirm] = useState<boolean>(false);
  const [confirmPin, setConfirmPin] = useState<string>();
  const [error, setError] = useState<string>('');
  const [disableNextButton, setDisableNextButton] = useState<boolean>(true);
  const [disableConfirmButton, setDisableConfirmButton] = useState<boolean>(true);
  const [creditCardDetails, setCreditCardDetails] = useState<any>();
  const [showMakePaymentModal, setShowMakePaymentModal] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [showChangePasswordDialog, setShowChangePasswordDialog] = useState<boolean>(false);
  const [showPaymentHistory, setShowPaymentHistory] = useState<boolean>(false);
  let ele: PinInput | null;

  const getDetails = true;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(chargePaymentSchema),
    mode: 'all',
  });

  useEffect(() => {
    getImage();
    dispatch(getPatientDetails(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200 && Object.keys(response?.data).length > 0) {
          setPreferredName(response?.data?.preferredName);
          const name = response?.data?.firstName + ' ' + response?.data?.lastName;
          setChildName(name);
          setBirthDate(response?.data?.dateOfBirth);
          setPatientInfo('complete');
        } else {
          setPatientInfo('incomplete');
        }
      });
    const data = { userId: user?.patientId };
    dispatch(getGuardianDetails(data))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200 && response?.data?.length > 0) {
          // eslint-disable-next-line array-callback-return
          const primaryContact = response.data.filter((p: any) => {
            if (p.guardianCategory === 'primary') {
              return p;
            }
          });
          const name = primaryContact[0]?.firstName + ' ' + primaryContact[0]?.lastName;
          setParentName(name);
          setMobileNumber(primaryContact[0]?.mobileNumber);
        }
      });

    dispatch(getInsuranceDetails(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          if (response?.data?.primaryInsurance !== null && !response?.data?.payOutOfPocket) {
            setPrimaryInsuranceData(response?.data?.primaryInsurance);
          }
          if (response?.data?.secondaryInsurance !== null) {
            setSecondaryInsuranceData(response?.data?.secondaryInsurance);
          }
        }
      });

    dispatch(getDocumentList(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200 && response?.data?.documentInfos !== undefined) {
          setClinicalDocuments(response?.data?.documentInfos[0]?.status);
        }
      });

    dispatch(getCurrentTreatmentPlan(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200 && response?.data?.pricingPlan) {
          setCurrentPlan(response?.data?.pricingPlan);
        }
      });

    dispatch(getVitalsDetails(user.patientId))
      .unwrap()
      .then((response: any) => {
        if (
          response?.status === 200 &&
          (response?.data?.height ||
            response?.data?.weight ||
            response?.data?.bloodPressure1 ||
            response?.data?.bloodPressure2)
        ) {
          setVitalDetails(response?.data);
        } else {
          setVitalDetails(null);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user?.firstName, user?.lastName, user?.mobileNumber, user?.patientId]);

  useEffect(() => {
    dispatch(getAppointmentConfirmationDetails(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setCreditCardDetails(response?.data?.appointmentCharge);
        }
      });
  }, [dispatch, user?.patientId]);

  const StyleCard = styled(Card)<CardProps>(({ theme }) => ({
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
    border: '1px solid #EFF4FF',
    borderRadius: 12,
    background: '#FFF',
    margin: '12px 16px',
    position: 'relative',
    zIndex: 99,
    padding: 20,
    '& .MuiTypography-root': {
      position: 'relative',
    },
    '& .MuiCardContent-root:last-child': {
      padding: 0,
    },
    '.MuiTypography-h2': {
      fontSize: 20,
    },
  }));

  const editPrimaryInsurance = () => {
    navigate(`${ROUTE.INSURANCE_DETAILS}`, { state: { primary: true } });
  };

  const getImage = () => {
    getProfileImageAPI(user?.patientId)
      .then((response: any) => {
        if (response.status === 200 && response.data) {
          setProfilePicture(response.data);
        } else {
          setProfilePicture('');
        }
      });
  };


  const handleViewDetails = () => {
    navigate(`${ROUTE.TREATMENT}`);
  };

  const handleOnComplete = (value: any) => {
    if (!showPinConfirm) {
      setPin(value);
      setDisableNextButton(false);
    } else {
      setConfirmPin(value);
      setDisableConfirmButton(false);
    }
  };

  const handleNext = () => {
    setShowPinConfirm(true);
    if (ele) { ele.clear(); }
  };

  const handleConfirmPin = () => {
    if (pin === confirmPin) {
      const data = { pin };
      dispatch(updatePin(data))
        .unwrap()
        .then((response: any) => {
          if (response?.status === 200) {
            toast('PIN updated successfully!', {
              type: 'success',
              icon: true
            });
            setShowDialog(false);
          }
        }
        );

    } else {

      setError('PIN does not match');
      setTimeout(() => {
        setError('');
      }, 2000);
      if (ele) { ele.clear(); }
    }
  };

  const handleBack = () => {
    setShowPinConfirm(false);
    setShowDialog(true);
    setError('');
    if (ele) { ele.clear(); }
  };

  const handlePinChange = (val: any) => {
    if (val.length < 6) {
      setDisableNextButton(true);
      setDisableConfirmButton(true);
    }
  };

  const handleViewCreditCard = () => {
    dispatch(getAddCardLink({}))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          setTimeout(() => {
            window.open(response?.data?.url, '_blank');
          });
        }
      });
  };

  const handleProceedToPay = () => {
    setShowMakePaymentModal(true);
  };

  const handleShowPaymentHistory=()=>{
    setShowPaymentHistory(true);
  };

  const handlePayNow = (formData: any) => {
    setDisableButton(true);
    const data = {
      description: formData.description,
      amount: formData.amount,
      chargeType: 'SELF_PAYMENT',
      patientId: user?.patientId
    };
    dispatch(chargePayment(data))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setShowMakePaymentModal(false);
          toast(`Your payment of $${watch('amount')} has been processed successfully. Thank you!`,
            {
              type: 'success',
              icon: true
            });
        }
      })
      .catch((e: any) => {
        if (e.err.errorCode === 8001) {
          toast('No valid payment available.', {
            type: 'error',
            icon: true
          });
        } else if (e.err.errorCode === 8002) {
          toast('Payment Could Not Be Processed.', {
            type: 'error',
            icon: true
          });
        }
        setShowMakePaymentModal(false);
      });


  };

  const handleTrackRequest = () => {
    navigate(ROUTE.ACCOUNT_MANAGEMENT);
  };

  return (
    <Container component='main' maxWidth='md'>
      <Grid container>
        <Grid item xs={10} display={'flex'} alignItems={'center'}>
          <Typography variant='h5' gutterBottom>
            Profile
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ minHeight: '115px' }}>
          {profilePicture !== null ?
            <IconButton sx={{ padding: '8px 0 0 0' }}>
              <CardMedia
                component={'img'}
                src={profilePicture ? `data:image/png;base64, ${profilePicture}` : '../images/Asset 28.png'}
                alt=''
                sx={{
                  border: '1px solid #333', borderRadius: '50%', height: { xs: '70px', md: '106px' },
                  width: { xs: '70px', md: '106px' },
                  cursor: 'default'
                }}
              />
            </IconButton> :
            null
          }
        </Grid>
        <Grid item xs={12}>
          <StyleCard sx={{ borderTop: '12px solid #FFC44D' }}>
            {patientInfo === 'incomplete' ? (
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Typography variant='h1'>Update Profile info</Typography>
                    <Typography variant='body1'>Complete the child and guardian information</Typography>
                    <Button sx={{ marginTop: 2.5 }} onClick={() => navigate(`${ROUTE.PROFILE}`)}>
                      complete profile
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CardMedia
                      component={'img'}
                      src='images/mask-group4.png'
                      alt='card-img'
                      sx={{ width: 196, height: 'auto' }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            ) : (
              <CardContent>
                <Grid container spacing={2} borderBottom={'1px solid #EAECF0'} paddingBottom={2.5}>
                  <Grid item xs={6} md={8} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                    <Typography variant='h2'>{preferredName}</Typography>
                    <Typography variant='h2'>{childName}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-end'}
                    justifyContent='space-around'
                  >
                    <Typography variant='h3' sx={{ fontSize: { sm: '18px', xs: '13px' } }}>
                      {birthDate !== '' ?
                        <>
                          <span>DOB:</span> {dayjs(birthDate).format('MMM DD, YYYY')}
                        </>
                        : <CircularProgress />}
                    </Typography>

                  </Grid>
                </Grid>
                <Grid container spacing={2} paddingTop={2.5}>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                    justifyContent='space-around'
                  >
                    <Typography variant='h2'>Primary Contact</Typography>
                    <Typography variant='subtitle2'>{parentName}</Typography>
                    <Typography variant='body1'>
                      Phone Number: +1 {formatPhoneNumber(mobileNumber)}
                    </Typography>
                    <Link href={ROUTE.PATIENT_INFORMATION} underline='none'>
                      VIEW ALL
                    </Link>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    md={2}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-end'}
                    justifyContent={'end'}
                  >
                    {currentPlan !== '-' ?
                      <>
                        <Typography variant='body1'>CARE PLAN</Typography>
                        <Typography variant='h2'>{currentPlan}</Typography>
                      </> :
                      null}
                  </Grid>
                  <Grid item xs={6} md={2} sx={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }}>
                    <CardMedia
                      component={'img'}
                      src='../images/Group 9656.png'
                      alt='leftCard'
                      width={72}
                      height={'auto'}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            )}
          </StyleCard>
        </Grid>
        {vitalDetails ?
          <Grid item xs={12}>
            <StyleCard>
              <CardContent>
                <CardMedia
                  component={'img'}
                  src='images/solid-spiral.svg'
                  alt='card-img'
                  sx={{ width: 228, height: 'auto', position: 'absolute', top: 0, margin: 'auto', left: 0, right: 0 }}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='h2'>System Vitals</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant='body1'>Weight</Typography>
                    <Typography variant='subtitle2'>{vitalDetails.weight} lb</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant='body1'>Height</Typography>
                    <Typography variant='subtitle2'>{vitalDetails.height} in</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant='body1'>Systolic Blood Pressure</Typography>
                    <Typography variant='subtitle2'>{vitalDetails.bloodPressure1}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant='body1'>Diastolic Blood Pressure</Typography>
                    <Typography variant='subtitle2'>{vitalDetails.bloodPressure2}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </StyleCard>
          </Grid>
          : null}

        {creditCardDetails !== null && creditCardDetails?.cardExpired ?
          <Grid item xs={12}>
            <StyleCard>
              <CardContent>
                <CardMedia
                  component={'img'}
                  src='/images/Group-9550-1.png'
                  alt='card-img'
                  sx={{ width: 389, height: 'auto', position: 'absolute', right: 0, top: 0 }}
                />
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}

                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    gap={1.5}
                  >
                    <Typography variant='h2'>Credit Card Expired!</Typography>
                    <Typography sx={{ color: '#B42318', fontWeight: 700 }}>
                       Saved card is expired, add new card</Typography>
                    <Grid container >
                      <Grid item xs={8}>
                        <Typography variant='subtitle1'>
                          Update and manage your payment & card details here.
                        </Typography>
                      </Grid>
                      <Grid item xs={4} alignItems={'end'} textAlign={'end'}>
                        <Button variant='text' size='small' sx={{ maxWidth: '125px', fontSize: '14px', padding: 0 }}
                          onClick={() => handleViewCreditCard()}>VIEW DETAILS</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </StyleCard>
          </Grid> : null}

        {getDetails ? (
          <>


            {/* -------------- clinical document grid ------------ */}
            {user?.profileStatus === 'COMPLETED' ?
              <Grid item xs={12}>
                <StyleCard>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={10}
                        md={8}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        gap={1.5}
                      >
                        <Typography variant='h2'>Policies & Consents</Typography>
                        <Typography variant='subtitle1'>
                          {clinicalDocument === 'Completed' ? 'All policies and consents signed and uploaded.' :
                            'Just a step away: Sign documents before the kickoff meeting.'
                          }

                        </Typography>
                      </Grid>
                      {clinicalDocument === 'Completed' ?
                        <Grid item xs={2} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                          <VerifiedIcon sx={{ color: COLORS.Link }} />
                        </Grid> : ''}
                    </Grid>
                  </CardContent>
                </StyleCard>
              </Grid> : null}

            <Grid item xs={12}>
              <StyleCard>
                <CardContent>
                  <CardMedia
                    component={'img'}
                    src='/images/Group-9550-1.png'
                    alt='card-img'
                    sx={{ width: 389, height: 'auto', position: 'absolute', right: 0, top: 0 }}
                  />
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'space-between'}
                      gap={1.5}
                    >
                      <Typography variant='h2'>Payment History</Typography>
                      <Grid container >
                        <Grid item xs={8}>
                          <Typography variant='subtitle1'>
                            Check your payment transactions.
                          </Typography>
                        </Grid>
                        <Grid item xs={4} alignItems={'end'} textAlign={'end'}>
                          <Button variant='text' size='small' sx={{ maxWidth: '125px', fontSize: '14px', padding: 0 }}
                            onClick={handleShowPaymentHistory}>VIEW DETAILS</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyleCard>
            </Grid>

            <Grid item xs={12}>
              <StyleCard>
                <CardContent>
                  <CardMedia
                    component={'img'}
                    src='/images/Group-9550-1.png'
                    alt='card-img'
                    sx={{ width: 389, height: 'auto', position: 'absolute', right: 0, top: 0 }}
                  />
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'space-between'}
                      gap={1.5}
                    >
                      <Typography variant='h2'>Make a Payment</Typography>
                      <Grid container >
                        <Grid item xs={8}>
                          <Typography variant='subtitle1'>
                            Easily pay your outstanding balance.
                          </Typography>
                        </Grid>
                        <Grid item xs={4} alignItems={'end'} textAlign={'end'}>
                          <Button variant='text' size='small' sx={{ maxWidth: '125px', fontSize: '14px', padding: 0 }}
                            onClick={handleProceedToPay}>PROCEED TO PAY</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyleCard>
            </Grid>

            
            <Grid item xs={12}>
              <StyleCard>
                <CardContent>
                  <CardMedia
                    component={'img'}
                    src='/images/Group-9550-1.png'
                    alt='card-img'
                    sx={{ width: 389, height: 'auto', position: 'absolute', right: 0, top: 0 }}
                  />
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}

                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'space-between'}
                      gap={1.5}
                    >
                      <Typography variant='h2'>Credit Card Details</Typography>
                      <Grid container >
                        <Grid item xs={8}>
                          <Typography variant='subtitle1'>
                              Update and manage your payment & card details here.
                          </Typography>
                        </Grid>
                        <Grid item xs={4} alignItems={'end'} textAlign={'end'}>
                          <Button variant='text' size='small' sx={{ maxWidth: '125px', fontSize: '14px', padding: 0 }}
                            onClick={() => handleViewCreditCard()}>VIEW DETAILS</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyleCard>
            </Grid>
            

            <Grid item xs={12}>
              <StyleCard>
                <CardContent>
                  <CardMedia
                    component={'img'}
                    src='/images/Group-9550-1.png'
                    alt='card-img'
                    sx={{ width: 389, height: 'auto', position: 'absolute', right: 0, top: 0 }}
                  />
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'space-between'}
                      gap={1.5}
                    >
                      <Typography variant='h2'>Account Management</Typography>
                      <Grid container >
                        <Grid item xs={8}>
                          <Typography variant='subtitle1'>
                            Submit service requests related to account management such as
                            updating your contact email, phone number, or transferring account
                            ownership
                          </Typography>
                        </Grid>
                        <Grid item xs={4} alignItems={'end'} textAlign={'end'}>
                          <Button variant='text' size='small' sx={{ maxWidth: '125px', fontSize: '14px', padding: 0 }}
                            onClick={handleTrackRequest}>TRACK REQUEST</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyleCard>
            </Grid>

            <Grid item xs={12}>
              <StyleCard>
                <CardContent>
                  <CardMedia
                    component={'img'}
                    src='/images/Group-9550-1.png'
                    alt='card-img'
                    sx={{ width: 389, height: 'auto', position: 'absolute', right: 0, top: 0 }}
                  />
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}

                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'space-between'}
                      gap={1.5}
                    >
                      <Typography variant='h2'>Change Your PIN</Typography>
                      <Grid container >
                        <Grid item xs={8}>
                          <Typography variant='subtitle1'>
                            Keep your account secure by updating PIN.
                          </Typography>
                        </Grid>
                        <Grid item xs={4} alignItems={'end'} textAlign={'end'}>
                          <Button variant='text' size='small' sx={{ maxWidth: '125px', fontSize: '14px', padding: 0 }}
                            onClick={() => { setShowDialog(true); }}>UPDATE PIN</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyleCard>
            </Grid>

            <Grid item xs={12}>
              <StyleCard>
                <CardContent>
                  <CardMedia
                    component={'img'}
                    src='/images/Group-9550-1.png'
                    alt='card-img'
                    sx={{ width: 389, height: 'auto', position: 'absolute', right: 0, top: 0 }}
                  />
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}

                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'space-between'}
                      gap={1.5}
                    >
                      <Typography variant='h2'>Change Your Password</Typography>
                      <Grid container >
                        <Grid item xs={8}>
                          <Typography variant='subtitle1'>
                            Protect your privacy: Change your password regularly.
                          </Typography>
                        </Grid>
                        <Grid item xs={4} alignItems={'end'} textAlign={'end'}>
                          <Button variant='text' size='small' sx={{ fontSize: '14px', padding: 0 }}
                            onClick={() => { setShowChangePasswordDialog(true); }}>CHANGE PASSWORD</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyleCard>
            </Grid>

            {/*  -------------- credit card/ payment details grid ------------------ */}
            {primaryInsuranceData !== null && primaryInsuranceData !== undefined ?
              <Grid item xs={12}>
                <StyleCard>
                  <CardContent sx={{ position: 'relative' }}>
                    {/* <IconButton sx={{ position: 'absolute', right: 0, top: 0 }}>
                    <InfoOutlinedIcon sx={{ color: COLORS.Error }} />
                  </IconButton> */}
                    <Typography variant='h2'>Insurance Details</Typography>
                    <Grid container spacing={1} paddingTop={2.5}>
                      <Grid item sm={6} xs={12}>
                        <PrimaryCard
                          editPrimaryInsurance={editPrimaryInsurance}
                          insurancetype='Primary'
                          data={primaryInsuranceData}
                          fromadmin={true}
                        />
                      </Grid>
                      {secondaryInsuranceData !== null ?
                        <Grid item sm={6} xs={12}>
                          <PrimaryCard
                            editPrimaryInsurance={editPrimaryInsurance}
                            insurancetype='Secondary'
                            data={secondaryInsuranceData}
                            fromadmin={true}
                          />
                        </Grid> : null}
                    </Grid>
                  </CardContent>
                </StyleCard>
              </Grid> :

              <Grid item xs={12}>
                <StyleCard>
                  <CardContent>
                    <CardMedia
                      component={'img'}
                      src='/images/Group-9550-1.png'
                      alt='card-img'
                      sx={{ width: 389, height: 'auto', position: 'absolute', right: 0, top: 0 }}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <CardMedia
                          component={'img'}
                          src='/images/Man.png'
                          alt='card-img'
                          sx={{ width: 170, height: 'auto', position: 'relative' }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography variant='h1'>Affordable, transparent pricing, no hidden fees</Typography>
                        <Typography variant='body1'>
                          When you avail Mindweal services, you always know how much it’ll cost. That’s because we’re
                          upfront about our pricing, and there are no hidden costs.
                        </Typography>
                        <Button sx={{ marginTop: 2.5 }} variant='outlined'
                          onClick={() => window.open('https://www.mindweal.com/pricing/')}>
                          View Pricing
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </StyleCard>
              </Grid>
            }
          </>
        ) : (
          <>
            {/*  -------- primary goals grid  -------------- */}
            <Grid item xs={12}>
              <StyleCard sx={{ position: 'relative' }}>
                <CardContent>
                  <CardMedia
                    component={'img'}
                    src='images/Group 9550.png'
                    alt='card-img'
                    sx={{ width: 228, height: 'auto', position: 'absolute', right: 0, top: 0 }}
                  />
                  <CardMedia
                    component={'img'}
                    src='images/Ellipse 1.png'
                    alt='card-img'
                    sx={{ width: 64, height: 'auto', position: 'absolute', left: 0, bottom: 0 }}
                  />
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='h2'>Primary Goals</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Typography variant='body1' sx={{ zIndex: 888 }}>
                        Set your primary goals
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                      <Link href={ROUTE.PRIMARY_GOALS} variant='subtitle1'>
                        Set Goals
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyleCard>
            </Grid>

            {/* ------------ intake documents ------------------ */}
            <Grid item xs={12} md={6}>
              <StyleCard>
                <CardContent sx={{ position: 'relative' }}>
                  <IconButton sx={{ position: 'absolute', right: 0, top: 0 }}>
                    <InfoOutlinedIcon sx={{ color: COLORS.Yellow }} />
                  </IconButton>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='h2'>Intake Documents</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body1'>
                        Just a step away: Upload documents before the kickoff meeting.{' '}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyleCard>
            </Grid>

            {/* --------------- legal documents  -------------- */}
            <Grid item xs={12} md={6}>
              <StyleCard>
                <CardContent sx={{ position: 'relative' }}>
                  <IconButton sx={{ position: 'absolute', right: 0, top: 0 }}>
                    <InfoOutlinedIcon sx={{ color: COLORS.Yellow }} />
                  </IconButton>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='h2'>Legal Documents</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body1'>Reminder: Sign documents before the kickoff meeting.</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyleCard>
            </Grid>



          </>
        )}
      </Grid>

      <Dialog aria-labelledby="simple-dialog-title"
        open={showDialog}>
        <Box sx={{ textAlign: 'right', m: '10px 20px 0 0' }}>
          <Typography sx={{ fontSize: '18px', cursor: 'pointer' }}
            onClick={() => setShowDialog(false)}
          >X</Typography>
        </Box>
        <DialogTitle id="simple-dialog-title" sx={{ padding: '16px 24px 0' }}>
          {!showPinConfirm ? 'Set New PIN' : 'Confirm New PIN'}</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography>
              {!showPinConfirm && showDialog ?
                'Enter a new 6-digit PIN for your account. ' :
                'Re-enter your new 6-digit PIN to confirm'}</Typography>

            <Grid item xs={3} mt={'20px'}>
              <PinInput
                length={6}
                focus
                secret
                secretDelay={500}
                type='numeric'
                inputMode='numeric'
                onComplete={handleOnComplete}
                onChange={handlePinChange}
                inputStyle={{
                  border: '1px solid #FFC44D',
                  borderRadius: '8px',
                  margin: '10px 15px',
                }}
                ref={(n) => ele = n}
              />
            </Grid>
            <Typography sx={{ color: 'red' }}>{error}</Typography>
            <Grid container justifyContent="center" mt='10px'>
              {!showPinConfirm ?
                <Button onClick={handleNext} sx={{ textAlign: 'right' }} disabled={disableNextButton}> Next </Button> :
                <>
                  <Grid xs={12} sx={{ m: '20px 0' }} textAlign={'center'}>
                    <Button onClick={handleConfirmPin} sx={{ textAlign: 'right' }} disabled={disableConfirmButton}>
                      Confirm</Button>
                  </Grid>
                  <Grid><Button variant='text' onClick={handleBack} sx={{ textAlign: 'right' }}> Back </Button></Grid>
                </>
              }
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={showMakePaymentModal}>
        <Box width={'100%'} component={'form'} onSubmit={handleSubmit((formData) => handlePayNow(formData))}>
          <IconButton
            aria-label='close'
            onClick={() => setShowMakePaymentModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>

            <Grid container spacing={4} display={'flex'}>
              <Grid item xs={12} alignItems={'center'} textAlign={'center'}>
                <Typography variant='h2' textAlign={'center'} pb={2}>
                  Make a Payment
                </Typography>

              </Grid>
              <Grid
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                sx={{ marginLeft: '50px' }}
              >
                <Grid alignItems={'start'}>
                  <Typography sx={{ fontWeight: 700 }}>Payment Amount*</Typography>
                  <TextField
                    id='amount'
                    fullWidth
                    placeholder='Amount Due'
                    inputProps={{ maxLength: 6 }}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>
                    }}
                    error={!!errors.amount}
                    helperText={errors?.amount?.message}
                    {...register('amount')}
                  />
                </Grid>
                <Grid alignItems={'start'} mt='20px'>
                  <Typography sx={{ fontWeight: 700 }}>Details of Payment*</Typography>
                  <TextField
                    id='description'
                    fullWidth
                    placeholder='Reason you are paying'
                    inputProps={{ maxLength: 250 }}
                    error={!!errors.description}
                    helperText={errors?.description?.message}
                    {...register('description')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant='contained' type='submit' disabled={!isValid || !isDirty || disableButton}>
              PAY NOW</Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog
        open={showChangePasswordDialog} disableEnforceFocus>
        <Box sx={{ textAlign: 'right', m: '10px 20px 0 0' }}>
          <Typography sx={{ fontSize: '18px', cursor: 'pointer' }}
            onClick={() => setShowChangePasswordDialog(false)}
          >X</Typography>
        </Box>
        <DialogTitle id="simple-dialog-title" sx={{ padding: '0px 24px 0' }}>
          Change Password
        </DialogTitle>
        <DialogContent>
          <ChangePassword onCancel={()=>setShowChangePasswordDialog(false)}/>   
        </DialogContent>
      </Dialog>

      <Dialog
        open={showPaymentHistory} disableEnforceFocus>
        <Box sx={{ textAlign: 'right', m: '10px 20px 0 0' }}>
          <Typography sx={{ fontSize: '18px', cursor: 'pointer' }}
            onClick={() => setShowPaymentHistory(false)}
          >X</Typography>
        </Box>
        <DialogTitle id="simple-dialog-title" sx={{ padding: '0px 24px 0' }}>
          Payment History
        </DialogTitle>
        <DialogContent sx={{width:'600px'}}>
          <Payments patientId={user?.patientId} from='patient'/>  
        </DialogContent>
      </Dialog>

    </Container>
  );
};

export default ProfileDashboard;

