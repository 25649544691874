/* eslint-disable max-len */

import { useState, useEffect, useContext } from 'react';
import {
  Grid, Container, Typography, styled, Card, CardProps,
  CardContent, Box, Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControlLabelProps
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';

import { useAppDispatch } from 'store/hooks';
import { Page } from 'components/Page';
import { ROUTE } from 'route/RouteEnums';
import { AuthContext } from 'utils/AuthContext';
import { checkDuplicateRequest, getAllRequests } from 'store/slices/accountManagementSlice';
import ConfirmBox from 'components/ConfirmBox';

const StyleCard = styled(Card)<CardProps>(({ theme }) => ({
  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
  border: '1px solid #EFF4FF',
  borderRadius: 12,
  background: '#FFF',
  margin: '12px 16px',
  position: 'relative',
  zIndex: 99,
  '& .MuiTypography-root': {
    marginBottom: '8px',
    position: 'relative',
  },
  width: '100%'
}));

const StyleFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>(({ theme }) => ({
  span: {
    fontSize: 16,
    color:'#101828',
    [theme.breakpoints.down('md')]: {
      margin:'0 0 0 36px',
    },
  },
  '.MuiFormControlLabel-label':{
    margin:0
  }
}));
  
const AccountManagement = () => {

  const [showRequestModal, setShowRequestModal] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [requestType, setRequestType] = useState<any>();
  const [allRequests, setAllRequests] = useState<any>([]);
  const [showError, setShowError] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);


  
  useEffect(() => {
    const request:any=[];
    dispatch(getAllRequests(user?.patientId))
      .unwrap()
      .then((response:any) => {
        if(response?.status === 200 ){  
          // eslint-disable-next-line array-callback-return
          response?.data?.map((d:any) => {
            const data= {
              ticketId: d?.ticketId,
              requestType: d?.requestType === 'Transfer Ownership' ? 'transferOwnership' : d?.requestType === 'Deactivate Account' ? 'deactivateAccount' : 'emailPhoneNumber',
              status: d?.status,
              userId: d?.userId,
              patientId: d?.patientId,
              requestDate: d?.requestDate
            };          
            request.push(data);                     
            setAllRequests(request);
          });            
        }
      }); 
  },[user?.patientId, dispatch]);

  const handleRequestChange=(e:any)=>{
    setRequestType(e.target.value);
    setShowError(false);
  };

  const handleContinue=()=>{
    if (requestType === 'emailPhoneNumber') {
      navigate(ROUTE.UPDATE_EMAIL_PHONE);
    } else if(requestType === 'transferOwnership'){
      navigate(ROUTE.TRANSFER_ACCOUNT_OWNERSHIP);
    } else if(requestType === 'deactivateAccount'){
      navigate(ROUTE.DEACTIVATE_ACCOUNT);
    } else{
      setShowError(true);
    }

  };
  const handleNewRequest=()=> {
    if(user?.patientId) {   
      dispatch(checkDuplicateRequest(user?.patientId))
        .unwrap()
        .then((response:any) => {
          if(response.status === 200 && response.data!=='') {
            setShowConfirm(true);
          } else{
            setShowRequestModal(true);
          }
        });
    }
  };

  const handleViewDetails=(ticketId:string, requestType:string, status:string)=>{
    if (requestType === 'emailPhoneNumber') {
      navigate(ROUTE.UPDATE_EMAIL_PHONE, {state: {ticketId, status}});
    } else if(requestType === 'transferOwnership'){
      navigate(ROUTE.TRANSFER_ACCOUNT_OWNERSHIP, {state: {ticketId, status}});
    } else if(requestType === 'deactivateAccount'){
      navigate(ROUTE.DEACTIVATE_ACCOUNT, {state: {ticketId, status}});
    }
  };

  return (
    <Page backDir={ROUTE.DASHBOARD}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Container component='section'>
            <Grid container>
              <Grid item xs={12} sm={9}>            
                <Typography
                  variant='h5'
                  position={'relative'}
                  width={'fit-content'}
                  sx={{ paddingTop: 6, textTransform: 'capitalize' }}
                >
                Account Management Requests
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button sx={{ marginTop: {xs:0, sm:6}}} onClick={() => handleNewRequest()}> NEW REQUEST</Button>
              </Grid>
            </Grid>
            <Grid container marginTop={4}>
              { allRequests.length === 0 ?
                <StyleCard>
                  <CardContent>
                    <Typography variant='h2' textAlign={'center'}>No Existing Requests!</Typography>                  
                  </CardContent>
                </StyleCard>
                :
                <>
                  {allRequests.map((a:any) => {
                    return(
                      <StyleCard key={a.ticketId}>
                        <CardContent>
                          <Grid container justifyContent='space-between'>
                            <Grid item xs={8}>
                              <Typography variant='h3'>
                                {a?.requestType === 'transferOwnership' ?
                                  'Transfer account ownership' :
                                  a?.requestType === 'emailPhoneNumber' ?
                                    'Change Email/Phone Number' : 'Deactivate Account'
                                }</Typography>
                              <Button variant='text' sx={{p:'0px'}} onClick={() => handleViewDetails(a?.ticketId, a?.requestType, a?.status)}>VIEW DETAILS</Button>
                            </Grid>
                            <Grid item xs={4} justifyItems={'end'} pr={'20px'}>
                              <Typography 
                                sx={{background: a?.status === 'Rejected' || a?.status === 'Update Failed' || a?.status === 'Transfer Failed' ? '#FFFBFA' : a?.status === 'Approved' || a?.status === 'Update Successful' || a?.status === 'Transfer Successful' ? '#F6FEF9' : '#F5F8FF', 
                                  color: a?.status === 'Rejected' || a?.status === 'Update Failed' || a?.status === 'Transfer Failed' ? '#B42318' : a?.status === 'Approved' || a?.status === 'Update Successful' || a?.status === 'Transfer Successful'? '#027A48' : '#1570EF', 
                                  border: a?.status === 'Rejected' || a?.status === 'Update Failed'|| a?.status === 'Transfer Failed' ? '1px solid #B42318' : a?.status === 'Approved' || a?.status === 'Update Successful' || a?.status === 'Transfer Successful' ? '1px solid #027A48' : '1px solid #1570EF', 
                                  borderRadius: '12px', padding: '2px 10px 0px 10px'
                                }}> 
                                {a?.status}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </StyleCard>
                    );
                  })}
                </>
              }
            </Grid>
          </Container>
        </Grid > 
        <Dialog open ={showConfirm} disableEnforceFocus >
          <DialogContent>
            <ConfirmBox
              message="This request cannot be processed because there is already a pending Account Management request. Please wait for the existing request to be completed before submitting a new one."
              onConfirm={() => setShowConfirm(false)} 
            />  
          </DialogContent>
        </Dialog> 
        <Dialog open={showRequestModal}>
          <Box width={'100%'}>
            <IconButton
              aria-label='close'
              onClick={() => {setShowRequestModal(false);setRequestType(undefined); setShowError(false);}}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>

              <Grid container spacing={8} alignContent={'center'} justifyContent={'center'}>
                <Grid item xs={12}>
                  <Typography variant='h2' sx={{ borderBottom: '1px solid #EAECF0' }} textAlign={'center'} pb={2}>
                    Account Management Request
                  </Typography>
                </Grid>
               
                <RadioGroup
                  name='accountRequest'
                  onChange={(e: any) => handleRequestChange(e)}                  
                >
                  <StyleFormControlLabel
                    value='emailPhoneNumber'
                    control={<Radio color='secondary'/>}
                    label='Update Email/Phone Number'
                    
                  />
                  <StyleFormControlLabel
                    value='transferOwnership'
                    control={<Radio />}
                    label='Transfer Ownership'
                  />
                  <StyleFormControlLabel
                    value='deactivateAccount'
                    control={<Radio />}
                    label='Deactivate Account'
                  />
                  
                </RadioGroup>
                
              </Grid>
              <Box textAlign={'center'}>
                {showError ? <Typography variant='body1' color='red'> Please select request type. </Typography> : ''}
              </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant='outlined'
                sx={{minWidth:{xs:'120px', sm:'150px'}}}
                onClick={()=>{setShowRequestModal(false); setRequestType(undefined); setShowError(false);}}>Cancel</Button>
              <Button variant='contained' sx={{minWidth:{xs:'120px', sm:'150px'}}} onClick={handleContinue}>Continue</Button>          
            </DialogActions>
          </Box>
        </Dialog>
      </Grid >
    </Page>
  );

  
};

export default AccountManagement;
