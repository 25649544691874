/* eslint-disable max-len */
import { useState, useEffect, useContext } from 'react';
import {
  Grid, Container, Typography, styled, Card, CardProps,
  CardContent, Box, Button,
  TextField,
  FormControlLabel,
  Alert,
  Checkbox,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { useAppDispatch } from 'store/hooks';
import { getGuardianDetails, sendRequest, getRequestDetails, actionRequestForAdmin } from 'store/slices/accountManagementSlice';
import { Page } from 'components/Page';
import { ROUTE } from 'route/RouteEnums';
import { toast } from 'react-toastify';
import { AuthContext } from 'utils/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckBoxBlankIcon } from 'utils/functions';


const StyleCard = styled(Card)<CardProps>(({ theme }) => ({
  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
  border: '1px solid #EFF4FF',
  borderRadius: 12,
  background: '#FFF',
  margin: '12px 16px',
  position: 'relative',
  zIndex: 99,
  '& .MuiTypography-root': {
    marginBottom: '8px',
    position: 'relative',
  },
  width: '100%'  
}));


const TransferAccountOwnership = (props:any) => {
  const [selectedRequest, setSelectedRequest] = useState<string>('toPatient');
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);
  const [name, setName] = useState<any>();
  const [emptyEmail, setEmptyEmail] = useState<boolean>(false);
  const [emptyName, setEmptyName] = useState<boolean>(false);
  const [emptyPhone, setEmptyPhone] = useState<boolean>(false);
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const [invalidPhone, setInvalidPhone] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [patientName, setPatientName] = useState<string>();
  const [dateOfBirth, setDateOfBirth] = useState<string>();  
  const [primaryGuardian, setPrimaryGuardian] = useState<any>();
  const [secondaryGuardian,setSecondaryGuardian] = useState<any>();
  const [isConsent1Checked, setIsConsent1Checked] = useState<boolean>(false);
  const [isConsent2Checked, setIsConsent2Checked] = useState<boolean>(false);
  const [isConsent3Checked, setIsConsent3Checked] = useState<boolean>(false);
  const [isConsent4Checked, setIsConsent4Checked] = useState<boolean>(false);
  const [isConsent5Checked, setIsConsent5Checked] = useState<boolean>(false);
  const [isConsent6Checked, setIsConsent6Checked] = useState<boolean>(false);
  const [isConsent7Checked, setIsConsent7Checked] = useState<boolean>(false);
  const [isConsent8Checked, setIsConsent8Checked] = useState<boolean>(false);
  const [isConsent9Checked, setIsConsent9Checked] = useState<boolean>(false);
  const [isConsent10Checked, setIsConsent10Checked] = useState<boolean>(false);
  const [isConsent11Checked, setIsConsent11Checked] = useState<boolean>(false);
  const [isConsent12Checked, setIsConsent12Checked] = useState<boolean>(false);

  const [newGuardianFirstName, setNewGuardianFirstName] = useState<any>();
  const [newGuardianLastName, setNewGuardianLastName] = useState<any>();
  const [newGuardianPhoneNumber, setNewGuardianPhoneNumber] = useState<string>(' ');
  const [newGuardianEmail, setNewGuardianEmail] = useState<any>();
  const [newGuardianRelation, seNewGuardianRelation] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [emptyFirstName, setEmptyFirstName] = useState<boolean>(false);
  const [emptyLastName, setEmptyLastName] = useState<boolean>(false);
  const [emptyRelation, setEmptyRelation] = useState<boolean>(false);
  const [emptyReason, setEmptyReason] = useState<boolean>(false);
  const [allConsentsChecked, setAllConsentsChecked] = useState<boolean>(false);
  const [reasonForRejection, setReasonForRejection] = useState<any>();
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [changeSuccessMessage,setChangeSuccessMessage] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [systemAlert, setSystemAlert] = useState<any>(null);
  const [showRejectionModal, setShowRejectionModal] = useState<boolean>(false);
  const [approveTextForModal, setApproveTextForModal] = useState<boolean>(false);
  const [age, setAge] = useState<any>();

  const dispatch = useAppDispatch();
  const {user} = useContext(AuthContext);
  const {state} = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    if(selectedRequest !== 'toPatient' && isConsent5Checked && isConsent6Checked && 
      isConsent7Checked && isConsent8Checked && isConsent9Checked && isConsent10Checked ) {
      setAllConsentsChecked(true);
    } else if(selectedRequest === 'toPatient' && isConsent1Checked && isConsent2Checked && isConsent3Checked && isConsent4Checked){
      setAllConsentsChecked(true);
    }
    if(selectedRequest === 'toPatient'){
      setNewGuardianFirstName(patientName && patientName?.split(' ')[0]?.charAt(0)?.toUpperCase()+patientName?.split(' ')[0]?.slice(1));
      setNewGuardianLastName(patientName && patientName?.split(' ')[1]?.charAt(0)?.toUpperCase()+patientName?.split(' ')[1]?.slice(1));
      seNewGuardianRelation('Self');
    }
  },[patientName, state, isConsent10Checked, isConsent11Checked, isConsent12Checked, isConsent1Checked, isConsent2Checked, isConsent3Checked, isConsent4Checked, isConsent5Checked, isConsent6Checked, isConsent7Checked, isConsent8Checked, isConsent9Checked, selectedRequest]);
  
  function calculateAge(birthdate:any) {
    const birthDate = new Date(birthdate);
    const today = new Date();
    
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    
    // Adjust age if the birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    
    return age;
  }

  useEffect(() => {

    if(state?.ticketId){      
      dispatch(getRequestDetails(state?.ticketId))
        .unwrap()
        .then((response:any) => {
          if(response?.status === 200){            
            const details =JSON.parse(response?.data?.requestData);            
            setPrimaryGuardian(details?.from);
            setSecondaryGuardian(details?.to); 
            setReason(details?.reasonForTransfer);
            setPatientName(details?.patientFullName);
            setDateOfBirth(details?.dob);
            setName(details?.submittedBy);
            setSelectedRequest(details?.ticketType);
            setReasonForRejection(details?.reasonForRejection);
            setIsConsent11Checked(details?.supportDoc1);
            setIsConsent12Checked(details?.supportDoc2);
            if(details?.ticketType !== 'toSecondaryGuardian'){
              setNewGuardianFirstName(details?.to?.firstName);
              setNewGuardianLastName(details?.to?.lastName);
              setNewGuardianEmail(details?.to?.email);
              setNewGuardianPhoneNumber(details?.to?.phoneNumber);
              seNewGuardianRelation(details?.to?.relationShip);
            }
            
            if(details?.systemAlerts !==null){
              setSystemAlert(details?.systemAlerts);
            } else if(JSON.parse(response?.data?.responseData)?.systemAlerts[0]){
              setSystemAlert(JSON.parse(response?.data?.responseData).systemAlerts[0]);
            }
          }
        });
    } else{      
      const data= {
        patientId: state?.initiatedByVMA === true ? state?.patientId :user?.patientId,
        requestType: selectedRequest
      };
      dispatch(getGuardianDetails(data))
        .unwrap()
        .then((response:any) => {
          if(response?.status === 200){  
            setPrimaryGuardian(response?.data?.from);
            setPatientName(response?.data?.patientFullName);
            setDateOfBirth(response?.data?.dob);
            setAge(calculateAge(response?.data?.dob));
            if(selectedRequest === 'toSecondaryGuardian' && response?.data?.to !== null){
              setSecondaryGuardian(response?.data?.to);
            }
          }
        });
       
    }
  },[user?.patientId, dispatch, selectedRequest, state?.ticketId, props?.from, state?.initiatedByVMA, state?.patientId]);

  const handleRelationCheckbox=(e:any)=>{
    if(e.target.checked){
      setDisableSubmitButton(false);
      setIsChecked(true);
    } else {
      setDisableSubmitButton(true);
      setIsChecked(false);
    }
  };

  const handleConsentCheckbox=async(e:any)=>{
    const id = e.target.name;
    
    if(e.target.checked){
      switch(Number(id)){
      case 1: setIsConsent1Checked(true); break;
      case 2: setIsConsent2Checked(true); break;
      case 3: setIsConsent3Checked(true); break;
      case 4: setIsConsent4Checked(true); break;
      case 5: setIsConsent5Checked(true); break;
      case 6: setIsConsent6Checked(true); break;
      case 7: setIsConsent7Checked(true); break;
      case 8: setIsConsent8Checked(true); break;
      case 9: setIsConsent9Checked(true); break;
      case 10: setIsConsent10Checked(true); break;
      case 11: setIsConsent11Checked(true); break;
      case 12: setIsConsent12Checked(true); break;
      }
    }else{
      switch(Number(id)){
      case 1: setIsConsent1Checked(false); break;
      case 2: setIsConsent2Checked(false); break;
      case 3: setIsConsent3Checked(false); break;
      case 4: setIsConsent4Checked(false); break;
      case 5: setIsConsent5Checked(false); break;
      case 6: setIsConsent6Checked(false); break;
      case 7: setIsConsent7Checked(false); break;
      case 8: setIsConsent8Checked(false); break;
      case 9: setIsConsent9Checked(false); break;
      case 10: setIsConsent10Checked(false); break;
      case 11: setIsConsent11Checked(false); break;
      case 12: setIsConsent12Checked(false); break;
      }
      setAllConsentsChecked(false);
    }
  };

  const handleSelectedTab =(val:any)=>{
    setSelectedRequest(val);
    setNewGuardianEmail(undefined);
    setNewGuardianLastName(undefined);
    setNewGuardianFirstName(undefined);
    setEmptyName(false);
    setEmptyEmail(false);
    setEmptyPhone(false);
    setInvalidEmail(false);
    setInvalidPhone(false);
    setIsChecked(false);
    setEmptyFirstName(false);
    setEmptyLastName(false);
    setEmptyReason(false);
    setEmptyRelation(false) ;
    setDisableSubmitButton(true);
    setNewGuardianPhoneNumber('1');
    if(val === 'toPatient'){
      setIsConsent1Checked(false);
      setIsConsent2Checked(false);
      setIsConsent3Checked(false);
      setIsConsent4Checked(false);
    }else{
      setIsConsent5Checked(false);
      setIsConsent6Checked(false);
      setIsConsent7Checked(false);
      setIsConsent8Checked(false);
      setIsConsent9Checked(false);
      setIsConsent10Checked(false);
      setIsConsent11Checked(false);
      setIsConsent12Checked(false);
    }
  };

  const onSubmitForm=()=>{    
    if((newGuardianEmail === undefined ||newGuardianEmail === '') && selectedRequest !== 'toSecondaryGuardian'){
      setEmptyEmail(true);      
    } 
    if((newGuardianPhoneNumber=== undefined || newGuardianPhoneNumber.length !== 11) && selectedRequest !== 'toSecondaryGuardian' ){
      setEmptyPhone(true);
    }
    if((newGuardianFirstName === '' || newGuardianFirstName === undefined) && selectedRequest !== 'toSecondaryGuardian'){
      setEmptyFirstName(true);
    }
    if((newGuardianLastName === '' || newGuardianLastName === undefined) && selectedRequest !== 'toSecondaryGuardian'){
      setEmptyLastName(true);
    }
    if((newGuardianRelation === '' || newGuardianRelation === undefined) && selectedRequest !== 'toSecondaryGuardian'){
      setEmptyRelation(true);
    }
    if( reason=== '' || reason === undefined){
      setEmptyReason(true);
    }
    if(name === undefined || name === ''){
      setEmptyName(true);
      return;
    }


    if((newGuardianFirstName && newGuardianLastName && newGuardianEmail && newGuardianPhoneNumber && newGuardianRelation) || selectedRequest === 'toSecondaryGuardian'){
      const data={
        requestData : JSON.stringify({
          patientId: state?.initiatedByVMA === true ? state?.patientId : user?.patientId,
          currentUserId: user?.userId,
          from: primaryGuardian,
          to: {
            firstName: selectedRequest === 'toSecondaryGuardian' && secondaryGuardian?.firstName !=='' ? secondaryGuardian?.firstName : newGuardianFirstName,
            lastName: selectedRequest === 'toSecondaryGuardian' && secondaryGuardian?.lastName !=='' ? secondaryGuardian?.lastName :newGuardianLastName,
            email: selectedRequest === 'toSecondaryGuardian' && secondaryGuardian?.email !=='' ? secondaryGuardian?.email :newGuardianEmail,
            phoneNumber:selectedRequest === 'toSecondaryGuardian' && secondaryGuardian?.phoneNumber !=='' ? secondaryGuardian?.phoneNumber :newGuardianPhoneNumber,
            relationShip: selectedRequest === 'toSecondaryGuardian' && secondaryGuardian?.relationShip !=='' ? secondaryGuardian?.relationShip :newGuardianRelation,
          },
          reasonForTransfer: reason,
          patientFullName: patientName,
          dob:dateOfBirth,
          submittedBy: name,
          relationToPatient: 'Primary Guardian',
          systemAlerts: null,
          ticketType: selectedRequest,
          supportDoc1: isConsent11Checked,
          supportDoc2: isConsent12Checked
        }),
        requestType:'TRANSFER_OWNERSHIP', 
        patientId: state?.initiatedByVMA === true ? state?.patientId : user?.patientId,
        userId: user?.userId, 
      };
      
 
      dispatch(sendRequest(data))
        .unwrap()
        .then((response:any) => {
          if(response.status === 200){
            if(response?.data?.includes('Last request is in progress')){
              setChangeSuccessMessage(true);
            }
            setShowSuccessModal(true);
          }
        });
    }
  };

  const validateEmail = (email:any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  
  const handleEmailChange=(e:any)=>{
    setNewGuardianEmail(e.target.value.toLowerCase());
    if(!validateEmail(e.target.value.toLowerCase())){
      setInvalidEmail(true);
    } else {
      setEmptyEmail(false);
      setInvalidEmail(false);
    }
  };

  const handlePhoneNumberChange=(val:any)=>{
    if(val.length !== 11){
      setInvalidPhone(true);
    }else{
      setEmptyPhone(false);
      setInvalidPhone(false);
    }
    setNewGuardianPhoneNumber(val);
  };

  const handleSendForConfirmation=()=>{
    setShowConfirmationModal(true);
  };

  const handleActionModal=()=>{
    setShowConfirmationModal(true);
    setApproveTextForModal(true);
  };

  const handleReject=()=>{
    setShowRejectionModal(true);
  };

  const handleReasonChange=(e:any)=>{
    setReasonForRejection(e?.target?.value);
  };

  const handleAction=(action:string)=>{
    const data={
      ticketId:state?.ticketId,
      action,
      reasonForRejection
    };
    dispatch(actionRequestForAdmin(data))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200 && action === 'Sent for confirmation') {
          toast(' Emails sent to the proposed new guardian & the current guardian', {
            type: 'success',
            icon: true
          });
          navigate(ROUTE.ADMIN_ACCOUNT_MANAGEMENT_REQUEST);
        }
        if(action === 'Approved'){
          navigate(ROUTE.ADMIN_ACCOUNT_MANAGEMENT_REQUEST);
        }
      });
    if (action === 'Sent for confirmation') {
      setShowConfirmationModal(false);
    }else if(action === 'Rejected'){
      setShowRejectionModal(false);
      navigate(ROUTE.ADMIN_ACCOUNT_MANAGEMENT_REQUEST);
    }
  };

  return (
    <Page backDir={props?.from === 'admin' && !state?.initiatedByVMA ? ROUTE.ADMIN_ACCOUNT_MANAGEMENT_REQUEST : props?.from === 'admin' && state?.initiatedByVMA ? `${ROUTE.ADMIN_PATIENT_INFO}/${state?.patientId}` :ROUTE.ACCOUNT_MANAGEMENT}>
      <Grid container spacing={3}>
        <Grid item xs={12} >
          <Container component='section'>
            <Grid container>
              <Grid item xs={6.5}>            
                <Typography
                  variant='h5'
                  position={'relative'}
                  width={'fit-content'}
                  sx={{ paddingTop: 6, textTransform: 'capitalize' }}
                >
                Transfer account ownership                    
                </Typography>
              </Grid>              
              {state?.ticketId ?
                <Grid item  alignItems={'flex-start'}>
                  <Typography 
                    sx={{background: state?.status === 'Rejected' || state?.status === 'Transfer Failed' ? '#FFFBFA' : state?.status === 'Approved' ? '#F6FEF9' : '#F5F8FF', 
                      color: state?.status === 'Rejected' || state?.status === 'Transfer Failed' ? '#B42318' : state?.status === 'Approved' ? '#027A48' : '#1570EF', 
                      border: state?.status === 'Rejected' || state?.status === 'Transfer Failed' ? '1px solid #B42318' : state?.status === 'Approved' ? '1px solid #027A48' : '1px solid #1570EF', 
                      borderRadius: '12px', padding: '2px 10px 0px 10px', marginTop: 8,
                    }}> 
                    {state?.status}
                  </Typography>
                </Grid>: null }              
            </Grid>
            <Grid container spacing={3} pt='20px'>
              <Grid item xs={4} sm={2} textAlign={'center'} alignItems={'center'}>
                <Button 
                  sx={{ background: selectedRequest === 'toPatient' ? '#9F84FF' : '#EBE9FE', 
                    color:selectedRequest === 'toPatient' ? '#FFF' : '#000', 
                    borderRadius: '12px',                    
                    padding: '10px 0',
                    cursor: 'pointer', 
                    minWidth: {xs:'100px', sm:'120px'},
                    minHeight:{xs:'62px', sm:'44px'},
                    fontSize:'14px',
                    fontWeight:300,
                    textTransform:'none',
                    fontFamily:'Graphik-Regular, sans-seri',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: '#9F84FF',
                      color:'#fff',
                      fontFamily:'Graphik-Regular, sans-seri'
                    },
                    '&.Mui-disabled':{
                      background: state?.ticketId && selectedRequest === 'toPatient' ? '#667085' :'#F2F4F7',
                      color: state?.ticketId && selectedRequest === 'toPatient' ? '#D0D5DD' :'#98A2B3'                      
                    }                    
                  }} 
                  disableElevation
                  disableRipple
                  onClick={() =>  handleSelectedTab('toPatient')}
                  disabled = {state?.ticketId }  
                > To Patient </Button>
              </Grid>
              <Grid item xs={4} sm={3} textAlign={'center'} alignItems={'center'}>
                <Button 
                  sx={{background: selectedRequest === 'toSecondaryGuardian' ? '#9F84FF' :'#EBE9FE', 
                    color:selectedRequest === 'toSecondaryGuardian' ? '#FFF' : '#000', 
                    borderRadius: '12px',                     
                    padding:'10px 0', 
                    cursor:'pointer',
                    minWidth: {xs:'100px', sm:'190px'},
                    fontSize:'14px',
                    fontWeight:300,
                    textTransform:'none',
                    fontFamily:'Graphik-Regular, sans-seri',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: '#9F84FF',
                      color:'#fff',
                      fontFamily:'Graphik-Regular, sans-seri'
                    },
                    '&.Mui-disabled':{
                      background: state?.ticketId && selectedRequest === 'toSecondaryGuardian' ? '#667085' :'#F2F4F7',
                      color: state?.ticketId && selectedRequest === 'toSecondaryGuardian' ? '#D0D5DD' :'#98A2B3'                      
                    }
                  }}
                  disableElevation
                  disableRipple
                  disabled = {state?.ticketId }
                  onClick={() => handleSelectedTab('toSecondaryGuardian')}> To Secondary Guardian </Button>
              </Grid>
              <Grid item xs={4} sm={3} textAlign={'center'} alignItems={'center'}>
                <Button sx={{background: selectedRequest === 'toLegalGuardian' ? '#9F84FF' :'#EBE9FE', 
                  color:selectedRequest === 'toLegalGuardian' ? '#FFF' : '#000', 
                  borderRadius: '12px', 
                  padding:'10px 0', 
                  cursor:'pointer',
                  minWidth: {xs:'100px', sm:'190px'},
                  fontSize:'14px',
                  fontWeight:300,
                  textTransform:'none',
                  fontFamily:'Graphik-Regular, sans-seri',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#9F84FF',
                    color:'#fff',
                    fontFamily:'Graphik-Regular, sans-seri'
                  },
                  '&.Mui-disabled':{
                    background: state?.ticketId && selectedRequest === 'toLegalGuardian' ? '#667085' :'#F2F4F7',
                    color: state?.ticketId && selectedRequest === 'toLegalGuardian' ? '#D0D5DD' :'#98A2B3'                      
                  }
                }}
                disableElevation
                disableRipple
                disabled = {state?.ticketId }
                onClick={() => handleSelectedTab('toLegalGuardian')}> To Another Legal Guardian </Button>
              </Grid>
            </Grid>

            <Grid container marginTop={4}>
              {reasonForRejection !== undefined  && reasonForRejection !==''?
                <StyleCard sx={{padding: {xs:'0px', sm:'0px 36px'}}}>
                  <CardContent>
                    <Typography variant='h2'>This Request is Rejected!</Typography>
                    <InputLabel>Reason for Rejection</InputLabel>
                    <Typography sx={{border:'1px solid #D0D5DD', borderRadius:'8px', padding:'10px 14px', background:'#EEF0F6'}}>{reasonForRejection}</Typography>
                  </CardContent>
                </StyleCard> : null}

              <StyleCard sx={{padding: {xs:'0px', sm:'0px 36px'}}}>                
                <CardContent>
                  <Alert severity='info' sx={{color:'#1D2939', fontSize:'14px', fontWeight:400}}>
                    { selectedRequest === 'toPatient' ?
                      <Typography> Request by the new Guardian to transfer ownership of the MindWeal platform account to the patient who is now an adult.</Typography> 
                      : selectedRequest === 'toSecondaryGuardian' ?
                        <Typography>Request by the Primary Guardian to transfer ownership of the MindWeal platform account to the Secondary Guardian.</Typography>
                        : <Typography>Request by the Primary Guardian to transfer ownership of the MindWeal platform account to another Legal Guardian.</Typography>                 
                    } </Alert>
                  {selectedRequest === 'toPatient' && age <= 18 ?
                    <Box sx={{ background: '#FEF3F2', border: '1px solid #B42318', borderRadius: '8px', p: '10px 16px 0', mt:'10px' }}>
                      <Typography>The patient&apos;s age is under 18, and the account cannot be transferred until they turn 18. </Typography>
                    </Box>
                    : null}
                  <Box m='16px 0' pb='24px' borderBottom={'1px solid #EAECF0'}>
                    <Typography variant='h2'>Patient Details</Typography>
                    <Grid container spacing={2} mt='12px'>
                      <Grid item xs={6}>
                        <Typography sx={{ fontSize: '15px' }}> Name: {patientName !== undefined ? patientName?.split(' ')[0]?.charAt(0)?.toUpperCase()+patientName?.split(' ')[0]?.slice(1) +' ' + patientName?.split(' ')[1]?.charAt(0)?.toUpperCase()+ patientName?.split(' ')[1]?.slice(1) : ''} </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{fontSize:'15px'}}> DOB: {dateOfBirth !== undefined ? dateOfBirth : ''}</Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box m='16px 0' pb='24px' borderBottom={'1px solid #EAECF0'}>
                    <Typography variant='h2'>Primary Guardian Details</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>First Name</InputLabel>
                        <TextField
                          fullWidth
                          value={primaryGuardian?.firstName}
                          disabled 
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Last Name</InputLabel>
                        <TextField
                          fullWidth
                          value={primaryGuardian?.lastName}
                          disabled 
                        />
                      </Grid>
                    
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Phone Number</InputLabel>
                        <TextField
                          fullWidth
                          value={primaryGuardian?.phoneNumber}
                          disabled 
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Email</InputLabel>              
                        <TextField
                          fullWidth
                          value={primaryGuardian?.email}
                          disabled 
                        />
                      </Grid>
                    
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Relationship to the Patient</InputLabel>
                        <TextField
                          fullWidth
                          value={primaryGuardian?.relationShip}
                          disabled 
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  
                  <Box m='16px 0' pb='24px' borderBottom={'1px solid #EAECF0'}>
                    <Typography variant='h2'>Proposed New Guardian Details</Typography>
                    <>
                      {selectedRequest==='toSecondaryGuardian' && secondaryGuardian === undefined && props?.from !== 'admin' ?
                        <Typography color='#d32f2f'>Please fill Secondary Guardian details in Profile Information &gt; Guardian Information &gt; 
                      Secondary Guardian. </Typography> : selectedRequest==='toSecondaryGuardian' && secondaryGuardian === undefined && state?.initiatedByVMA ? 
                          <Typography color='#d32f2f'> Please ask guardian to fill Secondary Guardian details first. </Typography> : ''}

                      {/* {selectedRequest === 'toLegalGuardian' ?
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor='firstNameLegal'>First Name*</InputLabel>
                            <TextField
                              id='firstNameLegal'
                              fullWidth
                              defaultValue={''}
                              value={newGuardianFirstName !== undefined ? newGuardianFirstName : ''}  
                              onChange={(e:any) => {setNewGuardianFirstName(e.target.value);setEmptyFirstName(false);}}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '&.MuiInputBase-root fieldset': {
                                    borderColor: emptyFirstName ? '#d32f2f' : '#D0D5DD',
                                  },
                                },
                              }} 
                              disabled={state?.ticketId}                         
                            />
                            {emptyFirstName  ? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter First Name </Typography> : ''}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor='lastNameLegal'>Last Name*</InputLabel>
                            <TextField
                              id='lastNameLegal'
                              fullWidth
                              value={newGuardianLastName} 
                              onChange={(e:any) => {setNewGuardianLastName(e.target.value); setEmptyLastName(false);}}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '&.MuiInputBase-root fieldset': {
                                    borderColor: emptyLastName ? '#d32f2f' : '#D0D5DD',
                                  },
                                },
                              }}   
                              disabled={state?.ticketId}                         
                            />
                            {emptyLastName ? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Last Name </Typography> : ''}
                          </Grid>
                 
                 
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor='phoneNumberLegal'>Phone Number*</InputLabel>
                            <ReactPhoneInput
                              component={TextField}
                              label=''
                              onlyCountries={['us']}
                              country={'us'}
                              masks={{ us: '(...) ...-....' }}
                              autoFormat={true}
                              disableDropdown={true}
                              defaultErrorMessage={'Please enter a phone number'}
                              countryCodeEditable={false}                            
                              onChange={(e:any) => handlePhoneNumberChange(e)}
                              value={newGuardianPhoneNumber}
                              disabled={state?.ticketId}
                            />
                            {emptyPhone || invalidPhone ? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Valid Phone Number </Typography> : ''}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor='emailLegal'>Email*</InputLabel>
                            <TextField
                              fullWidth                            
                              id='emailLegal'
                              onChange={(e:any) => handleEmailChange(e)}
                              value={newGuardianEmail}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '&.MuiInputBase-root fieldset': {
                                    borderColor: emptyEmail || invalidEmail ? '#d32f2f' : '#D0D5DD',
                                  },
                                },
                              }}
                              disabled={state?.ticketId}
                            />
                            {emptyEmail || invalidEmail? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Valid Email </Typography> : ''}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor='relationLegal'>Relationship to the Patient*</InputLabel>
                            <TextField
                              id='relationLegal'
                              fullWidth
                              value={newGuardianRelation}
                              onChange={(e:any) => {seNewGuardianRelation(e.target.value); setEmptyRelation(false);}}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '&.MuiInputBase-root fieldset': {
                                    borderColor: emptyRelation ? '#d32f2f' : '#D0D5DD',
                                  },
                                },
                              }}
                              disabled={state?.ticketId}
                            />
                            {emptyRelation ? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Relationship </Typography> : ''}
                          </Grid>
                        </Grid> 
                        : */}
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <InputLabel htmlFor='firstName'>First Name*</InputLabel>
                          <TextField
                            id='firstName'
                            fullWidth
                            value={selectedRequest === 'toPatient' && age >= 18 ? patientName !== undefined && patientName?.split(' ')[0]?.charAt(0)?.toUpperCase()+patientName?.split(' ')[0]?.slice(1) : 
                              selectedRequest === 'toSecondaryGuardian' && secondaryGuardian?.firstName !=='' ? secondaryGuardian?.firstName : 
                                newGuardianFirstName !== undefined ? newGuardianFirstName : ''}  
                            onChange={(e:any) => {setNewGuardianFirstName(e.target.value);setEmptyFirstName(false);}}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '&.MuiInputBase-root fieldset': {
                                  borderColor: emptyFirstName ? '#d32f2f' : '#D0D5DD',
                                },
                              },
                            }} 
                            disabled={state?.ticketId || selectedRequest === 'toSecondaryGuardian' || (selectedRequest === 'toPatient' && age <= 18)}                         
                          />
                          {emptyFirstName  ? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter First Name </Typography> : ''}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputLabel htmlFor='lastName'>Last Name*</InputLabel>
                          <TextField
                            id='lastName'
                            fullWidth
                            value={selectedRequest === 'toPatient' && age >= 18 ? patientName !== undefined && patientName?.split(' ')[1]?.charAt(0)?.toUpperCase()+ patientName?.split(' ')[1]?.slice(1) : 
                              selectedRequest === 'toSecondaryGuardian' && secondaryGuardian?.lastName !=='' ? secondaryGuardian?.lastName : 
                                newGuardianLastName !== undefined ? newGuardianLastName : ''} 
                            onChange={(e:any) => {setNewGuardianLastName(e.target.value); setEmptyLastName(false);}}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '&.MuiInputBase-root fieldset': {
                                  borderColor: emptyLastName ? '#d32f2f' : '#D0D5DD',
                                },
                              },
                            }}   
                            disabled={state?.ticketId || selectedRequest === 'toSecondaryGuardian' || (selectedRequest === 'toPatient' && age <= 18)}                         
                          />
                          {emptyLastName ? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Last Name </Typography> : ''}
                        </Grid>
                      
                      
                        <Grid item xs={12} sm={6}>
                          <InputLabel htmlFor='phoneNumber'>Phone Number*</InputLabel>
                          <ReactPhoneInput
                            component={TextField}
                            label=''
                            onlyCountries={['us']}
                            country={'us'}
                            masks={{ us: '(...) ...-....' }}
                            autoFormat={true}
                            disableDropdown={true}
                            defaultErrorMessage={'Please enter a phone number'}
                            countryCodeEditable={false}                            
                            onChange={(e:any) => handlePhoneNumberChange(e)}
                            value={selectedRequest === 'toSecondaryGuardian' && secondaryGuardian?.phoneNumber !=='' ? `1 ${secondaryGuardian?.phoneNumber}` : 
                              newGuardianPhoneNumber !== undefined ? newGuardianPhoneNumber : ''}
                            disabled={state?.ticketId || selectedRequest === 'toSecondaryGuardian' || (selectedRequest === 'toPatient' && age <= 18)}
                          />
                          {emptyPhone || invalidPhone ? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Valid Phone Number </Typography> : ''}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputLabel htmlFor='email'>Email*</InputLabel>
                          <TextField
                            fullWidth                            
                            id='email'
                            onChange={(e:any) => handleEmailChange(e)}
                            value={selectedRequest === 'toSecondaryGuardian' && secondaryGuardian?.email !=='' ? secondaryGuardian?.email :
                              newGuardianEmail !== undefined ? newGuardianEmail : ''}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '&.MuiInputBase-root fieldset': {
                                  borderColor: emptyEmail || invalidEmail ? '#d32f2f' : '#D0D5DD',
                                },
                              },
                            }}
                            disabled={state?.ticketId || selectedRequest === 'toSecondaryGuardian' || (selectedRequest === 'toPatient' && age <= 18)}
                          />
                          {emptyEmail || invalidEmail? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Valid Email </Typography> : ''}
                        </Grid>
                      
                        <Grid item xs={12} sm={6}>
                          <InputLabel htmlFor='relation'>Relationship to the Patient*</InputLabel>
                          <TextField
                            id='relation'
                            fullWidth
                            value={selectedRequest === 'toPatient' && age >= 18 ? 'Self' : 
                              selectedRequest === 'toSecondaryGuardian' && secondaryGuardian?.relationShip !=='' ? secondaryGuardian?.relationShip : 
                                newGuardianRelation !== undefined ? newGuardianRelation : ''}
                            onChange={(e:any) => {seNewGuardianRelation(e.target.value); setEmptyRelation(false);}}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '&.MuiInputBase-root fieldset': {
                                  borderColor: emptyRelation ? '#d32f2f' : '#D0D5DD',
                                },
                              },
                            }}
                            disabled={state?.ticketId || selectedRequest === 'toSecondaryGuardian' || (selectedRequest === 'toPatient' && age <= 18)}
                          />
                          {emptyRelation ? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Relationship </Typography> : ''}
                        </Grid>
                      </Grid>
                      
                    </>
                  </Box>

                  {selectedRequest !=='toPatient' ?
                    <Box m='16px 0' pb='24px' borderBottom={'1px solid #EAECF0'}>
                      <Typography variant='h2'>Reason for Transfer</Typography>                      
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <InputLabel htmlFor='reason'>Reason*</InputLabel>
                          <TextField
                            id='reason'
                            fullWidth
                            required
                            multiline  
                            rows={2}
                            variant='filled'                        
                            placeholder='Please provide a brief explanation for the account transfer request'
                            onChange={(e:any) => {setReason(e.target.value); setEmptyReason(false);}}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '&.MuiInputBase-root fieldset': {
                                  borderColor: emptyReason ? '#d32f2f' : '#D0D5DD',
                                },
                              },
                            }}
                            value={reason}
                            disabled={state?.ticketId}
                          />
                          {emptyReason ? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Reason </Typography> : ''}
                        </Grid>
                      </Grid>
                    </Box> :''}


                  <Box m='16px 0' pb='24px' borderBottom={selectedRequest !=='toPatient' ? '1px solid #EAECF0' :'0px'}>
                    <Typography variant='h2'>Consent and Authorization</Typography>
                    <Typography variant='body1'> Please read the following statements carefully and check each box to confirm </Typography>
                    {selectedRequest === 'toPatient'?
                      <>
                        <FormControlLabel 
                          sx={{
                            '.MuiFormControlLabel-label':{
                              marginTop :'10px',
                            },
                            background:'#F2F4F7',
                            padding: '10px 12px',
                            border:'1px solid #EEF0F6',
                            borderRadius:'8px',
                            mb:'16px',
                            width:'100%'
                          }}
                          name='1'
                          disabled = {selectedRequest === 'toPatient' && age <= 18}
                          control={<Checkbox icon={<CheckBoxBlankIcon/>} onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent1Checked || state?.ticketId}/>}
                          label="I confirm that my child has turned 18, and I was their legal guardian until they reached adulthood." /> 

                        <FormControlLabel 
                          sx={{
                            '.MuiFormControlLabel-label':{
                              marginTop :'10px',
                            },
                            background:'#F2F4F7',
                            padding: '10px 12px',
                            border:'1px solid #EEF0F6',
                            borderRadius:'8px',
                            mb:'16px',
                            width:'100%'
                          }}
                          name='2'
                          disabled = {selectedRequest === 'toPatient' && age <= 18}
                          control={<Checkbox icon={<CheckBoxBlankIcon/>} onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent2Checked || state?.ticketId}/>}
                          label="I confirm that I have neither obtained nor plan to obtain guardianship of my adult child." /> 


                        <FormControlLabel 
                          sx={{
                            '.MuiFormControlLabel-label':{
                              marginTop :'10px',
                            },
                            background:'#F2F4F7',
                            padding: '10px 12px',
                            border:'1px solid #EEF0F6',
                            borderRadius:'8px',
                            mb:'16px',
                            width:'100%'
                          }}
                          name='3'
                          disabled = {selectedRequest === 'toPatient' && age <= 18}
                          control={<Checkbox icon={<CheckBoxBlankIcon/>} onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent3Checked|| state?.ticketId}/>}
                          label="I confirm that I have provided accurate and complete details about my child, the new account holder." /> 

                        <FormControlLabel 
                          sx={{
                            '.MuiFormControlLabel-label':{
                              marginTop :'10px',
                            },
                            background:'#F2F4F7',
                            padding: '10px 12px',
                            border:'1px solid #EEF0F6',
                            borderRadius:'8px',
                            width:'100%'
                          }}
                          name='4'
                          disabled = {selectedRequest === 'toPatient' && age <= 18}
                          control={<Checkbox icon={<CheckBoxBlankIcon/>} onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent4Checked || state?.ticketId}/>}
                          label="I recognize my obligation to inform my child about this transfer request." />
                      </>
                      :
                      <>
                        <FormControlLabel 
                          sx={{
                            '.MuiFormControlLabel-label':{
                              marginTop :'10px',
                            },
                            background:'#F2F4F7',
                            padding: '10px 12px',
                            border:'1px solid #EEF0F6',
                            borderRadius:'8px',
                            mb:'16px',
                            width:'100%'
                          }}
                          name='5'
                          control={<Checkbox icon={<CheckBoxBlankIcon/>} onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent5Checked || state?.ticketId}/>}
                          label="I, the current guardian, authorize the transfer of my MindWeal account ownership to the new guardian listed above." /> 

                        <FormControlLabel 
                          sx={{
                            '.MuiFormControlLabel-label':{
                              marginTop :'10px',
                            },
                            background:'#F2F4F7',
                            padding: '10px 12px',
                            border:'1px solid #EEF0F6',
                            borderRadius:'8px',
                            mb:'16px',
                            width:'100%'
                          }}
                          name='6'
                          control={<Checkbox icon={<CheckBoxBlankIcon/>} onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent6Checked || state?.ticketId}/>}
                          label="I confirm that I have provided the correct and complete contact information of the new guardian." /> 


                        <FormControlLabel 
                          sx={{
                            '.MuiFormControlLabel-label':{
                              marginTop :'10px',
                            },
                            background:'#F2F4F7',
                            padding: '10px 12px',
                            border:'1px solid #EEF0F6',
                            borderRadius:'8px',
                            mb:'16px',
                            width:'100%'
                          }}
                          name='7'
                          control={<Checkbox icon={<CheckBoxBlankIcon/>} onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent7Checked || state?.ticketId}/>}
                          label="I confirm that new guardian is legal guardian of the listed patient." /> 

                        <FormControlLabel 
                          sx={{
                            '.MuiFormControlLabel-label':{
                              marginTop :'10px',
                            },
                            background:'#F2F4F7',
                            padding: '10px 12px',
                            border:'1px solid #EEF0F6',
                            borderRadius:'8px',
                            mb:'16px',
                            width:'100%'
                          }}
                          name='8'
                          control={<Checkbox icon={<CheckBoxBlankIcon/>} onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent8Checked || state?.ticketId}/>}
                          label="I acknowledge that I am responsible for notifying new guardian of this transfer request." />


                        <FormControlLabel 
                          sx={{
                            '.MuiFormControlLabel-label':{
                              marginTop :'10px',
                            },
                            background:'#F2F4F7',
                            padding: '10px 12px',
                            border:'1px solid #EEF0F6',
                            borderRadius:'8px',
                            mb:'16px',
                            width:'100%'
                          }}
                          name='9'
                          control={<Checkbox icon={<CheckBoxBlankIcon/>} onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent9Checked || state?.ticketId}/>}
                          label="I certify that new guardian acknowledges and accepts the responsibilities of managing the MindWeal account for the listed patient." />

                        <FormControlLabel 
                          sx={{
                            '.MuiFormControlLabel-label':{
                              marginTop :'10px',
                            },
                            background:'#F2F4F7',
                            padding: '10px 12px',
                            border:'1px solid #EEF0F6',
                            borderRadius:'8px',
                            width:'100%'
                          }}
                          name='10'
                          control={<Checkbox icon={<CheckBoxBlankIcon/>} onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent10Checked || state?.ticketId}/>}
                          label="I understand that transferring account ownership will revoke my access to manage the listed patient within the MindWeal app." />
                      </>
                    }
                  </Box>

                  {selectedRequest !== 'toPatient' ?
                    <Box m='16px 0' pb='24px' borderBottom={'1px solid #EAECF0'}>
                      <Typography variant='h2'>Supporting Documentation</Typography>

                      <FormControlLabel 
                        sx={{
                          '.MuiFormControlLabel-label':{
                            marginTop :'10px',
                          },
                          background:'#F2F4F7',
                          padding: '10px 12px',
                          border:'1px solid #EEF0F6',
                          borderRadius:'8px',
                          mb:'16px',
                          width:'100%'
                        }}
                        name='11'
                        control={<Checkbox icon={<CheckBoxBlankIcon/>} onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent11Checked}/>}
                        label="I have submitted the supporting documentation via secure chat." /> 

                      <FormControlLabel 
                        sx={{
                          '.MuiFormControlLabel-label':{
                            marginTop :'10px',
                          },
                          background:'#F2F4F7',
                          padding: '10px 12px',
                          border:'1px solid #EEF0F6',
                          borderRadius:'8px',
                          mb:'16px',
                          width:'100%'
                        }}
                        name='12'
                        control={<Checkbox icon={<CheckBoxBlankIcon/>} onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent12Checked}/>}
                        label="I have emailed the supporting documentation to contact@mindweal.com" />
                    </Box> :''}

                  <Box sx={{m:{xs:0, sm:'16px'}, pb:{xs:0, sm:'24px'}}} borderBottom={ systemAlert !== null ? '1px solid #EAECF0' : 'none'}>
                    <Typography variant='h2'>Submitted by</Typography>
                    <Grid container columnSpacing={3} mt='24px' mb='24px'>
                      <Grid item xs={12} sm={6}> 
                        <InputLabel htmlFor='name'> Full Name*</InputLabel>
                        <TextField
                          id='name'
                          fullWidth  
                          placeholder='Enter Full Name'
                          value={name}
                          onChange={(e:any) => {setName(e.target.value); setEmptyName(false);}}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '&.MuiInputBase-root fieldset': {
                                borderColor: emptyName ? '#d32f2f' : '#D0D5DD',
                              },
                            },
                          }}
                          disabled={state?.ticketId || (selectedRequest === 'toPatient' && age <= 18)}
                        />
                        {emptyName ? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Name </Typography> : ''}
                      </Grid>
                    </Grid>

                    <InputLabel> Relationship to Patient</InputLabel>                
                    <FormControlLabel 
                      sx={{
                        '.MuiFormControlLabel-label':{
                          marginTop :'10px',
                          fontWeight:700
                        }
                      }}
                      disabled = {selectedRequest === 'toPatient' && age <= 18}
                      control={<Checkbox icon={<CheckBoxBlankIcon/>} onChange={(e:any)=>handleRelationCheckbox(e)} checked={isChecked || state?.ticketId}/>}
                      label={ selectedRequest === 'toPatient' ? 'Primary Guardian (until they turned 18)': 'Primary Guardian'} />      
                  </Box>

                  {systemAlert !== null ?
                    <Box sx={{ background: '#FEF3F2', border: '1px solid #B42318', borderRadius: '8px', p: '12px 16px' }}>
                      <Typography><b>System Alert:</b> {systemAlert}</Typography>
                    </Box>
                    : null}
                </CardContent> 
                 
              </StyleCard>
            </Grid>
            <Grid justifySelf={'center'} sx={{ marginTop:{xs:'6px', sm:'36px'} }}>
              {state?.ticketId && props?.from !== 'admin' ? 
                <Button variant='outlined' 
                  sx={{ marginRight: '20px', minWidth: { xs: '140px', sm: '200px' } }}
                  onClick={()=> navigate(ROUTE.ACCOUNT_MANAGEMENT)}
                >  Back  </Button>
                :
                <>
                  
                  {props?.from === 'admin' && !state?.initiatedByVMA?
                    <>                   
                      {state?.status === 'Rejected' || state?.status === 'Approved' || state?.status === 'Transfer Successful' ?
                        <Button variant='outlined' onClick={() => navigate(ROUTE.ADMIN_ACCOUNT_MANAGEMENT_REQUEST)}>Back</Button> 
                        : 
                        state?.status === 'Under Review' || state?.status === 'Transfer Failed' ?
                          <>
                            <Button type='submit' variant='outlined' onClick={() => handleReject()} sx={{ minWidth: { xs: '140px', sm: '200px' }, mr: '20px' }}> Reject </Button>
                            <Button type='submit' onClick={() => handleSendForConfirmation()} sx={{ minWidth: { xs: '140px', sm: '200px' } }}> Send For Confirmation </Button>
                          </>
                          :
                          <>
                            <Button type='submit' variant='outlined' onClick={() => handleReject()} sx={{ minWidth: { xs: '140px', sm: '200px' }, mr: '20px' }}> Reject </Button>
                            <Button type='submit' onClick={() => handleActionModal()} sx={{ minWidth: { xs: '140px', sm: '200px' } }}> Approve </Button>
                          </>
                      }                    
                    </>
                    :
                    <>
                      <Button variant='outlined' 
                        sx={{ marginRight: '20px', minWidth: { xs: '140px', sm: '200px' } }}
                        onClick={()=> navigate(state?.initiatedByVMA ? ROUTE.ADMIN_DASHBOARD : ROUTE.ACCOUNT_MANAGEMENT)}>  Cancel  </Button>
                      <Button disabled={disableSubmitButton || !allConsentsChecked || (selectedRequest==='toSecondaryGuardian' && secondaryGuardian === undefined)} type='submit' onClick={onSubmitForm}
                        sx={{ minWidth: { xs: '140px', sm: '200px' } }}> Submit </Button>
                    </>
                  }
                </>
              }
            </Grid>
            <Dialog  aria-labelledby="success-dialog"  open={showSuccessModal} >
              <DialogTitle id="simple-dialog-title" sx={{padding:'30px 0 0 0'}}>
                <Typography variant='h2'> {changeSuccessMessage ? 'Last request is Under Review':'Request Submitted Successfully'} </Typography></DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={'36px'}
                > 
                  <Typography variant='h3'> 
                    {changeSuccessMessage ? 'Please wait until we complete your last request.' : 'We will review and get back to you soon.'} </Typography>           
                </Grid>
              </DialogContent>
              <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => navigate(state?.initiatedByVMA ? `${ROUTE.ADMIN_PATIENT_INFO}/${state?.patientId}` : ROUTE.ACCOUNT_MANAGEMENT)} sx={{mb:'24px'}}> OKAY</Button>
              </DialogActions>
            </Dialog>

            <Dialog  aria-labelledby="success-dialog"  open={showConfirmationModal} >
              <DialogTitle id="simple-dialog-title" sx={{padding:'30px 0 0 0'}}>
                <Typography variant='h2'> 
                  {approveTextForModal ?  'Initiate Transfer' : 'Send For Confirmation' } </Typography></DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"  
                  padding='20px 0 10px'                
                > 
                  <Typography variant='h3'> 
                    {approveTextForModal ?
                      'Initiating the transfer will update the primary guardian to the proposed new guardian.'
                      :
                      <ul>
                        <li>An email will be sent to the current guardian informing them that the request has been approved by MindWeal and forwarded to the proposed new guardian for their acceptance.</li>
                        <li>An email will be sent to the new guardian with a link to an online form to accept the request to become the primary guardian.</li>
                      </ul>
                    }
                  </Typography>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant='outlined' sx={{mb:'24px'}} onClick={() => setShowConfirmationModal(false)}>Cancel</Button>
                {approveTextForModal ?
                  <Button onClick={() => handleAction('Approved')} sx={{ mb: '24px' }}> Transfer</Button> :
                  <Button onClick={() => handleAction('Sent for confirmation')} sx={{ mb: '24px' }}> Send</Button>}
              </DialogActions>
            </Dialog>

            <Dialog  aria-labelledby="success-dialog"  open={showRejectionModal} >
              <DialogTitle id="simple-dialog-title" sx={{padding:'30px 0 0 0'}}>
                <Typography variant='h2'> Reject Request </Typography></DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="left"
                  justifyContent="left" 
                  pt='20px'                                   
                > 
                  <InputLabel>Reason for Rejection*</InputLabel>
                  <TextField
                    multiline
                    placeholder='Enter reason here'
                    onChange={(e:any) => handleReasonChange(e)}
                    rows={4}
                    variant='filled'
                  />
                </Grid>
              </DialogContent>
              <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant='outlined' sx={{mb:'24px'}} onClick={() => {setShowRejectionModal(false); setReasonForRejection('');}}>Cancel</Button>
                <Button onClick={()=>handleAction('Rejected')} sx={{mb:'24px'}} disabled={reasonForRejection?.length < 2 || reasonForRejection === null}> Reject</Button>
              </DialogActions>
            </Dialog>

          </Container>
        </Grid >

      </Grid >
    </Page>
  );
};

export default TransferAccountOwnership;
 