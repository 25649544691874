
import { Backdrop, Box, Button, DialogTitle, Typography } from '@mui/material';
import React from 'react';

interface ConfirmBoxProps {
  message: string;
  onConfirm: () => void;
}

const ConfirmBox: React.FC<ConfirmBoxProps> = ({ message, onConfirm }) => {
  return (
    <Backdrop open={true}  sx={{
      zIndex: 1300 ,
    }}>
      <Box sx={{
        width: 670,
        p: 4,
        bgcolor: 'background.paper',
        borderRadius: 3,
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 1300 ,
      }}>
        
        <DialogTitle id="simple-dialog-title" sx={{ padding: '0px 24px 0' }}>
          Account Management Requests
        </DialogTitle>
        <Typography variant="h6" textAlign="center" mb={3}>
          {message}
        </Typography>
        <Button
          variant="contained"
          color="success"
          onClick={onConfirm}
        > Ok</Button>
      </Box>
    </Backdrop>
  );
};
export default ConfirmBox;
