import React, { useContext, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  CardMedia,
  styled,
  Card,
  CardProps,
  CardContent,
  Grid,
  TextField,
  InputLabel,
  Button,
} from '@mui/material';
import { useAppDispatch } from 'store/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Page } from 'components/Page';
import { ROUTE } from 'route/RouteEnums';
import { addVitalDetails, getVitalsDetails} from 'store/slices/vitalSlice';
import { vitalSchema } from 'utils/schema';
import { toast } from 'react-toastify';
import { AuthContext } from 'utils/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

const StyleCard = styled(Card)<CardProps>(({ theme }) => ({
  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
  border: '1px solid #EFF4FF',
  borderRadius: 12,
  background: '#FFF',
  margin: '12px 16px',
  position: 'relative',
  zIndex: 99,
  '& .MuiTypography-root': {
    marginBottom: '8px',
    position: 'relative',
  },
}));

const StyleVitalCard = styled(Card)<CardProps>(({ theme }) => ({
  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
  border: '1px solid #EFF4FF',
  borderRadius: 12,
  background: '#FFECC8',
  margin: '12px 16px',
  position: 'relative',
  zIndex: 99,
  '& .MuiTypography-root': {
    marginBottom: '8px',
    position: 'relative',
  },
}));
interface CustomizedState {
  validRoute: boolean;
}

const SystemVitals = (props: any) => {
  const dispatch = useAppDispatch();
  const { user } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      height:null, 
      weight:null,
      bloodPressure1:null,
      bloodPressure2:null
    },
    resolver: yupResolver(vitalSchema),
    mode: 'all',
  });

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const {
    validRoute
  } = state || {};

  useEffect(() => {
    if (validRoute === undefined) {
      navigate(ROUTE.DASHBOARD);
    }
  });

  useEffect(() => {
    if(user.patientId && !isDirty)
      dispatch(getVitalsDetails(user.patientId))
        .unwrap()
        .then((response: any) => {
          if (response?.status === 200) {
            Object.keys(response?.data).forEach((key: any) => {
              if (response.data[key] === null) setValue(key, '', { shouldValidate: false });
              else setValue(key, response.data[key], { shouldValidate: false });
            });
          } 
        });
  });

  const addVital = (formData: any) => {
    const payload = {...formData,patientId:user.patientId};
    dispatch(addVitalDetails(payload))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          const message = 'Vital details added successfully.';
          toast(message, {
            type: 'success',
            icon: true,
          });
          reset(formData);
          navigate(ROUTE.DASHBOARD);
        }
      });
  };

  const onSubmitForm = (formData: any) => {
    addVital(formData);
  };

  return (
    <Page backDir={ROUTE.DASHBOARD}>
      <Container component='main' maxWidth='md'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          component='form'
          onSubmit={handleSubmit((formData) => onSubmitForm(formData))}
        >
          <CardMedia
            component={'img'}
            src='images/vitals.svg'
            alt='card-img1'
            height='119px'
            sx={{ objectFit: 'contain', width: 'auto' }}
          />
          <Typography variant='h5' position={'relative'} width={'fit-content'} sx={{ textTransform: 'capitalize' }}>
            System Vitals
            <CardMedia
              component={'img'}
              src='images/vector.svg'
              alt='heading divider'
              sx={{ position: 'absolute', right: 0, width: 108, height: 'auto' }}
            />
          </Typography>
          <StyleCard>
            <CardContent>
              <Typography variant='h3'>Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor='weight'>Weight (in pounds)</InputLabel>
                  <TextField id='weight' variant='outlined' fullWidth placeholder='lb' {...register('weight')}
                    inputProps={{maxLength:6}}
                    error={!!errors.weight}
                    helperText={errors?.weight?.message}/>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor='height'>Height (in)</InputLabel>
                  <TextField id='height' variant='outlined' fullWidth placeholder='in' {...register('height')}
                    inputProps={{maxLength:6}}
                    error={!!errors.height}
                    helperText={errors?.height?.message}
                    title='Please enter height using only inches (for example, 64 inches instead of 5 feet 4 inches)'
                  />
                  
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor='systolic'>Systolic Blood Pressure</InputLabel>
                  <TextField id='systolic' variant='outlined' fullWidth placeholder='mmHg'
                    {...register('bloodPressure1')}
                    inputProps={{maxLength:3}}
                    error={!!errors.bloodPressure1}
                    helperText={errors?.bloodPressure1?.message}/>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor='diastolic'>Diastolic Blood Pressure</InputLabel>
                  <TextField id='diastolic' variant='outlined' fullWidth placeholder='mmHg' 
                    {...register('bloodPressure2')}
                    inputProps={{maxLength:3}}
                    error={!!errors.bloodPressure2}
                    helperText={errors?.bloodPressure2?.message}/>
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={{ xs: 'center', sm: 'flex-end' }}>
                  <Button sx={{ minWidth: { xs: 200, md: 150 } }} type='submit' disabled={!isDirty || !isValid}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </StyleCard>
          <StyleVitalCard>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <CardMedia
                    component={'img'}
                    src='images/systemVital.svg'
                    alt='card-img1'
                    height='119px'
                    sx={{ objectFit: 'contain', width: 'auto' }}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography variant='h1' position={'relative'} width={'fit-content'} sx={{ mb: '26px !important' }}>
                    System Vitals
                    <CardMedia
                      component={'img'}
                      src='images/dvider.svg'
                      alt='heading divider'
                      sx={{ position: 'absolute', right: 0, width: 108, height: 'auto' }}
                    />
                  </Typography>
                  <Typography variant='subtitle1'>
                    Gathering this data is key to tailoring your mental health care. It helps us minimize medication
                    side effects and prescribe the right dosage for your unique needs.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </StyleVitalCard>
        </Box>
      </Container>
    </Page>
  );
};

export default SystemVitals;
