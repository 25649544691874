import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { GaurdianRelation, NO, permissions } from 'utils/constants';
import { secondaryGuardianSchema } from 'utils/schema';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { AuthContext } from 'utils/AuthContext';
import { useAppSelector } from 'store/hooks';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { toast } from 'react-toastify';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { Restricted } from 'components/Restricted';

const SecondaryGaurdian = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(secondaryGuardianSchema),
    mode: 'onChange',
  });
  
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const guardians = useAppSelector((state) => state.user.guardians);
  const isProfileDetailsFetched = useAppSelector((state) => state.user.loaders.isProfileDetailsFetched);
  const { user } = useContext(AuthContext);
  const [isMobileNumberRequired, setIsMobileNumberRequired] = useState(false);

  const setSecondaryGuardianDetails = () => {
    if (props?.secondaryGaurdian) {
      Object.keys(props?.secondaryGaurdian).forEach((key: any) => {
        switch (key) {
        case 'isEmergency': {
          setIsEmergency(props?.secondaryGaurdian[key]);
          break;
        }
        case 'mobileNumber': {
          const str = props?.secondaryGaurdian?.mobileNumber.replaceAll(' ','');
          const mobile = str.replace(/[^\w\s]/g,'');
          const phoneNumbers = parseInt(mobile);          
          setValue('mobileNumber', `1${phoneNumbers}`, { shouldValidate: true, shouldDirty: false });
          break;
        }
        case 'isActiveCustody':
          setValue(key, props?.secondaryGaurdian[key] ? 'yes' : 'no', { shouldValidate: true, shouldDirty: false });
          break;
        default:
          setValue(key, props?.secondaryGaurdian[key], { shouldValidate: true, shouldDirty: false });
        }
      });
    } else {
      setValue('other', '', { shouldValidate: true, shouldDirty: false });
      setValue('id', 0, { shouldValidate: true, shouldDirty: false });
      setValue('isActiveCustody', NO, { shouldValidate: true, shouldDirty: false });
    }
  };


  const setIsEmergency = (isEmergency: any) => {
    if (isEmergency) {
      setValue('isEmergency', 'yes', { shouldValidate: true, shouldDirty: false });
      props.SGEmergencyContact('yes');
    } else {
      setValue('isEmergency', 'no', { shouldValidate: true, shouldDirty: false });
      props.SGEmergencyContact('no');
    }
  };

  useEffect(() => {
    setSecondaryGuardianDetails();
    //eslint-disable-next-line
  }, [guardians]);

  //handle toolkit (clickout)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  //tooltip content
  const tooltipContent = (
    <>
      <h3>Emergency Contact Person</h3>
      <p> This person will be contacted in case of emergencies. </p>
    </>
  );

  //submit SG form
  const onSubmitForm = async (formData: any) => {    
    if (formData.mobileNumber === user?.mobileNumber || formData.email.toLowerCase() === user?.email.toLowerCase()) {
      toast('Please provide different details than primary guardian', {
        type: 'error',
        icon: true,
      });
      return;
    }
    if(formData?.isEmergency === 'yes' && formData?.mobileNumber?.length === 1)
    {
      setIsMobileNumberRequired(true);
      return;
    }

    function formatPhoneNumber(phoneNumberString:any) {
      const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return null;
    }
    if(isValid && isDirty){ 
      const payload = guardians
        .filter((guardian: any) => guardian.guardianCategory !== formData.guardianCategory)
        .concat({
          ...formData,
          id: formData?.id ? formData.id : null,
          isEmergency: formData.isEmergency === 'yes' ? true : false,
          guardianCategory: 'secondary',
          isActiveCustody: false,
          other: formData.relation === 'other' ? formData?.other : '',
          mobileNumber: formData?.mobileNumber ? formatPhoneNumber(formData?.mobileNumber.slice(1)) : '',
          email: formData?.email ? formData?.email.toLowerCase() : '' 
        });
      props.onUpdateGuardian(payload, () => resetData(formData));
    }else{
      toast('No changes to update.',{
        type:'info',
        icon:true
      });
    }
  };

  const handleSkipStep = () =>{
    props.skipStep();
  };

  const resetData = (formData: any) => {
    const message1 = 'Secondary guardian updated successfully.';
    const message2 = 'Secondary guardian updated successfully. You can upload your picture.';
    toast(props.pictureAvailable ? message1 : message2, {
      type: 'success',
      icon: true,
    });
    reset(formData);
  };

  //return secondary Gaurdian Information component
  return (
    <>
      <Container component='main' maxWidth='sm' sx={{ padding: '0px !important' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ mt: 1 }} component='form' onSubmit={handleSubmit((formData) => onSubmitForm(formData))}>
            <Box
              sx={{
                marginBottom: 2,
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Typography variant='body1' gutterBottom sx={{ backgroundColor: '#FFFBF1', padding: '8px 8px 4px' }}>
                    <b>IMPORTANT: </b>This information is MANDATORY if the patient has a Secondary Guardian. 
                    Secondary Guardian is contacted if the primary guardian is unreachable. 
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputLabel htmlFor='firstName'>First Name</InputLabel>

                <TextField
                  id='firstName'
                  autoComplete='firstName'
                  placeholder='Enter Guardian’s First Name'
                  fullWidth
                  {...register('firstName')}
                  error={!!errors.firstName}
                  helperText={errors.firstName ? `${errors.firstName?.message}` : ''}
                  disabled={!user?.permissions.includes('EDIT_PROFILE')}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel htmlFor='lastName'>Last Name</InputLabel>
                <TextField
                  id='SGlasttName'
                  autoComplete='lastName'
                  placeholder='Enter Guardian’s Last Name'
                  fullWidth
                  {...register('lastName')}
                  error={!!errors.lastName}
                  helperText={errors.lastName ? errors.lastName?.message : ''}
                  disabled={!user?.permissions.includes('EDIT_PROFILE')}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel htmlFor='relation'>Relationship to the patient</InputLabel>
                <TextField
                  id='relation'
                  autoComplete='relation'
                  placeholder='Select relationship to the patient'
                  select // tell TextField to render select
                  fullWidth
                  {...register('relation')}
                  error={!!errors.relation}
                  helperText={errors.relation ? errors.relation?.message : ''}
                  value={watch('relation') ? watch('relation') : 'select_relation_to_the_child'}
                  disabled={!user?.permissions.includes('EDIT_PROFILE')}
                >
                  <MenuItem key={-1} value={'select_relation_to_the_child'} disabled>
                    Select relation to the child
                  </MenuItem>
                  {GaurdianRelation.map((option) => (
                    <MenuItem key={option.name} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel htmlFor='mobileNumber'>Phone Number{watch('isEmergency') === 'yes' ? '*':''}</InputLabel>
                <ReactPhoneInput
                  component={TextField}
                  label=''
                  onlyCountries={['us']}
                  placeholder='+1 (555) 000-0000'
                  country={'us'}
                  masks={{ us: '(...) ...-....' }}
                  autoFormat={true}
                  disableDropdown={true}
                  defaultErrorMessage={'Please enter a phone number'}
                  {...register('mobileNumber')}                
                  inputProps={{
                    helperText: errors.mobileNumber ? errors.mobileNumber?.message : '',
                    error: !!errors.mobileNumber,                      
                  }}
                  countryCodeEditable={false}
                  onChange={(e) => {
                    setValue('mobileNumber', e, { shouldValidate: true, shouldDirty: true });
                    setIsMobileNumberRequired(false);
                  }}
                  value={watch('mobileNumber') ? watch('mobileNumber') : null}
                  disabled={!user?.permissions.includes('EDIT_PROFILE')}                  
                />
                {isMobileNumberRequired ? <Typography color='#d32f2f' fontSize='0.75rem'>
                  Please enter Phone Number</Typography> : ''}
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel htmlFor='email'>Email Address</InputLabel>
                <TextField
                  id='email'
                  autoComplete='email'
                  placeholder='Enter email address'
                  fullWidth
                  {...register('email')}
                  error={!!errors.email}
                  helperText={errors.email ? errors.email?.message : ''}
                  disabled={!user?.permissions.includes('EDIT_PROFILE')}
                  inputProps={{
                    maxLength: 255,                    
                  }}
                />
              </Grid>
            </Grid>
            {!props?.showEmergency?.isEmergency &&      
            <Box
              sx={{
                marginTop: 2,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <InputLabel htmlFor='secondary-gaurdian' sx={{ display: 'flex', alignItems: 'center', fontSize: 13 }}>
                    <b>Do you wish to make Secondary Guardian as an Emergency Contact person?</b>
                    
                    <Tooltip
                      ref={tooltipRef}
                      open={open}
                      title={tooltipContent}
                      arrow
                      placement='bottom'
                      enterTouchDelay={0}
                    >
                      <ErrorOutlineOutlinedIcon onMouseEnter={() => setOpen(true)} fontSize='small' />
                    </Tooltip>
                    
                  </InputLabel>

                  <FormControl error={!!errors.isEmergency}>
                    <RadioGroup row name='isEmergency' value={watch('isEmergency') === 'yes' ? 'yes' : 'no'}>
                      <FormControlLabel
                        value='yes'
                        control={<Radio />}
                        label='Yes'
                        {...register('isEmergency')}
                        disabled={!user?.permissions.includes('EDIT_PROFILE')}
                      />
                      <FormControlLabel
                        value='no'
                        control={<Radio />}
                        label='No'
                        {...register('isEmergency')}
                        disabled={!user?.permissions.includes('EDIT_PROFILE')}
                      />
                    </RadioGroup>
                    <FormHelperText>{errors.isEmergency ? errors.isEmergency?.message : ''}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Box> }
            {props?.disableSaveButton ?
              <Grid container>
                <Alert severity='warning'>Please save Primary Guardian Information first.</Alert>
              </Grid> : ''}

            {props?.disableSaveButton ?
              <Grid container>
                <Alert severity='warning'>Please save Primary Guardian Information first.</Alert>
              </Grid> : ''}

            <Restricted permission={permissions.EDIT_PROFILE}>
              <Grid container justifyContent='flex-end'>
                <Grid item> 
                  <Button size='small' variant='text' sx={{ mt: 2, mb: 2 }}
                    onClick={()=>handleSkipStep()}> 
                  Skip This Step </Button> 
                </Grid>
                <Grid item>                  
                  <LoadingButton
                    loading={isProfileDetailsFetched}
                    variant='contained'
                    type='submit'
                    fullWidth
                    sx={{ mt: 2, mb: 2 }}
                    disabled = {props?.disableSaveButton}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </Restricted>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default SecondaryGaurdian;
