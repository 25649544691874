/* eslint-disable indent */
import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';

import MultiSelect from 'components/QuestionBuilders/MultiSelect';
import SingleSelect from 'components/QuestionBuilders/SingleSelect';
import UserInput from 'components/QuestionBuilders/UserInput';
import MultiSelectPrimary from 'components/QuestionBuilders/MultiSelectPrimary';
import SingleSelectPrimary from 'components/QuestionBuilders/SingleSelectPrimary';
import DomainInfo from 'components/QuestionBuilders/DomainInfo';
import { useAppDispatch } from 'store/hooks';
import { getMFSHStatus, saveMcatAnswers, saveWellbeingAnswers } from 'store/slices/mcatSlice';
import { ROUTE } from 'route/RouteEnums';
import { AuthContext } from 'utils/AuthContext';

interface CustomizedState {
  wellbeingUser?: string;
}

const Assessment = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const {
    wellbeingUser
  } = state || {};

  const [question, setQuestion] = useState<any>({});
  const [type, setType] = useState<string>('');
  const [answers, setAnswers] = useState<any>({});
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [displayThankyouModal, setDisplayThankyouModal] = useState<boolean>(false);
  const [displayQuestion, setDisplayQuestion] = useState<boolean>(false);
  const [displayProfileCompletionModal] = useState<boolean>(false);
  const [hasResult, setHasResult] = useState<boolean>(false);
  const [mfshStatus, setmfshStatus] = useState<string>('');

  const {user} = useContext(AuthContext);

  useEffect(() => {
    dispatch(getMFSHStatus(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setmfshStatus(response?.data);
           if(!user?.isExistingUser && response?.data !== 'COMPLETED'){
            navigate(ROUTE.DASHBOARD);
            return;
           }
        }
      });
    if(wellbeingUser === undefined && location.pathname === '/wellbeing/assessment'){
      navigate(ROUTE.DASHBOARD);
    }
    
    const answerDetails = {
      launchType: wellbeingUser
    };
    if (Object.keys(question).length === 0) {
      submitAnswer(answerDetails);
    }
    if (type === 'none') {
      setType('domaininfo');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, displayQuestion, user?.patientId, mfshStatus]);

  const submitAnswer = (answerDetails: any) => {
    const data= {
      ...answerDetails,
      patientId:user?.patientId
    };
    setDisplayQuestion(false);
    dispatch(wellbeingUser === undefined ? saveMcatAnswers(data) : saveWellbeingAnswers(data))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          const { question } = response.data;
          if (response?.data?.testCompleted) {
            setDisplayThankyouModal(true);
            setHasResult(response?.data?.hasResult);
          }
          const questionDetails = {
            questionId: response?.data?.quizId,
            question: question?.text,
            type: question?.type,
            info: question?.info,
            informationSection: question?.informationSection,
            header: wellbeingUser !== undefined ? question?.header : null,
            launchType: wellbeingUser ?? null,
            heading: question?.heading,
            instruction: question?.instruction,
            progress:response?.data?.progress,
            event:response?.data?.event,
            imageId:question?.imageId,
            prompt: question?.prompt,
            subTitle: question?.subTitle,
            warn: question?.warn,
            wellnessSubtitle: question?.wellnessSubtitle,
            assessmentType: wellbeingUser === undefined ? 'mcat' :'wellness',
            buttonText: question?.buttonText,
          };
          setQuestion(questionDetails);
          setAnswers(question?.answerSection);
          setType(question?.answerMode);
          if (question?.type === 'info') {
            setDisplayModal(true);
          }
          setDisplayQuestion(true);
        }
      });
  };

  const handleClose = () => {
    setDisplayModal(false);
  };

  const openModal = () => {
    setDisplayModal(true);
  };

  const handleDomainModal = () => {
    submitAnswer({ quizId: question.questionId });
  };

  const displayQuestions = () => {
    if (!displayThankyouModal) {
      switch (type) {
        case 'radio':
          return <SingleSelect question={question} answers={answers} handleNext={submitAnswer} openModal={openModal} />;
        case 'checkbox':
          return <MultiSelect question={question} answers={answers} handleNext={submitAnswer} openModal={openModal} />;
        case 'inputbox':
          return <UserInput question={question} handleNext={submitAnswer} openModal={openModal} />;
        case 'radioPrimary':
          return <SingleSelectPrimary question={question} answers={answers} 
          handleNext={submitAnswer} openModal={openModal} />;
        case 'checkboxPrimary':
          return <MultiSelectPrimary question={question} answers={answers}
             handleNext={submitAnswer} openModal={openModal} />;
        case 'domaininfo':
          return <DomainInfo info={question} imageId={question?.imageId} 
          handleDomainModal={handleDomainModal} />;
      }
    }
  };

  return (
    <Container component='section' maxWidth='md'>

      <Box mt={8}>{!displayQuestion && !displayThankyouModal ?
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <CircularProgress />
        </Box>
        : displayQuestions()} </Box>
      {/* Continue later dialog */}
      <Dialog open={displayModal} fullWidth={false}>
        <DialogContent>
          <IconButton
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {question?.type !== 'info' ? (
            <Typography variant='h1' gutterBottom textAlign={'center'}>
              Tired?
            </Typography>
          ) : null}
          <Box margin={'5px 10px'}>
            {question?.type !== 'info' ? (
              <Typography>
                You can pause and resume the assessment as needed, but it&apos;s vital to complete it before your
                appointment. Please understand that if it&apos;s not completed, unfortunately, we will have to cancel
                the appointment.
              </Typography>
            ) : (
              <Typography>Domain information here</Typography>
            )}
          </Box>
          <Grid container item xs={12} mt={4} sx={{ justifyContent: 'center' }}>
            {question?.type !== 'info' ? (
              <Box sx={{ textAlign: { xs: 'center' } }}>
                <Button variant='outlined' sx={{ marginRight: '8px', marginBottom: { xs: 2, sm: 0 } }}
                  onClick={handleClose}>
                  No, I will continue
                </Button>
                <Button onClick={() => navigate(ROUTE.DASHBOARD)}>Save and Close</Button>
              </Box>
            ) : (
              <Button onClick={handleClose}> I Understand </Button>
            )}
          </Grid>
        </DialogContent>
      </Dialog>

      {/*  Thank you dialog */}
      {displayThankyouModal ?
       <>
       <Grid container sx={{ display:'flex', justifyContent:'center', mb:'24px'}}>
        <CardMedia 
        component={'img'} 
        src='../images/assessmentCompleted.svg' 
        alt='card-img1' 
        height='120px'
        sx={{objectFit:'contain',  width:'auto'}}
        />
        </Grid>
          <Typography gutterBottom textAlign={'center'} 
          sx={{fontSize:'36px', fontWeight:600, fontFamily:'Playfair Display'}}>
            Congratulations !
          </Typography>
          {wellbeingUser === undefined ?
          <Typography sx={{fontSize:'18px'}} textAlign={'center'} > 
          Your M-CAT assessment is now completed </Typography>: null }
          <Box sx={{mt:'36px', background:'#fff', padding:'30px 24px 4px 24px'}}>
            <Typography textAlign={'left'} fontSize='18px'>
            Great job finishing up the questions. Your detailed responses provide our specialists with 
            valuable insights, enabling them to craft a more personalized treatment plan for your forthcoming
            clinical visit.
            </Typography>
            <Box sx={{background:'#f8f5ff', borderRadius:'8px', padding:'20px 12px', margin:'24px 0'}}>
            <Grid container>
                <Grid item xs={1}>
                  <InfoOutlinedIcon fontSize='small' color='primary' sx={{ mr: '10px' }} />
                </Grid>
                <Grid item xs={11} mt='2px'>
                If you haven&apos;t set up an appointment yet, please do so 
                <b> — we&apos;re ready to take the next step with you !!</b> 
                </Grid>
              </Grid>
            
            </Box>
          </Box>
          <Grid container item xs={12} mt={4} sx={{ justifyContent: 'center' }}>
            {hasResult ? 
            <Button onClick={() => navigate(ROUTE.MCAT_RESULT)}>View Result</Button>
            :
            <Button onClick={() => navigate(ROUTE.DASHBOARD)}>Exit Assessment</Button>
            }
          </Grid>
          </>
       : null }

      <Dialog open={displayProfileCompletionModal} fullWidth={false}>
        <DialogContent>
          <Typography variant='h3' textAlign={'center'}>
            Profile completion
          </Typography>
          <Box m={1}>
            <Typography mt={2}>Kindly fill out your profile details before starting your test.</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button sx={{ marginTop: 4 }} onClick={() => navigate(ROUTE.PROFILE)}>
              Okay
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

    </Container>
  );
};

export default Assessment;

