import React, { useContext, useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  CardProps,
  Container,
  Grid,
  IconButton,
  Typography,
  styled,
  Link,
  Button,
  Dialog,
  Box,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Page } from 'components/Page';
import { ROUTE } from 'route/RouteEnums';
import { AuthContext } from 'utils/AuthContext';
import { formatPhoneNumber } from 'utils/functions';
import ChangePassword from 'components/ChangePassword';

const AdminProfile = (props: any) => {
  const [showChangePasswordDialog, setShowChangePasswordDialog] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const StyleCard = styled(Card)<CardProps>(({ theme }) => ({
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
    border: '1px solid #EFF4FF',
    borderRadius: 12,
    background: '#FFF',
    margin: '12px 16px',
    position: 'relative',
    zIndex: 99,
    padding: 20,
    '& .MuiTypography-root': {
      position: 'relative',
    },
    '& .MuiCardContent-root:last-child': {
      padding: 0,
    },
    '.MuiTypography-h2': {
      fontSize: 20,
    },
    '& .Mui-selected': {
      background: '#9F84FF',
      color: '#FFF!important',
      borderRadius: '23px',
      width: '100%',
    },
    '& .MuiTab-root': {
      margin: '0 60px!important',
    },
  }));

  return (
    <Page backDir={ROUTE.ADMIN_DASHBOARD} buttonText='Dashboard'>
      <Container component='main' maxWidth='md'>
        <Grid container spacing={2}>
          <Grid item xs={10} display={'flex'} alignItems={'center'}>
            <Typography variant='h5' gutterBottom>
              Admin Profile
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton>
              <CardMedia
                component={'img'}
                src={'/images/Group 9513.png'}
                alt='patient picture'
                sx={{
                  border: '1px solid #333',
                  borderRadius: '50%',
                  height: { xs: '70px', md: '106px' },
                  width: { xs: '70px', md: '106px' },
                  cursor: 'default',
                }}
              />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <StyleCard sx={{ borderTop: '12px solid #FFC44D !important' }}>
              <CardContent>
                <Grid container borderBottom={'1px solid #EAECF0'}>
                  <Grid item xs={12}>
                    <Typography variant='h2' mb={2}>
                      {user.firstName} {user.lastName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant='h3' sx={{ fontSize: 14 }} pt={2}>
                      Your Position
                    </Typography>
                    <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500' }}>
                      {user.roleType}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='h3' sx={{ fontSize: 14 }} pt={2}>
                      User Role
                    </Typography>
                    <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500' }}>
                      {user.roleType}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='h3' sx={{ fontSize: 14 }} pt={2}>
                      Email Address
                    </Typography>
                    <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500' }}>
                      {user.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='h3' sx={{ fontSize: 14 }} pt={2}>
                      Phone Number
                    </Typography>
                    <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500' }}>
                      {formatPhoneNumber(user.mobileNumber)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container mt={4}>
                  <Grid item xs={12}>
                    <Link href={ROUTE.SIGN_OUT}>SIGN OUT</Link>
                  </Grid>
                </Grid>
              </CardContent>
            </StyleCard>
          </Grid>
          <Grid item xs={12}>
            <StyleCard>
              <CardContent>
                <CardMedia
                  component={'img'}
                  src='/images/Group-9550-1.png'
                  alt='card-img'
                  sx={{ width: 389, height: 'auto', position: 'absolute', right: 0, top: 0 }}
                />
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}

                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    gap={1.5}
                  >
                    <Typography variant='h2'>Password Settings</Typography>
                    <Grid container >
                      <Grid item xs={8}>
                        <Typography variant='subtitle1'>
                            Update your password to keep your account secure.
                        </Typography>
                      </Grid>
                      <Grid item xs={4} alignItems={'end'} textAlign={'end'}>
                        <Button variant='text' size='small' sx={{ fontSize: '14px', padding: 0 }}
                          onClick={() => { setShowChangePasswordDialog(true); }}>CHANGE PASSWORD</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </StyleCard>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        open={showChangePasswordDialog} disableEnforceFocus>
        <Box sx={{ textAlign: 'right', m: '10px 20px 0 0' }}>
          <Typography sx={{ fontSize: '18px', cursor: 'pointer' }}
            onClick={() => setShowChangePasswordDialog(false)}
          >X</Typography>
        </Box>
        <DialogTitle id="simple-dialog-title" sx={{ padding: '0px 24px 0' }}>
          Change Password
        </DialogTitle>
        <DialogContent>
          <ChangePassword onCancel={()=>setShowChangePasswordDialog(false)}/>   
        </DialogContent>
      </Dialog>
    </Page>
  );
};

export default AdminProfile;
