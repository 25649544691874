import React, { useContext, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Badge, CardMedia, CircularProgress, Fab, Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {
  CometChatMessageComposerAction, CometChatTheme, 
  CometChatThemeContext, CometChatUIKit,
  CometChatUIKitConstants, MessageBubbleAlignment, CometChatMessages,
  CometChatConversations
} from '@cometchat/chat-uikit-react';
import { ActionSheetStyle, AvatarStyle, ListItemStyle, CometChatTextBubble } from '@cometchat/uikit-elements';
import {
  MessageHeaderConfiguration,
  MessageComposerConfiguration,
  MessageComposerStyle,
  ConversationsConfiguration,
  ConversationsStyle,
  MessagesConfiguration,
  MessageListConfiguration,
  ComposerId,
  MessagesStyle,
} from '@cometchat/uikit-shared';

import { createComponent } from '@lit-labs/react';
import {CometChat} from '@cometchat/chat-sdk-javascript';
import logo from 'assets/images/logo.svg';
import chat from 'assets/images/chat1.svg';

const Chatbox = (props: any) => {
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [open, setOpen] = useState(false);
  const[cometUser, setCometUser] = useState<any>();
  const [group, setGroup] = useState<any>();
  const { theme } = useContext(CometChatThemeContext);

  useEffect(()=>{
    const listenerID = 'mindWeal';
    CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
        onTextMessageReceived: () => {
          setUnreadMessageCount(unreadMessageCount+1);
        },
        onMediaMessageReceived:()=>{
          setUnreadMessageCount(unreadMessageCount+1);
        },
        onCustomMessageReceived:()=>{
          setUnreadMessageCount(unreadMessageCount+1);
        }
      })
    );
  
    CometChat.getUnreadMessageCountForAllGroups().then(
      (array:any) => {
        const sumValues = (obj:any) => Object.keys(obj).reduce((acc, value) => acc + obj[value], 0);
        setUnreadMessageCount(sumValues(array));
      }, error => {
        console.log('Error in getting message count', error);
      }
    );

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        setCometUser(undefined);
        setGroup(undefined);
      }
    };
  
    window.addEventListener('visibilitychange', handleVisibilityChange);

    return(() =>{
      CometChat.removeMessageListener(listenerID);
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    });

  },[open, unreadMessageCount, props?.showLoader]);

  const mcStyle = new MessageComposerStyle({
    inputBorder: 'none',
    textColor: '#000',
    placeHolderTextColor: '#fff',
    dividerTint: '#EAECF0',
    attachIcontint: '#3476ff',
    textFont:'Graphik-Regular,sans-serif'
  });

  const actionStyle = new ActionSheetStyle({
    height: '150px',
    width: '200px',
    ActionSheetSeparatorTint: '#FEF0C7',
  });

  const getAttachmentOptions = (item: CometChat.User | CometChat.Group, composerId:ComposerId) => {
    // eslint-disable-next-line max-len
    const options: CometChatMessageComposerAction[] = CometChatUIKit.getDataSource().getAttachmentOptions(theme, composerId);
    const indexes = [1, 2, 4];
    const filteredOptions = options.filter((value, index) => !indexes.includes(index));
    return filteredOptions;
  };

  const auxButtonView = () => {
    return (<>
      <a rel="noopener noreferrer" href='https://kb.mindweal.com/en/mindwealpatientknowledgebase/communication-policy' 
        target='_blank' style={{textDecoration:'none'}}> 
    Communication Policy </a>
    </>);
  };

  const mcConfig = new MessageComposerConfiguration({
    messageComposerStyle: mcStyle,
    hideVoiceRecording: true,
    hideLiveReaction: true,
    hideLayoutMode: true,
    headerView: ()=>{<></>;},
    attachmentIconURL: '/images/attachment.svg',
    attachmentOptions: getAttachmentOptions,
    actionSheetStyle: actionStyle,
    auxilaryButtonView: () => { return auxButtonView(); },

  });

  const convStyle = new ConversationsStyle({
    width: '325px',
    height: '430px',
  });

  const avatarStyle = new AvatarStyle({
    backgroundColor: '#FFECC0',
    nameTextColor: '#000',
    backgroundSize: '36px',
  });

  const lStyle = new ListItemStyle({
    hoverBackground: 'none',
    titleColor: '#000',
  });

  const convConfig = new ConversationsConfiguration({
    disableTyping: false,
    hideError: true,
    hideSeparator: true,
    menu: ' ',
    avatarStyle: avatarStyle,
    listItemStyle: lStyle,
    options: () => [],
  });

  function getMentionsTextFormatter(textMessage: CometChat.TextMessage) {  
    const mentionsFormatter =  
        CometChatUIKit.getDataSource().getMentionsTextFormatter({ theme });  
    mentionsFormatter.setCometChatUserGroupMembers(textMessage.getMentionedUsers());  
    mentionsFormatter.setMessage(textMessage);  

    mentionsFormatter.setMessageBubbleAlignment(MessageBubbleAlignment.left);  

    return mentionsFormatter;  
  }  

  function getUrlTextFormatter(textMessage: CometChat.TextMessage) {  
    const urlFormatter =  
        CometChatUIKit.getDataSource().getUrlTextFormatter({  
          theme,  
          alignment: MessageBubbleAlignment.left,          
        }); 
    return urlFormatter;  
  } 

  const TextMessageBubble = createComponent({
    tagName: 'cometchat-text-bubble',
    elementClass: CometChatTextBubble,
    react: React
  });

  const getTemplates = () => {
    const templates = CometChatUIKit.getDataSource().getAllMessageTemplates( new CometChatTheme({}));
    templates.forEach((template) => {
      if (template.contentView && template.category === CometChatUIKitConstants.MessageCategory.message &&
         template.type === CometChatUIKitConstants.MessageTypes.text) {       
        // eslint-disable-next-line max-len
        const contentView = template.contentView = (message: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) => {
          const style = {
            textFont: 'inter',
            textColor: 'black'
          };
          return <TextMessageBubble text={(message as CometChat.TextMessage).getText()} textStyle={style} 
            textFormatters={[getUrlTextFormatter(message as CometChat.TextMessage), 
              getMentionsTextFormatter(message as CometChat.TextMessage)]} />;
        };
        template.contentView = (message: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) => {
          if (message?.getSender()?.getRole() === 'guardian') {
            return <div style={{
              background: '#FFC44D',
              padding: '3px',
              borderRadius: '12px 0px 12px 12px',
              alignItems: 'center',
            }}>
              {contentView(message, _alignment)}</div>;
          } else {
            return <div style={{
              background: '#FEF0C7',
              padding: '3px',
              borderRadius: '0px 12px 12px 12px',
              alignItems: 'center',
            }}>
              {contentView(message, _alignment)}</div>;
          }
        };
      }
      if (template.category === 'action' && template.type === 'groupMember') {
        template.bubbleView = () => <></>;
      }
    });
    return templates;
  };

  const getCategories = () => {
    const defaultCategories = CometChatUIKit.getDataSource().getAllMessageCategories();
    const myCategoreis = defaultCategories.filter((ele) => ele!=='action');
    return myCategoreis;
  };
  
  const setTypes = () => {
    const defaultTypes = CometChatUIKit.getDataSource().getAllMessageTypes();
    const myTypes = defaultTypes.filter((ele) => ele!=='groupMember');
    return myTypes;
  };

  const msgListConfig = new MessageListConfiguration({
    templates: getTemplates(),
    // eslint-disable-next-line max-len
    messagesRequestBuilder: new CometChat.MessagesRequestBuilder().setLimit(30).setCategories(getCategories()).setTypes(setTypes())
  });


  const msgHeaderConfig = new MessageHeaderConfiguration({
    avatarStyle: avatarStyle,
    onBack:() => {setCometUser(undefined); setGroup(undefined);} 
  });

  const msgConfig = new MessagesConfiguration({
    messageComposerConfiguration: mcConfig,
    hideDetails: true,
    messageListConfiguration: msgListConfig,
    messageHeaderConfiguration: msgHeaderConfig
  });

  const handleConversationClick = (conversation: CometChat.Conversation) => {
    if(conversation.getConversationType() === 'user'){
      const chatUser = conversation.getConversationWith() as CometChat.User;
      // navigate to messages component and pass chatUser
      setCometUser(chatUser);
    }else{
      const chatGroup = conversation.getConversationWith() as CometChat.Group;
      // navigate to messages component and pass chatGroup
      setGroup(chatGroup);
    }
  };
  
  const messagesStyle = new MessagesStyle({
    background: 'transparent',
    height: '430px',
    width: '325px',
  });

  const messageHeaderConfiguration = new MessageHeaderConfiguration({
    avatarStyle:avatarStyle,
    hideBackButton:false,
    onBack:() => {setCometUser(undefined); setGroup(undefined);} 
  });

  return (
    <>
      <Accordion
        defaultExpanded={false}
        sx={{
          position: 'fixed', bottom: 0, right: 0, zIndex: 1299,
          width: open ? 'auto' : '65px',
          boxShadow: 'none',
          background: !open ? 'transparent' : '#fff'
        }}
        onChange={() => {setOpen(!open); setGroup(undefined);setCometUser(undefined);}}
      >
        {props?.showLoader === true && !open? 
          <CircularProgress /> :
          <AccordionSummary
            sx={{
              backgroundColor: !open ? 'transparent' : '#fff',
              boxShadow: !open ? '' : '0px 2px 6px 0px rgba(0, 0, 0, 0.10);',
            }}
          >

            {open ?
              <Grid container>
                <Grid item xs={11} sx={{ display: 'inline-flex' }}>
                  <>
                    <CardMedia component={'img'} src={logo} alt='chat-logo'
                      sx={{ width: '36px', height: '36px', borderRadius: '50%', background: '#fff' }} />
                    <Typography variant='h3' sx={{ padding: '6px 0 0 8px' }}> MindWeal Support</Typography> </>
                </Grid>  </Grid>
              : ''}

            <Grid item xs={1} alignContent={'flex-end'} sx={{ paddingTop: '6px' }}>
              {open ? <CloseIcon /> : ''}
            </Grid>


            {!open ?
              <Fab aria-label="add"
                sx={{
                  color: '#fff',
                  background: '#FFC44D',
                  '&.MuiButtonBase-root.MuiFab-root:hover': {
                    backgroundColor: '#cf9c36!important'
                  }
                }}
              >
                <CardMedia component={'img'} src={chat} alt='card-img1'
                  sx={{ width: '32px', height: '32px' }}
                />
                <Badge badgeContent={unreadMessageCount}
                  sx={{
                    '& .MuiBadge-badge': {
                      color: '#fff',
                      backgroundColor: '#000!important',
                      marginLeft: '20px',
                      marginTop:'-25px'
                    }
                  }}
                />
              </Fab> : ''}
          </AccordionSummary>
        }

        <AccordionDetails
          sx={{ padding: '8px 0' }}
        >
          {props?.showLoader === true ? 
            <CircularProgress /> :
            <>
              {cometUser === undefined && group === undefined ?
                <CometChatConversations
                  onItemClick={handleConversationClick}
                  conversationsStyle={convStyle}
                  hideSeparator
                  options={() => []}
                  avatarStyle={avatarStyle}
                /> :
                <CometChatMessages
                  user={cometUser || undefined}
                  group={group || undefined}
                  messageComposerConfiguration={mcConfig}
                  messagesStyle={messagesStyle}
                  messageHeaderConfiguration={messageHeaderConfiguration}
                  messageListConfiguration={msgListConfig}
                  hideDetails={true}
                  
                />}
            </>
          }
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default Chatbox;
