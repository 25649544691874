import { API_URL } from 'shared/api/Constants';
import { apiInterceptor } from './axios';


export const getAllUsersAPI = () => {
  return apiInterceptor.get(`${API_URL.GET_ALL_USERS}`).then((response: any) => {
    return response;
  });
};

export const getUserAPI = (id: any) => {
  return apiInterceptor.get(`${API_URL.GET_ALL_USERS}/${id}`).then((response: any) => {
    return response;
  });
};
  
export const saveUserAPI = (data: any) => {
  return apiInterceptor.post(`${API_URL.SAVE_USER}`, data).then((response: any) => {
    return response;
  });
};

export const updateUserAPI = (data: any) => {
  return apiInterceptor.put(`${API_URL.GET_ALL_USERS}/${data.userId}`, data).then((response: any) => {
    return response;
  });
};


export const deleteUserAPI = (id: any) => {
  return apiInterceptor.delete(`${API_URL.GET_ALL_USERS}/${id}`).then((response: any) => {
    return response;
  });
};


