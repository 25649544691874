/* eslint-disable indent */
import React, { useEffect, useState, useContext } from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Switch,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AuthContext } from 'utils/AuthContext';
import { saveInsuranceDetails, getInsuranceDetails, getPatientDetails } from 'store/slices/userSlice';
import FileUploader from './FileUploader';
import { useAppDispatch } from 'store/hooks';
import { insuranceDetailsSchema } from 'utils/schema';
import PrimaryCard from './PrimaryCard';
import { uploadCardImageAPI, downloadCardImageAPI } from 'services/profileServices';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { permissions } from 'utils/constants';
import { Restricted } from 'components/Restricted';
import { validateZipcode } from 'store/slices/signInSlice';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const InsuranceDetails = (props: any) => {
  const [primaryInsurance, setPrimaryInsurance] = useState(false);
  const [secondaryInsurance, setSecondaryInsurance] = useState(false);
  const [primaryInsuranceData, setPrimaryInsuranceData] = useState<any>(null);
  const [secondaryInsuranceData, setSecondaryInsuranceData] = useState<any>(null);
  const [frontSideImage, setFrontSideImage] = useState<string | null>(null);
  const [backSideImage, setBackSideImage] = useState<string | null>(null);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [payOutOfPocket, setPayOutOfPocket] = useState<boolean>(false);
  const [payOutOfPocketCopy, setPayOutOfPocketCopy] = useState<boolean>(false);
  const [primaryFrontSideImage, setPrimaryFrontSideImage] = useState<any>(null);
  const [primaryBackSideImage, setPrimaryBackSideImage] = useState<any>(null);
  const [secondaryFrontSideImage, setSecondaryFrontSideImage] = useState<any>(null);
  const [secondaryBackSideImage, setSecondaryBackSideImage] = useState<any>(null);
  const [showExplanation, setShowExplanation] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(0);
  const [insuranceProviders, setInsuranceProviders] = useState<any>();
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [displayInsuranceMessage, setDisplayInsuranceMessage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { user } = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(insuranceDetailsSchema),
    mode: 'all',
  });


  useEffect(() => {
    getInsuranceData();

    dispatch(getPatientDetails(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          if(response?.data?.invalidInsurance){
            setDisplayInsuranceMessage(true);            
          }
          const zip = response?.data?.zipcode ? parseInt(response?.data?.zipcode) : parseInt('00000');
            dispatch(validateZipcode(zip))
              .unwrap()
              .then((response: any) => {
                setInsuranceProviders(response?.data?.insurance);
              });
        } 
      });
     
      if(props?.from === 'admin'){
        setShowForm(true);
      }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user?.patientId]);

  useEffect(() => {
    if (showForm) {
      reset({
        groupNo: '',
        insuranceCarrier: user?.insurance,
        patientRelation: '',
        planId: '',
        subscriberDob: '',
        subscriberName: '',
      });
    }
  }, [reset, showForm, user?.insurance]);

  const getInsuranceData = () => {
    if (user?.patientId !== undefined || props?.patientId !== undefined) {
      dispatch(getInsuranceDetails(props?.from === 'admin' ? props?.patientId : user?.patientId))
        .unwrap()
        .then(async(response: any) => {
          if (response.status === 200) {
            setPayOutOfPocket(response?.data?.payOutOfPocket);
            setPayOutOfPocketCopy(response?.data?.payOutOfPocket);
            if (response?.data?.primaryInsurance !== null) {
              Object.keys(response?.data?.primaryInsurance).forEach((key: any) => {
                setValue(key, response.data.primaryInsurance[key], { shouldValidate: true, shouldDirty: false });
              });

              setPrimaryInsurance(true);
              setPrimaryInsuranceData(response?.data?.primaryInsurance);
              if(props?.from !== 'admin'){
              setShowForm(false);
              }else {
                setShowForm(true);
              }

              const frontSideData = {
                params: {
                  patientId: props?.from !== 'admin' ? user?.patientId : props?.patientId,
                  imageSide: 'Front',
                  insuranceType: 'Primary',
                },
              };
              await downloadCardImageAPI(frontSideData.params).then((response: any) => {
                if (response?.status === 200 && response?.data !== '') {
                  setPrimaryFrontSideImage(response?.data);
                  setFrontSideImage(response?.data);
                }
              });

              const backSideData = {
                params: {
                  patientId: props?.from !== 'admin' ? user?.patientId : props?.patientId,
                  imageSide: 'Back',
                  insuranceType: 'Primary',
                },
              };
             await downloadCardImageAPI(backSideData.params).then((response: any) => {
                if (response?.status === 200 && response?.data) {
                  setPrimaryBackSideImage(response?.data);
                  setBackSideImage(response?.data);
                }
              });
            }
            if (response?.data?.secondaryInsurance !== null) {
              Object.keys(response?.data.secondaryInsurance).forEach((key: any) => {
                setValue(key, response.data.secondaryInsurance[key], { shouldValidate: true, shouldDirty: false });
              });
              setSecondaryInsurance(true);
              setSecondaryInsuranceData(response?.data?.secondaryInsurance);
              if(props?.from !=='admin'){
              setShowForm(false);
              } else {
                setShowForm(true);
              }

              const frontSideData = {
                params: {
                  patientId: props?.from !== 'admin' ? user?.patientId : props?.patientId,
                  imageSide: 'Front',
                  insuranceType: 'Secondary',
                },
              };
              downloadCardImageAPI(frontSideData.params).then((response: any) => {
                if (response?.status === 200 && response?.data) {
                  setSecondaryFrontSideImage(response?.data);
                }
              });

              const backSideData = {
                params: {
                  patientId: props?.from !== 'admin' ? user?.patientId : props?.patientId,
                  imageSide: 'Back',
                  insuranceType: 'Secondary',
                },
              };
              downloadCardImageAPI(backSideData.params).then((response: any) => {
                if (response?.status === 200 && response?.data) {
                  setSecondaryBackSideImage(response?.data);
                }
              });
            }
          }
        });
    }
  };

  const onSubmitForm = async (formData: any) => {
    if(isValid && isDirty){
    if (user?.patientId !== undefined) {
      setLoading(true);
      delete formData.id;
      const data = secondaryInsurance
        ? {
          secondaryInsurance: {
            ...formData,
          },
          id: user?.patientId,
        }
        : {
          primaryInsurance: {
            ...formData,
          },
          id: user?.patientId,
        };

      await dispatch(saveInsuranceDetails(data))
        .unwrap()
        .then(async(response: any) => {
          if (response?.status === 200) {           
            const formData: any = new FormData();
            if (frontSideImage !== null) {
              formData.append('file', frontSideImage);
              const frontSideData = {
                params: {
                  patientId: user?.patientId ? user?.patientId : null,
                  imageSide: 'Front',
                  insuranceType: !secondaryInsurance ? 'Primary' : 'Secondary',
                },
                formData: formData,
              };
             await uploadCardImageAPI(frontSideData.params, frontSideData.formData)
              .then(async(res:any)=>{
                if(res.status === 200){
                  if (backSideImage !== null) {
                    const backsideFormData: any = new FormData();
                    backsideFormData.append('file', backSideImage);
                    const backSideData = {
                      params: {
                        patientId: user?.patientId ? user?.patientId : null,
                        imageSide: 'Back',
                        insuranceType: secondaryInsurance ? 'Secondary' : 'Primary',
                      },
                      formData: backsideFormData,
                    };
                    await uploadCardImageAPI(backSideData.params, backSideData.formData);
                  }
                }                
              })
              .catch(() =>{
                setLoading(false);
              });
            }
           
            toast('Payment details have been saved successfully', {
              type: 'success',
              icon: true,
            });            
            setShowForm(false);
            reset(formData);
            props.onComplete();
           
          }
        });
        getInsuranceData();        
    }
    setPrimaryInsurance(true);
    setSecondaryInsurance(false);
    setLoading(false);
  }else{
    toast('No changes to update.',{
      type:'info',
      icon:true
    });
  }
  };

  const handleFileUpload = (acceptedFiles: any, side: string) => {
    if (acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (side === 'front') {
          setFrontSideImage(file);
        } else {
          setBackSideImage(file);
        }
      };
      setValue('cardImage', 'file', { shouldValidate: true, shouldDirty: true });
      register('cardImage');
      reader.readAsDataURL(file);
    }
  };

  const editPrimaryInsurance = () => {
    setShowForm(true);
    const patientId = props?.from === 'admin' ? props?.patientId  : user?.patientId;
    dispatch(getInsuranceDetails(patientId))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          if (response?.data?.primaryInsurance !== null) {
            Object.keys(response?.data.primaryInsurance).forEach((key: any) => {
              setValue(key, response.data.primaryInsurance[key], { shouldValidate: false, shouldDirty: false });
            });
            setPrimaryInsurance(false);
            setSecondaryInsurance(false);
          }
        }
      });
  };

  const editSecondaryInsurance = () => {
    setShowForm(true);
    const patientId = props?.from === 'admin' ? props?.patientId  : user?.patientId;
    dispatch(getInsuranceDetails(patientId))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          if (response?.data?.secondaryInsurance !== null) {
            Object.keys(response?.data.secondaryInsurance).forEach((key: any) => {
              setValue(key, response.data.secondaryInsurance[key], { shouldValidate: true, shouldDirty: false });
            });
            setPrimaryInsurance(false);
          }
        }
      });
  };

  const handlePayOutOfPocketChange = (e: any) => {
    if(!e.target.checked && !payOutOfPocketCopy){
      setShowExplanation(false);
      setPayOutOfPocket(false);
      return;
    }
    setPayOutOfPocket(!payOutOfPocket);
    setShowExplanation(true);     
    setDisableSave(false); 
    if(payOutOfPocket){
      setShowForm(false);
    }    
  };

  const handlePayOutOfPocket=()=>{
    setDisableSave(true);
    if(!payOutOfPocket){
      setShowForm(true);
      setShowExplanation(false);
    }

    const data = {
      id: user?.patientId,
      payOutOfPocket: payOutOfPocket,
    };
    dispatch(saveInsuranceDetails(data))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          toast('Payment details have been saved successfully', {
            type: 'success',
            icon: true,
          });
          setTimeout(() => getInsuranceData(), 1000);
          setActiveStep(0);
          setDisableSave(true);
        }else{
          toast('Payment details failed to save', {
            type: 'error',
            icon: true,
          });
          setDisableSave(false);
        }
      });
  };

  const handleDateChange = (val: any) => {
    setValue('subscriberDob', new Date(val).toLocaleDateString());
    register('subscriberDob');
  };
  const isSm = useMediaQuery('(max-width:786px)');
  return (
    <Container component='main' maxWidth='sm'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  name='payout'
                  onChange={(e:any) => handlePayOutOfPocketChange(e)}
                  checked={payOutOfPocket}
                  disabled={!user?.permissions.includes('EDIT_PROFILE')}
                />
              }
              label='Pay out of pocket'
            />
          </Grid>
          {showExplanation || payOutOfPocket ?
            <>
              <Alert severity='info' sx={{ textAlign: 'justify' }}>
                Explore our transparent out-of-pocket pricing options
                <Link href='https://www.mindweal.com/pricing/' target='_blank' variant='body1'> here.</Link>
                &nbsp;We offer secure payment methods, including credit/debit cards.
                Upon scheduling your child&apos;s appointment, our team will guide you through payment details
                and our pricing structure.
              </Alert>
              <Grid item xs={12} sx={{ m: '12px 0'}} display={'flex'} 
              justifyContent={'flex-end'}>
                <Button size='small' onClick={handlePayOutOfPocket} disabled={disableSave}> Save </Button>
              </Grid>
            </>
            : null}
          
        </Grid>
        {activeStep === 0 && showForm && !payOutOfPocket ? (
          <Box component='form' noValidate sx={{ mt: 1 }} onSubmit={handleSubmit((formData) => onSubmitForm(formData))}>
            <Grid container spacing={2}>         
              <Grid item xs={12}>
                {primaryInsuranceData !== null && secondaryInsuranceData === null && !payOutOfPocket ? (
                  
                  <Grid item xs={12} sm={6} mb='24px'>      
                    <PrimaryCard
                      editPrimaryInsurance={editPrimaryInsurance}
                      insurancetype='Primary'
                      data={primaryInsuranceData}
                      fromadmin={props?.from === 'admin' ? true : false}
                    />
                     
                  </Grid>
                 ) : secondaryInsuranceData !== null && !payOutOfPocket && !showExplanation ?
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} mb='24px'>
                      <PrimaryCard
                        editPrimaryInsurance={editPrimaryInsurance}
                        insurancetype='Primary'
                        data={primaryInsuranceData}
                        fromadmin={props?.from === 'admin' ? true : false}
                      />
                      </Grid>
                      <Grid item xs={12} sm={6} mb='24px'>
                      <PrimaryCard
                        editPrimaryInsurance={editSecondaryInsurance}
                        insurancetype='Secondary'
                        data={secondaryInsuranceData}
                        fromadmin={props?.from === 'admin' ? true : false}
                      />
                    </Grid>                    
                  </Grid>                  
                  :
                  null}
                {props?.from === 'admin' ?
                  <Grid xs={12} sx={{ display: 'flex' }}>
                    {secondaryInsuranceData !== null && primaryInsuranceData !== null ?
                      <>
                        <Grid xs={6}>
                          <Button variant='text' sx={{ paddingTop: '0px' }} onClick={editPrimaryInsurance}>
                            View Details</Button>
                        </Grid>

                        <Grid xs={6}>
                          <Button variant='text' sx={{ paddingTop: '0px' }} onClick={editSecondaryInsurance}>
                            View Details</Button>
                        </Grid>
                      </>
                      : null}
                  </Grid>
                  : null
                } 

                {secondaryInsurance && props?.from !== 'admin' ?
                  <Grid sx={{mb:'24px'}}>
                    <Typography variant='h2' color='#475467'> Add Secondary Insurance</Typography>
                  </Grid> : ''
                }
                <InputLabel htmlFor='insuranceCarrier'>
                  {user?.permissions.includes('EDIT_PROFILE') ? 'Your Insurance Carrier*' : 'Your Insurance Carrier'}
                </InputLabel>

                {user?.permissions.includes('EDIT_PROFILE') ?                
                <TextField
                  select
                  fullWidth
                  id='insuranceCarrier'
                  autoComplete='insuranceCarrier'
                  // eslint-disable-next-line max-len
                  value={watch('insuranceCarrier') ? watch('insuranceCarrier') : user?.insurance}
                  {...register('insuranceCarrier')}
                  error={!!errors.insuranceCarrier}
                  helperText={errors?.insuranceCarrier?.message}                  
                >
                  {insuranceProviders?.map((provider:any, index:number) => (
                    <MenuItem key={index + 1} value={provider}>
                      {provider}
                    </MenuItem>
                  ))}                 
                </TextField>  :

                  <TextField
                  fullWidth
                  id='insuranceCarrier'
                  value={watch('insuranceCarrier') }
                  disabled
                />
                }
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor='planId'>{props?.from !== 'admin' ? 'Member ID*' : 'Member ID'}</InputLabel>
                <TextField
                  required
                  fullWidth
                  placeholder='Enter Your Member Id'
                  id='planId'
                  type='text'
                  autoComplete='planId'
                  value={watch('planId') ? watch('planId') : ''}
                  {...register('planId')}
                  error={!!errors.planId}
                  helperText={user?.permissions.includes('EDIT_PROFILE') ? errors?.planId?.message : ''}
                  inputProps={{ maxLength: 255 }}
                  disabled={!user?.permissions.includes('EDIT_PROFILE')}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor='groupNo'>
                {user?.permissions.includes('EDIT_PROFILE') ? 'Group No*' : 'Group No'}</InputLabel>
                <TextField
                  required
                  fullWidth
                  placeholder='Enter Insurance Group No.'
                  id='groupNo'
                  type='text'
                  autoComplete='groupNo'
                  value={watch('groupNo') ? watch('groupNo') : ''}
                  {...register('groupNo')}
                  error={!!errors.groupNo}
                  helperText={user?.permissions.includes('EDIT_PROFILE') ? errors?.groupNo?.message : ''}
                  disabled={!user?.permissions.includes('EDIT_PROFILE')}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel htmlFor='subscriberName'>
                  {user?.permissions.includes('EDIT_PROFILE') ? 'Name of Subscriber*' : 'Name of Subscriber'}
                </InputLabel>
                <TextField
                  required
                  fullWidth
                  placeholder='Enter name of subscriber'
                  id='subscriberName'
                  type='text'
                  autoComplete='subscriberName'
                  value={watch('subscriberName') ? watch('subscriberName') : ''}
                  {...register('subscriberName')}
                  error={!!errors.subscriberName}
                  helperText={errors?.subscriberName?.message}
                  disabled={!user?.permissions.includes('EDIT_PROFILE')}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel htmlFor='subscriberbirtday'>
                  {user?.permissions.includes('EDIT_PROFILE') ? 'Birthdate of Subscriber*' : 'Birthdate of Subscriber'}
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '100%' }}
                    disableFuture
                    value={watch('subscriberDob') ? dayjs(watch('subscriberDob')) : null}
                    onChange={(e) => handleDateChange(e)}
                    disabled={!user?.permissions.includes('EDIT_PROFILE')}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor='patientRelation '>
                  {user?.permissions.includes('EDIT_PROFILE')
                    ? 'Subscriber’s relationship to patient*'
                    : 'Subscriber’s relationship to patient'}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder='Select relationship to patient'
                  id='patientRelation '
                  type='text'
                  value={watch('patientRelation') ? watch('patientRelation') : ''}
                  {...register('patientRelation')}
                  error={!!errors.patientRelation}
                  helperText={errors?.patientRelation?.message}
                  disabled={!user?.permissions.includes('EDIT_PROFILE')}
                >
                </TextField>
              </Grid>
             
              {user?.permissions.includes('EDIT_PROFILE') && (
                <>
                  <Grid item xs={12}>
                    <Typography variant='h2'>Upload Insurance Card Image</Typography>
                  </Grid> 
                  <Grid item xs={12} sx={{ p: '1rem' }}>
                    <InputLabel htmlFor='frontSide'>Front Side</InputLabel>
                    {primaryFrontSideImage !== null && !secondaryInsurance ? (
                      <img
                        src={`data:image/png;base64, ${primaryFrontSideImage}`}
                        alt='front side'
                        style={{
                          maxWidth: isSm ? '245px' : '300px',
                          maxHeight: isSm? '245px' : '385px',
                        }}
                      />
                    ) : null}
                    {secondaryFrontSideImage !== null && secondaryInsurance ? (
                      <img
                        src={`data:image/png;base64, ${secondaryFrontSideImage}`}
                        alt='front side'
                        style={{
                          maxWidth: isSm ? '245px' : '300px',
                          maxHeight: isSm? '245px' : '385px',
                        }}
                      />
                    ) : null}
                    <FileUploader onFileUpload={handleFileUpload} side='front' />
                  </Grid>
                  <Grid item xs={12} sx={{ p: '1rem' }}>
                    <InputLabel htmlFor='frontSide'>Back Side</InputLabel>
                    {primaryBackSideImage !== null && !secondaryInsurance ? (
                      <img
                        src={`data:image/png;base64, ${primaryBackSideImage}`}
                        alt='back side'
                        style={{
                          maxWidth: isSm ? '245px' : '300px',
                          maxHeight: isSm? '245px' : '385px',
                        }}
                      />
                    ) : null}
                    {secondaryBackSideImage !== null && secondaryInsurance ? (
                      <img
                        src={`data:image/png;base64, ${secondaryBackSideImage}`}
                        alt='front side'
                        style={{
                          maxWidth: isSm ? '245px' : '300px',
                          maxHeight: isSm? '245px' : '385px',
                        }}
                      />
                    ) : null}

                    <FileUploader onFileUpload={handleFileUpload} side='back' />
                  </Grid>
                </>
              )}
              <Restricted permission={permissions.EDIT_PROFILE}>
                <Grid container justifyContent={'space-between'}>   
                  <Grid item xs={12} sm={8} display={'flex'} sx={{justifyContent:{ xs:'center', sm:'left'}}}>
                  {primaryInsuranceData !== null ?
                    <Button variant='text' sx={{ mt: 2, mb: 2 }}
                      onClick={() => {setSecondaryInsurance(false); setShowForm(false);}}
                    >
                      Back to Insurance Details
                    </Button> : null}
                  </Grid>

                <Grid item xs={12} sm={4} display={'flex'} sx={{justifyContent:{ xs:'center', sm:'left'}}}>
                  <LoadingButton loading={loading} type='submit' variant='contained' sx={{ mt: 2, mb: 2 }}>
                    Save
                  </LoadingButton>
                </Grid>
                </Grid>
              </Restricted>
            </Grid>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: { sm: '620px' },
            }}
          >
            <Grid container spacing={2}>
              {primaryInsuranceData !== null && !payOutOfPocket && !showExplanation && 
              user?.permissions.includes('EDIT_PROFILE') ? (
                <Grid item xs={12} sm={6}>
                  <PrimaryCard
                    editPrimaryInsurance={editPrimaryInsurance}
                    insurancetype='Primary'
                    data={primaryInsuranceData}
                    fromadmin={props?.from === 'admin' ? true : false}
                  />
                </Grid>
              ) : null}
              {secondaryInsuranceData !== null && !payOutOfPocket && !showExplanation && 
              user?.permissions.includes('EDIT_PROFILE') ? (
                <Grid item xs={12} sm={6} >
                  <PrimaryCard
                    editPrimaryInsurance={editSecondaryInsurance}
                    insurancetype='Secondary'
                    data={secondaryInsuranceData}
                    fromadmin={props?.from === 'admin' ? true : false}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} sm={6}></Grid>
              {primaryInsuranceData !== null && secondaryInsuranceData === null && !payOutOfPocket && (
                <Restricted permission={permissions.EDIT_PROFILE}>
                {user?.permissions.includes('EDIT_PROFILE') ?  
                  <Grid item xs={12} sm={7}>
                    <Button
                      variant='text'
                      onClick={() => {
                        setShowForm(true);
                        setPrimaryInsurance(!primaryInsurance);
                        setSecondaryInsurance(true);
                      }}
                    >
                      Add Secondary Insurance
                    </Button>
                  </Grid>: null }
                </Restricted> 
              )}
            </Grid>
          </Box>
         
        )}
        {displayInsuranceMessage && !payOutOfPocket && !showExplanation && activeStep === 0 && 
             <Grid item xs={12}>
               <Alert severity='error' sx={{fontSize:'14px'}}>
              This insurance is not accepted in the current state, 
              please update your insurance or opt to pay out of pocket to continue MindWeal services
               </Alert>
              
             </Grid>
            }
      </Box>
    </Container>
  );
};

export default InsuranceDetails;
