import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTransactionHistoryAPI,
  chargePaymentAPI, paymentActionAPI } from 'services/paymentServices';
import { handleMessage } from 'shared/api/ErrorMessages';

interface Error {
    data:any
}


export const getTransactionHistory= createAsyncThunk(
  'GET_PAYMENT_HISTORY', 
  async (patientId:any, {rejectWithValue} :any) => {
    try{
      const response = await getTransactionHistoryAPI(patientId);
      return response;
    } catch (err:any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err:err.data,
        message,
        show:false,
      };
      return rejectWithValue(error);
    }
  }
);

export const chargePayment= createAsyncThunk(
  'CHARGE_PAYMENT', 
  async (data:any, {rejectWithValue} :any) => {
    try{
      const response = await chargePaymentAPI(data);
      return response;
    } catch (err:any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err:err.data,
        message,
        show:false,
      };
      return rejectWithValue(error);
    }
  }
);

export const paymentAction= createAsyncThunk(
  'PAYMENT_ACTION', 
  async (data:any, {rejectWithValue} :any) => {
    try{
      const response = await paymentActionAPI(data);
      return response;
    } catch (err:any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err:err.data,
        message,
        show:false,
      };
      return rejectWithValue(error);
    }
  }
);
  


