import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactPhoneInput from 'react-phone-input-material-ui';

import { providerDetailsSchema } from 'utils/schema';
import { permissions } from 'utils/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getProviderDetails, updateProviderDetails } from 'store/slices/userSlice';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { toast } from 'react-toastify';
import { useAuth } from 'utils/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from 'route/RouteEnums';
import { Restricted } from 'components/Restricted';

const ProviderInfo = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
    reset,
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(providerDetailsSchema),
    mode: 'all',
  });
  const isProfileDetailsFetched = useAppSelector((state) => state.user.loaders.isProfileDetailsFetched);
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getProvider = () => {
    if (user?.patientId || props?.patientId) {
      const data = { userId: props?.from === 'admin' ? props?.patientId : user?.patientId };
      dispatch(getProviderDetails(data))
        .unwrap()
        .then((response: any) => {
          if (response.status === 200) {
            Object.keys(response.data).forEach((key: any) => {
              if ((key === 'officeFaxNumber' && response.data[key]?.length === 0) || response.data[key] === null)
                setValue(key, '1', { shouldValidate: true, shouldDirty: false });
              else if (
                (key === 'officeFaxNumber' && response.data[key].length === 10) ||
                (key === 'officePhoneNumber' && response.data[key].length === 10)
              )
                setValue(key, `1${response.data[key]}`, { shouldValidate: false, shouldDirty: false });
              else if (response.data[key] !== null)
                setValue(key, response.data[key], { shouldValidate: true, shouldDirty: false });
              else {
                setValue(key, '', { shouldValidate: true, shouldDirty: false });
              }
            });
          } else setValue('officeFaxNumber', '1', { shouldValidate: true, shouldDirty: false });
        });
    } else {
      const message = 'Please add patient details first.';
      toast(message, {
        type: 'info',
        icon: true,
      });
      navigate(ROUTE.PATIENT_INFORMATION);
    }
  };

  useEffect(() => {
    getProvider();
    //eslint-disable-next-line
  }, []);

  const getPayload = (formData: any) => {
    const { officeName, firstName, lastName, officePhoneNumber, officeFaxNumber } = formData;
    return {
      officeName,
      firstName,
      lastName,
      officePhoneNumber: officePhoneNumber.slice(1),
      officeFaxNumber: officeFaxNumber !== '1' ? officeFaxNumber : '',
    };
  };

  const onSubmitForm = (formData: any) => {
    if(isValid && isDirty){
      if (user?.patientId) {
        const payload = getPayload(formData);
        dispatch(updateProviderDetails({ userId: user?.patientId, payload }))
          .unwrap()
          .then((response: any) => {
            if (response.status === 200) {
              const message = 'Provider details updated successfully.';
              toast(message, {
                type: 'success',
                icon: true,
              });
              reset(formData);
              props?.onComplete();
            }
          });
      }
    }else{
      toast('No changes to update.',{
        type:'info',
        icon:true
      });
    }
  };

  return (
    <Container component='main' maxWidth='sm'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component='form' noValidate sx={{ mt: 1 }} onSubmit={handleSubmit((formData) => onSubmitForm(formData))}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel htmlFor='office-name'>{props?.from !== 'admin' ? 'Office Name*' : 'Office Name'}</InputLabel>
              <TextField
                required
                fullWidth
                placeholder="Enter provider's office name"
                id='office-name'
                autoComplete='officeName'
                {...register('officeName')}
                error={!!errors.officeName}
                helperText={errors?.officeName?.message}
                disabled={!user?.permissions.includes('EDIT_PROFILE')}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='last-name'>{props?.from !== 'admin' ? 'Last Name*' : 'Last Name'}</InputLabel>
              <TextField
                required
                fullWidth
                placeholder="Enter provider's last name"
                id='lastName'
                autoComplete='lastName'
                {...register('lastName')}
                error={!!errors.lastName}
                helperText={errors?.lastName?.message}
                disabled={!user?.permissions.includes('EDIT_PROFILE')}
                inputProps={{
                  maxLength: 255,
                }}
              /> 
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='pharmacyName'>{props?.from !== 'admin' ? 'First Name*' : 'First Name'}</InputLabel>
              <TextField
                required
                fullWidth
                placeholder="Enter provider's first name"
                id='first-name'
                autoComplete='firstName'
                {...register('firstName')}
                error={!!errors.firstName}
                helperText={errors?.firstName?.message?.toString()}
                disabled={!user?.permissions.includes('EDIT_PROFILE')}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='phone'>
                {props?.from !== 'admin' ? 'Office\'s Phone Number*' : 'Office\'s Phone Number'}
              </InputLabel>
              <ReactPhoneInput
                component={TextField}
                label=''
                placeholder='+1 (555) 000-0000'
                onlyCountries={['us']}
                country={'us'}
                masks={{ us: '(...) ...-....' }}
                autoFormat={true}
                disableDropdown={true}
                defaultErrorMessage={'Please enter a phone number'}
                {...register('officePhoneNumber')}
                inputProps={{
                  helperText: errors.officePhoneNumber ? errors.officePhoneNumber?.message : '',
                  error: !!errors.officePhoneNumber,
                }}
                countryCodeEditable={false}
                onChange={(e) => {
                  setValue('officePhoneNumber', e, { shouldValidate: true, shouldDirty: true });
                }}
                value={watch('officePhoneNumber') ? watch('officePhoneNumber') : null}
                disabled={!user?.permissions.includes('EDIT_PROFILE')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='faxNumber'>Office&apos;s Fax Number</InputLabel>
              <ReactPhoneInput
                component={TextField}
                label=''
                placeholder='+1 (555) 000-0000'
                onlyCountries={['us']}
                country={'us'}
                masks={{ us: '(...) ...-....' }}
                autoFormat={true}
                disableDropdown={true}
                defaultErrorMessage={'Please enter a fax number'}
                {...register('officeFaxNumber')}
                inputProps={{
                  helperText: errors?.officeFaxNumber ? errors.officeFaxNumber?.message : '',
                  error: !!errors.officeFaxNumber,
                }}
                countryCodeEditable={false}
                onChange={(e) => {
                  setValue('officeFaxNumber', e, { shouldValidate: true, shouldDirty: true });
                }}
                value={watch('officeFaxNumber') ? watch('officeFaxNumber') : ''}
                onBlur={() => {
                  if (watch('officeFaxNumber') === '1') clearErrors('officeFaxNumber');
                }}
                disabled={!user?.permissions.includes('EDIT_PROFILE')}
              />
            </Grid>
            <Restricted permission={permissions.EDIT_PROFILE}>
              <Grid item xs={12} display={'flex'} justifyContent={'end'}>
                <LoadingButton
                  loading={isProfileDetailsFetched}
                  variant='contained'
                  // loadingPosition='start'
                  type='submit'
                  sx={{ mt: 2, mb: 2 }}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Restricted>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default ProviderInfo;
