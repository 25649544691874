import {useContext, useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, CardProps, Container, Dialog, 
  DialogActions, DialogContent, DialogTitle, Grid, InputLabel, MenuItem, 
  styled, TextField, Typography
} from '@mui/material';
import { useNavigate } from 'react-router';
import {useLocation} from 'react-router-dom';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { ROUTE } from 'route/RouteEnums';
import { useAppDispatch } from 'store/hooks';
import { Page } from 'components/Page';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { deleteUser, getUser, saveUser, updateUser } from 'store/slices/userManagementSlice';
import { userManagementSchema } from 'utils/schema';
import { toast } from 'react-toastify';
import { AuthContext } from 'utils/AuthContext';

const StyleCard = styled(Card)<CardProps>(({ theme }) => ({
  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
  border: '1px solid #EFF4FF',
  borderRadius: 12,
  background: '#FFF',
  margin: '12px 16px',
  position: 'relative',
  zIndex: 99,
  padding: 20,
  '& .MuiTypography-root': {
    position: 'relative',
  },
  '& .MuiCardContent-root:last-child': {
    padding: 0,
  },
  '.MuiTypography-h2': {
    fontSize: 20,
  },
  '& .Mui-selected':{
    background:'#9F84FF',
    color:'#FFF!important',
    borderRadius:'23px',
    width:'100%'
  },
  '& .MuiTab-root':{
    margin:'0 60px!important'
  }
}));
  
function CreateUser() {
  const {
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(userManagementSchema),
    mode: 'all',
  });

  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<string>();
  const { state } = useLocation();
  const {user} = useContext(AuthContext);
  
  const locations = [
    {
      id:1,
      location:'24B Professional Park Dr, Maryville, IL - 62062',
    },
    {
      id:2,
      location:'1315 Macom Dr, Suite 207, Naperville, IL - 60564',
    },
    {
      id:3,
      location:'19 E First Street, Hinsdale, IL - 60521',
    },
  ];

  const roles = [
    {
      id:1,
      role:'VMA',
    },
    {
      id:2,
      role:'Admin',
    },
    {
      id:3,
      role:'VMA Manager',
    },
    {
      id:4,
      role:'Billing Manager',
    },
    {
      id:5,
      role:'Clinical Manager',
    },
    {
      id:6,
      role:'Clinical VMA',
    },

    {
      id:7,
      role:'Billing VMA',
    },
    {
      id:8,
      role:'Global Manager',
    },
    {
      id:9,
      role:'Provider',
    },
    {
      id:10,
      role:'Tech VMA',
    },
    {
      id:11,
      role:'Other VMA',
    },
  ];
 
  const positions = [
    {
      id:1,
      position:'Medical Assistant ',
    },
    {
      id:2,
      position:'Medical Assistant Supervisor',
    },
    {
      id:3,
      position:'Clinical Provider',
    },
    {
      id:4,
      position:'Clinical Provider Supervisor',
    },
    {
      id:5,
      position:'Manager',
    },
    {
      id:7,
      position:'Director',
    },
    {
      id:8,
      position:'Medical Director',
    },
    {
      id:9,
      position:'Chief Operating Officer (COO)',
    },
    {
      id:10,
      position:'Chief Financial Officer (CFO)',
    },
    {
      id:11,
      position:'Chief Executive Officer (CEO)',
    },
  ];

  useEffect(()=>{
    if(!user?.permissions.includes('USER_MANAGEMENT')){
      navigate(ROUTE.ADMIN_DASHBOARD);
    }

    if(state?.userId){
      dispatch(getUser(state?.userId))
        .unwrap()
        .then((res:any)=>{
          if(res?.status === 200){
            const data= res?.data;
            setValue('firstName', data?.firstName);
            setValue('lastName', data?.lastName);
            setValue('email', data?.email);          
            setValue('mobileNumber', data?.mobileNumber);
            setValue('position', data?.position);
            setValue('officeLocation', data?.officeLocation);
            setValue('roleType', data?.roleType);
            setValue('providerId', data?.providerId);
            setStatus(data?.status);
          }
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state?.userId]);

  const onSubmitForm=(formData:any)=>{
    
    if(state?.userId){
      const data={
        userId:state?.userId,
        ...formData
      };
      dispatch(updateUser(data))
        .unwrap()
        .then((res:any) => {
          if(res?.status === 200){
            toast('User details updated successfully',{
              type:'success',
              icon:true
            });
            navigate(ROUTE.ADMIN_USER_MANAGEMENT);  
          }
        });
    }else{
      dispatch(saveUser(formData))
        .unwrap()
        .then((res:any) => {
          if(res?.status === 200){
            toast('New user account created successfully',{
              type:'success',
              icon:true
            });
            navigate(ROUTE.ADMIN_USER_MANAGEMENT);
          }
        });
    }
  };


  const handleDeactivate=()=>{
    dispatch(deleteUser(state?.userId))
      .unwrap()
      .then((res:any) => {
        if(res?.status === 200){
          toast('User account deactivated successfully',{
            type:'success',
            icon:true
          });
          navigate(ROUTE.ADMIN_USER_MANAGEMENT); 
        }
      });
  };

  return (
    <Page backDir={ROUTE.ADMIN_USER_MANAGEMENT} buttonText='Back'>
      <Container component='main' maxWidth='md'>
        <Box component='form' onSubmit={handleSubmit((formData) => onSubmitForm(formData))}>
          <Grid container spacing={2}>
            <Grid item xs={10} display={'flex'} alignItems={'center'} mt={3} mb={3}>
              <Typography variant='h5' gutterBottom>
                {state?.userId ? 'Update User Details' : 'Create New User'} 
              </Typography>
            </Grid>
          </Grid>
          <StyleCard>
            <CardContent>
              <Grid container spacing={2} >
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor='firstName'>First Name*</InputLabel>
                  <TextField 
                    id='firstName' 
                    variant='outlined'
                    fullWidth 
                    {...register('firstName')}
                    error={!!errors.firstName}
                    helperText={errors?.firstName?.message}                  
                    inputProps={{
                      maxLength: 255,
                    }}
                    value={watch('firstName') ? watch('firstName') :''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor='lastName'>Last Name*</InputLabel>
                  <TextField 
                    id='lastName' 
                    variant='outlined'
                    fullWidth 
                    {...register('lastName')}
                    error={!!errors.lastName}
                    helperText={errors?.lastName?.message}                  
                    inputProps={{
                      maxLength: 255,
                    }}
                    value={watch('lastName') ? watch('lastName') :''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor='email'>Email*</InputLabel>
                  <TextField 
                    id='email' 
                    variant='outlined'
                    fullWidth 
                    {...register('email')}
                    error={!!errors.email}
                    helperText={errors?.email?.message}                  
                    inputProps={{
                      maxLength: 255,
                    }}
                    value={watch('email') ? watch('email') :''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor='mobileNumber'>Phone Number</InputLabel>
                  <ReactPhoneInput
                    component={TextField}
                    label=''
                    onlyCountries={['us']}
                    country={'us'}
                    masks={{ us: '(...) ...-....' }}
                    autoFormat={true}
                    disableDropdown={true}
                    defaultErrorMessage={'Please enter a Phone Number'}
                    placeholder='+1 (555) 000-0000'
                    {...register('mobileNumber')}
                    inputProps={{
                      helperText: errors.mobileNumber ? errors.mobileNumber?.message : '',
                      error: !!errors.mobileNumber,
                    }}
                    countryCodeEditable={false}
                    onChange={(e) => {
                      setValue('mobileNumber', e, { shouldValidate: true });
                    }}
                    value={watch('mobileNumber') ? watch('mobileNumber') : null}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor='position'>Position</InputLabel>
                  <TextField 
                    id='position' 
                    variant='outlined'
                    fullWidth 
                    select
                    {...register('position')}
                    error={!!errors.position}
                    helperText={errors?.position?.message}                  
                    value={watch('position') ? watch('position') :''}
                  >
                    <MenuItem key={0} value='position' disabled>
                  Choose Position
                    </MenuItem>
                    {positions.map((l) => (
                      <MenuItem key={l.id} value={l.position}>
                        {l.position}
                      </MenuItem>
                    ))}
                  
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor='roleType'>User Role</InputLabel>
                  <TextField 
                    id='roleType' 
                    variant='outlined'
                    fullWidth 
                    select
                    {...register('roleType')}
                    error={!!errors.roleType}
                    helperText={errors?.roleType?.message}                 
                    value={watch('roleType') ? watch('roleType') :''}
                  >
                    
                    <MenuItem key={0} value='roleType' disabled>
                  Choose Role
                    </MenuItem>
                    {roles.map((l) => (
                      <MenuItem key={l.id} value={l.role}>
                        {l.role}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor='officeLocation'>Office*</InputLabel>
                  <TextField 
                    id='officeLocation' 
                    variant='outlined'
                    fullWidth 
                    select
                    {...register('officeLocation')}
                    error={!!errors.officeLocation}
                    helperText={errors?.officeLocation?.message}
                    value={watch('officeLocation') ? watch('officeLocation') :''}
                  >
                    <MenuItem key={0} value='preferredLocation' disabled>
                  Choose Location
                    </MenuItem>
                    {locations.map((l) => (
                      <MenuItem key={l.id} value={l.location}>
                        {l.location}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor='providerId'>Zoom Personal Room Link</InputLabel>
                  <TextField 
                    id='providerId' 
                    variant='outlined'
                    fullWidth 
                    {...register('providerId')}
                    error={!!errors.providerId}
                    helperText={errors?.providerId?.message}                  
                    inputProps={{
                      maxLength: 255,
                    }}
                    value={watch('providerId') ? watch('providerId') :''}
                  />
                </Grid>              
              </Grid>
              <Grid container display= 'flex' justifyContent='space-between' mt='20px'>
                <Grid item display='flex'>                  
                  {status?.toLowerCase() === 'deactivated' ?
                    <Typography color='#d32f2f'> This user has been deactivated.</Typography> : null}
                </Grid>
                <Grid item display= 'flex' justifySelf= 'flex-end'>
                  <Button variant='text' sx={{color:'#d32f2f'}} 
                    disabled={state?.userId === undefined || state?.userId === null ||
                     status?.toLowerCase() === 'deactivated'}
                    onClick={()=>{setShowConfirmationModal(true);}}> Deactivate User</Button>
                </Grid>
              </Grid>
            </CardContent>
          </StyleCard>
          <Grid display={'flex'} justifyContent={'center'} mt={'20px'}>
            <Button variant='outlined' sx={{mr:'16px'}} 
              onClick={()=>{navigate(ROUTE.ADMIN_USER_MANAGEMENT);}}>Cancel</Button>
            <Button type='submit' disabled={status?.toLowerCase() === 'deactivated'}>
              {state?.userId ? 'Update' : 'Create'}</Button>
          </Grid>
        </Box>
      </Container>
      
      <Dialog  aria-labelledby="success-dialog"  open={showConfirmationModal} 
        sx={{ 
          '&.MuiDialog-paper':{ 
            p:'24px'
          }
        }}>
        <DialogTitle id="simple-dialog-title" sx={{padding:'30px 30px 0 '}}>
          <Typography variant='h2'>Deactivate Account</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            mt={'36px'}
          > 
            <Typography sx={{fontSize:'18px', textAlign:'center'}}>
              Deactivating this account will remove the VMA’s access to the MindWeal platform. </Typography>    
            <Typography sx={{fontSize:'18px', mt:'20px'}}>Are you sure you want to proceed?</Typography>       
          </Grid>
        </DialogContent> 
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          
          <Button variant='outlined' onClick={() => setShowConfirmationModal(false)} sx={{mr: '20px' }}> Cancel</Button>
          <Button onClick={() => handleDeactivate()} 
            disabled={state?.userId === undefined || state?.userId === null}
            sx={{ minWidth: { xs: '140px', sm: '200px' }, background: '#F04438', color: '#fff',
              '&:hover': {
                background: '#F04438'
              }
            }}> Deactivate </Button>
                  
        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default CreateUser;

