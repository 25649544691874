import { CardMedia, Container, Stack, Typography, Grid } from '@mui/material';
import logo from 'assets/images/logo.svg';
import CloudOffIcon from '@mui/icons-material/CloudOff';

const Offline = () => {
  return (
    <Stack
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{ width: 1, height: '100vh', background:'#FFECC8'}}
    >
      <Container component={'section'} maxWidth='md'>
        <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
          <CardMedia
            component='img'
            src={logo}
            alt='logo'
            sx={{ width: '42px', height: '42px' }}
          />
        </Grid>
        <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
          <CloudOffIcon sx={{mt:'15px'}}/>
          <Typography variant='h3' ml='10px' mt={'15px'}>You&apos;r Offline</Typography>
        </Grid>
      </Container>

    </Stack>
  );
};

export default Offline;