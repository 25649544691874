import React, { useEffect, useState, useContext } from 'react';
import DashboardOnboarding from './DashboardOnboarding';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardProps,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Link,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router';

import { useAppDispatch } from 'store/hooks';
import { getDocumentList } from 'store/slices/documentSlice';
import { getProfileStatus, getPatientDetails } from 'store/slices/userSlice';
import { getMCATStatus, getMFSHLink, getMFSHStatus,
  getWellbeingStatus,getMCATScore, getWellbeingScore } from 'store/slices/mcatSlice';

import COLORS from 'theme/colors';
import { useAuth } from 'utils/AuthContext';
import DashboardFooter from './DashboardFooter';
import { ROUTE } from 'route/RouteEnums';
import AppointmentStatus from './AppointmentStatus';
import { toast } from 'react-toastify';
import { getAppointmentConfirmationDetails } from 'store/slices/appointmentSlice';

const Dashboard = (props: any) => {
  const StyleCard = styled(Card)<CardProps>(({ theme }) => ({
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
    border: '1px solid #EFF4FF',
    borderRadius: 12,
    background: '#FFF',
    margin: '12px 16px',
    position: 'relative',
    zIndex: 99,
    '& .MuiTypography-root': {
      marginBottom: '8px',
      position: 'relative',
    },
  }));

  const [isOpen, setIsOpen] = useState(sessionStorage.getItem('firstimelogin') === 'yes' ? true : false);
  const [clinicalDocument, setClinicalDocuments] = useState<string>('incomplete');
  const [profileStatus, setProfileStatus] = useState<string>('incomplete');
  const [mcatStatus, setmcatStatus] = useState<string>('');
  const [displayModal] = useState<boolean>(false);
  const [wellbeingStatus, setWellbeingStatus] = useState<any>();
  const [mfshStatus, setmfshStatus] = useState<string>('incomplete');
  const [mcatScore, setMcatScore] = useState();
  const [wellbeingScore, setWellbeingScore] = useState();
  const [wellbeingScoreCategory, setWellbeingScoreCategory] = useState<string>();
  const [mcatScoreCategory, setMcatScoreCategory] = useState<string>();
  const [trackYourProgress, setTrackYourProgress] = useState<boolean>(false);
  const [migrationStatus, setMigrationStatus] = useState<boolean>(false);
  const [appointmentDateStatus, setAppointmentDateStatus] = useState<string>('');

  const navigate = useNavigate();
  const { user, setUserDetails } = useAuth();
  const dispatch = useAppDispatch();
  const isSm = useMediaQuery('(max-width:900px)');

  
  useEffect(() => {
    window.onpopstate = () => {
      navigate(ROUTE.DASHBOARD);
    };

    dispatch(getDocumentList(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200 && response?.data?.documentInfos !== undefined) {
          setClinicalDocuments(response?.data?.documentInfos[0]?.status);
        }
      });

    dispatch(getProfileStatus(user.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          const { data } = response;
          if (
            data?.patientInfoStatus &&
            data?.guardianInfoStatus &&
            data?.childProviderInfoStatus &&
            data?.pharmacyInfoStatus &&
            data?.paymentInfoStatus
          ) {
            setProfileStatus('COMPLETED');
            const data = {
              ...user,
              profileStatus: 'COMPLETED',
            };
            setUserDetails(data);
          } else {
            setProfileStatus('incomplete');
            const data = {
              ...user,
              profileStatus: 'incomplete',
            };
            setUserDetails(data);
          }
        }
      });

    dispatch(getMCATStatus(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setmcatStatus(response?.data);
        }
      });

    dispatch(getWellbeingStatus(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setWellbeingStatus(response?.data);
          setTrackYourProgress(response?.data?.trackYourProgress);
        }
      });

    dispatch(getMFSHStatus(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setmfshStatus(response?.data);
        }
      });
      

    dispatch(getPatientDetails(user?.patientId))
      .unwrap()
      .then((response:any) => {
        if(response?.status === 200){
          if(response.data.migrationStatus === 'MG-A' || response.data.migrationStatus === 'MG-R')
          {
            setMigrationStatus(true);
          }
        }
      });
   

    dispatch(getMCATScore(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200 ) {
          setMcatScore(response?.data?.mcatIScore);
          const mcatScore = response?.data?.mcatIScore;
          if( mcatScore < 50) { setMcatScoreCategory('In Crisis');}
          else if( mcatScore > 50 && mcatScore < 100) {setMcatScoreCategory('Critical');}
          else if( mcatScore > 101 && mcatScore < 225) {setMcatScoreCategory('Struggling');}
          else if( mcatScore > 226 && mcatScore < 325) {setMcatScoreCategory('Vulnerable');}
          else if( mcatScore > 326 && mcatScore < 400) {setMcatScoreCategory('Balanced');}
          else if( mcatScore > 401 && mcatScore < 450) {setMcatScoreCategory('Stable');}
          else if( mcatScore > 451 && mcatScore < 500) {setMcatScoreCategory('Thriving');}       
        }
      });

    dispatch(getWellbeingScore(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          const wellbeingScore = response?.data?.wellnessScore; 
          setWellbeingScore(wellbeingScore);                   
          if( wellbeingScore < 100) { setWellbeingScoreCategory('In Crisis');}
          else if( wellbeingScore > 100 && wellbeingScore < 200) {setWellbeingScoreCategory('Critical');}
          else if( wellbeingScore > 200 && wellbeingScore < 500) {setWellbeingScoreCategory('Struggling');}
          else if( wellbeingScore > 500 && wellbeingScore < 600) {setWellbeingScoreCategory('Vulnerable');}
          else if( wellbeingScore > 600 && wellbeingScore < 800) {setWellbeingScoreCategory('Balanced');}
          else if( wellbeingScore > 800 && wellbeingScore < 900) {setWellbeingScoreCategory('Stable');}
          else if( wellbeingScore > 900 && wellbeingScore < 1000) {setWellbeingScoreCategory('Thriving');}       
        }
      });

    dispatch(getAppointmentConfirmationDetails(user?.patientId))
      .unwrap()
      .then((response:any) => {
        if(response.status===200){
          const data = new Date(response.data.upcomingAppointmentDateTime);
          if(new Date(data) <= new Date()){
            setAppointmentDateStatus('datepassed');
          }
        }
      });
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user.userId, user?.patientId, profileStatus]);


  const handleModalClose = () => {
    setIsOpen(false);
    sessionStorage.removeItem('firstimelogin');
  };

  const handleStartTest = () => {
    if(mfshStatus === 'COMPLETED' && !migrationStatus){
      mcatStatus === 'PENDING' ? navigate(ROUTE.MCAT_ASSESSMENT) : navigate(ROUTE.MCAT_TEST);
    }else if(migrationStatus && clinicalDocument === 'Completed' && profileStatus === 'COMPLETED'){
      mcatStatus === 'PENDING' ? navigate(ROUTE.MCAT_ASSESSMENT) : navigate(ROUTE.MCAT_TEST);
    }else{
      toast('Please complete medical & family history to proceed with this step.',
        {
          type:'info',
          icon:true
        }
      );
    }
  };

  const handleWellbeing = (val:string) => {
    
    if(mcatStatus !== 'COMPLETED' && migrationStatus === false){
      toast('Please complete M-CAT to proceed with this step.',
        {
          type:'info',
          icon:true
        }
      );
    } else if(migrationStatus && clinicalDocument !== 'Completed'){
      toast('Please sign policies & consents to proceed with this step. Check your email for more info.',
        {
          type:'info',
          icon:true
        }
      );
    }
    else{
      navigate(`${ROUTE.WELLBEING_ASSESSMENT}`, { state: { wellbeingUser: val } });
    }
  };

  const handleWellnessDetails=()=>{
    navigate(`${ROUTE.WELLNESS_DASHBOARD}`);
  };

  const handleMCATDetails=()=>{
    navigate(`${ROUTE.MCAT_DASHBOARD}`);
  };

  const handleViewDetails=()=>{
    navigate(`${ROUTE.TREATMENT}`);
  };

  const handleJotForm=()=>{
    if(profileStatus !== 'COMPLETED'){
      toast('Please fill the profile info first', {
        type:'info',
        icon:true
      });
    } else if(clinicalDocument !== 'Completed'){
      toast('Please sign policies & consents to proceed with this step. Check your email for more info.',{
        type:'info',
        icon:true
      });
    }else{
      const windowReference = window.open();
      dispatch(getMFSHLink(user?.patientId))
        .unwrap()
        .then((response: any) => {
          if (response?.status === 200) {        
            if(windowReference){
              windowReference.location = `//${response?.data?.url}`;
            }
          }
        });
    }
  };



  return (
    <>
      <DashboardOnboarding isOpen={isOpen} handleModalClose={handleModalClose}></DashboardOnboarding>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Container component='section' maxWidth='md'>
            <Typography
              variant='h5'
              position={'relative'}
              width={'fit-content'}
              sx={{ paddingTop: 6, textTransform: 'capitalize' }}
            >
              hello {user?.firstName},
              <CardMedia
                component={'img'}
                src='images/vector.svg'
                alt='heading divider'
                sx={{ position: 'absolute', right: 0, width:{xs:60,sm:108}, height: 'auto' }}
              />
            </Typography>
            <Grid container marginTop={4}>

              {/* --------------Profile Setup--------------- */}
              {profileStatus === 'incomplete' ?
                <Grid item xs={12} md={4}>
                  <StyleCard>
                    <CardContent sx={{ maxHeight: 180 }}>
                      <Typography variant='h2'>Profile Setup</Typography>
                      <Typography variant='body1'>
                      Share demographic details and guardian information to ensure the security and privacy of your
                      data.
                      </Typography>
                      {profileStatus === 'incomplete' ?
                        <Link href={ROUTE.PROFILE} variant='subtitle1'>
                        Let&apos;s Start
                        </Link> :
                        <Link href={ROUTE.PROFILE} variant='subtitle1'>
                        Edit Profile
                        </Link>
                      }
                    </CardContent>
                  </StyleCard>
                </Grid> : null}              
              {/* -------------- Appointment ------------- */}
              <Grid item xs={12} md={ profileStatus === 'incomplete'? 8 : 12 } 
                sx={{opacity: profileStatus === 'incomplete' ? 0.5 : 1}}>
                <AppointmentStatus profileStatus={profileStatus} />
              </Grid>
            
              {/* -------------- Documents ---------------- */}
              {profileStatus === 'COMPLETED' && clinicalDocument !== 'Completed' ? (
                <Grid item xs={12} >
                  <StyleCard>
                    <CardContent sx={{ position: 'relative' }}>
                      <CardMedia
                        component={'img'}
                        src='images/Group 9550.png'
                        alt='card-img'
                        sx={{ width: 228, height: 'auto', position: 'absolute', right: 0, top: 0 }}
                      />
                      <CardMedia
                        component={'img'}
                        src='images/Ellipse 1.png'
                        alt='card-img'
                        sx={{ width: 64, height: 'auto', position: 'absolute', left: 0, bottom: 0 }}
                      />
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant='h2'>Policies & Consents</Typography>
                        </Grid>
                        <Grid item xs={12}>                          
                          {clinicalDocument === 'Completed'
                            ? 
                            <Typography variant='body1' sx={{ zIndex: 888 }}>
                               All your documents are up to date and uploaded! </Typography>
                            : 
                            <Typography variant='body1' sx={{ zIndex: 888 }}>                              
                              We have emailed you our policies and treatment consents. Check your inbox 
                              for a message from &quot;MindWeal via Docusign&quot;, review the documents, 
                              and sign as needed. Then, return to the app to complete the onboarding steps.
                            </Typography>
                          }                          
                        </Grid>                        
                      </Grid>
                    </CardContent>
                  </StyleCard>
                </Grid>
              ) : null}


              {/* -------------------3rd row ------------------------------- */}
              {/* ------------------ MFSH -------------- */}
              {mfshStatus !== 'COMPLETED' && migrationStatus === false ?
                <Grid item xs={12} 
                  sx={{opacity: profileStatus === 'COMPLETED' && clinicalDocument === 'Completed' ? 1 : 0.5}}>
                  <StyleCard>
                    <CardContent >
                      <Grid container spacing={1} >
                        <Grid item xs={12} md={2}>
                          <CardMedia
                            component={'img'}
                            src='images/Group 9699.svg'
                            alt='card-img'
                            sx={{ width: '97px', height: 'auto' }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} mt={'10px'}>
                          <Typography variant='h2'>Medical and family history</Typography>
                          <Typography variant='body1'>
                          Let&apos;s use this safe space to provide your child&apos;s medical and family history.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} mt={'25px'} textAlign={'end'}>
                          <Button variant='outlined' onClick={handleJotForm}>
                          let&apos;s start</Button>                        
                        </Grid>
                      </Grid>
                    </CardContent>
                  </StyleCard>
                </Grid> : null }


              {/* -------------------4th row ------------------------------- */}
              {/* --------------- MCAT -------------- */}
              {/* wellbeing status is used to managed width of grid only */}
              {mcatStatus !== 'COMPLETED' && migrationStatus === false ?
                <Grid item xs={12} 
                  md={wellbeingStatus?.childStatus === 'NEW' || wellbeingStatus?.childStatus === 'PENDING'   
                || wellbeingStatus?.parentStatus === 'NEW' || wellbeingStatus?.parentStatus === 'PENDING' ? 6 : 12} 
                  sx={{opacity: migrationStatus === false && profileStatus === 'COMPLETED' && 
                  clinicalDocument === 'Completed' && mfshStatus === 'COMPLETED' ? 1 : 
                    profileStatus === 'COMPLETED' && 
                  clinicalDocument === 'Completed' && migrationStatus !== false && mfshStatus === 'NEW' ? 1 : 0.5}}>
                  <StyleCard>
                    <CardContent sx={{ maxHeight: 188, position: 'relative' }}>
                    
                      <Typography variant='h2'>Complete M-CAT</Typography>
                      <Typography variant='body1'>
                          Equip our providers with crucial information about your child&apos;s mental
                          health by completing the comprehensive and {!isSm ? <br /> : null}interactive assessment.
                      </Typography>
                      <Link onClick={handleStartTest}
                        variant='subtitle1'
                        style={{ cursor: 'pointer' }}                        
                      >
                          Start test
                      </Link>
                                       
                      <CardMedia
                        component={'img'}
                        src='images/macat_ellipse.png'
                        alt='mcat test'
                        sx={{ height: 'auto', width: '100%', maxWidth: 80, position: 'absolute', bottom: 0, right: 0 }}
                      />
                    </CardContent>
                  </StyleCard>
                </Grid>
                : null
              }                         
              {/* --------- Wellbeing ----------------*/}
              {trackYourProgress && (wellbeingStatus?.childStatus === 'NEW' 
                || wellbeingStatus?.childStatus === 'PENDING'
                || wellbeingStatus?.parentStatus === 'NEW' || wellbeingStatus?.parentStatus === 'PENDING') ?
                
                <Grid item xs={12} md={ (!migrationStatus && mcatStatus === 'COMPLETED') || migrationStatus ? 12 : 6} 
                  sx={{
                    opacity: migrationStatus === false && profileStatus === 'COMPLETED' && 
                  clinicalDocument === 'Completed' && mfshStatus === 'COMPLETED'  && mcatStatus === 'COMPLETED' ? 1 : 
                      migrationStatus === true && profileStatus === 'COMPLETED' && 
                  clinicalDocument === 'Completed' && mfshStatus === 'NEW' ? 1 : 0.5}}>
                  <StyleCard>
                    <CardContent sx={{ position: 'relative' }}>
                      <CardMedia
                        component={'img'}
                        src='images/Group 9505.png'
                        alt='card-img1'
                        sx={{ width: 75, height: 'auto', position: 'absolute', right: 50, bottom: 0 }}
                      />
                      <CardMedia
                        component={'img'}
                        src='images/Vector 12.png'
                        alt='card-img1'
                        sx={{ width: 65, height: 'auto', position: 'absolute', right: 10, bottom: 0 }}
                      />
                      <Typography variant='h2'>Track Your Progress</Typography>
                      <Typography variant='body1'>
                        Complete a brief questionnaire to monitor your journey and assess your overall wellness.
                      </Typography>
                      <Typography mt={4.5}>
                        { wellbeingStatus?.parentStatus === 'NEW' || wellbeingStatus?.parentStatus === 'PENDING' ?
                          <Link onClick={()=>handleWellbeing('parent')} variant='subtitle1' 
                            style={{ cursor: 'pointer', margin:'0 10px 0 0' }}>
                          &nbsp; Parent Survey 
                          </Link> : null }
                        {wellbeingStatus?.childStatus === 'NEW' || wellbeingStatus?.childStatus === 'PENDING' ?
                          <Link onClick={() => handleWellbeing('child')}
                            variant='subtitle1' style={{ cursor: 'pointer' }}>
                          &nbsp; Child Survey 
                          </Link> : null }
                      </Typography>
                    </CardContent>
                  </StyleCard>
                </Grid> : null}

              {/* -------------------2nd row ------------------------------- */}
              {!trackYourProgress && wellbeingScore !==  undefined ?
                <Grid item xs={12}>
                  <StyleCard>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={8} display={'flex'} flexDirection={'column'}
                          justifyContent={'space-around'}>
                         
                          <Typography variant='h2'>Wellness Score</Typography>
                          <Grid container>
                            <Grid item >
                              <Typography variant='h5' sx={{ span: { fontSize: 14, color: COLORS.Link } }}>
                                {wellbeingScore} 
                              </Typography>
                            </Grid>
                            <Grid item mt={'20px'} ml={'25px'} >
                              <Typography variant='body1'>{wellbeingScoreCategory}</Typography>
                            </Grid>
                          </Grid>
                          <Button variant='text' size='small' sx={{maxWidth:'125px', fontSize:'14px', padding:0}}
                            onClick={handleWellnessDetails}>EXPLORE DETAILS</Button>
                        </Grid>
                        <Grid item xs={12} md={4} 
                          sx={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }}>
                          <CardMedia component={'img'} src='images/wellnessdashboard.svg' alt='welbeing graph'  
                            sx={{width:'130px', height:'130px', objectFit:'fill'}}/>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </StyleCard>
                </Grid> : null}

              { mcatStatus === 'COMPLETED' && mcatScore !== undefined  ?
                <Grid item xs={12}>
                  <StyleCard>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={8} display={'flex'} flexDirection={'column'}
                          justifyContent={'space-around'}>
                       
                          <Typography variant='h2'>MCAT-i Score</Typography>
                          <Grid container>
                            <Grid item >
                              <Typography variant='h5' sx={{ span: { fontSize: 14, color: COLORS.Link } }}>
                                {mcatScore} 
                              </Typography>
                            </Grid>
                            <Grid item mt={'20px'} ml={'25px'} >
                              <Typography variant='body1'>{mcatScoreCategory}</Typography>
                            </Grid>
                          </Grid>
                          <Button variant='text' size='small' sx={{maxWidth:'125px', fontSize:'14px', padding:0}}
                            onClick={handleMCATDetails}>EXPLORE DETAILS</Button>
                        </Grid>
                        <Grid item xs={12} md={4} 
                          sx={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }}>
                          <CardMedia component={'img'} src='images/mcatIScorewidget.svg' alt='welbeing graph'  
                            sx={{width:'130px', height:'130px', objectFit:'fill'}}/>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </StyleCard>
                </Grid> : null}

              {mcatScore !== undefined && wellbeingScore !==  undefined ? 
                <Grid item xs={12}>
                  <StyleCard>
                    <CardContent>
                      <CardMedia
                        component={'img'}
                        src='/images/Group-9550-1.png'
                        alt='card-img'
                        sx={{ width: 389, height: 'auto', position: 'absolute', right: 0, top: 0 }}
                      />
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                  
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'space-between'}
                          gap={1.5}
                        >
                          <Typography variant='h2'>Treatment Plan</Typography>
                          <Grid container >
                            <Grid item xs={8}>
                              <Typography variant='subtitle1'>
                        Click for active diagnoses & current medications.
                              </Typography>
                            </Grid>
                            <Grid item xs={4} alignItems={'end'} textAlign={'end'}>
                              <Button variant='text' size='small' sx={{maxWidth:'125px', fontSize:'14px', padding:0}}
                                onClick={handleViewDetails}>VIEW DETAILS</Button>
                            </Grid>
                          </Grid>                      
                        </Grid> 
                      </Grid>
                    </CardContent>
                  </StyleCard>
                </Grid>
                : null} 

              {/* -------------------4th row ------------------------------- */}
              {mcatStatus === 'COMPLETED' && appointmentDateStatus === '' ? 
                <Grid item xs={12} >
                  <StyleCard>
                    <CardContent sx={{ background: '#FFECC8' }}>
                      <Grid container >
                        <Typography variant='h2'>What&apos;s Next on Your Path to Wellness</Typography>
                        <Grid item xs={12} md={9}>

                          <Typography variant='h4'> 1. Schedule Your New Patient Visit </Typography>
                          <Typography variant='body1'>Book an appointment for a comprehensive evaluation of your 
                          child&apos;s emotional, cognitive, and behavioral functioning with a board-certified provider.
                          This will ensure an accurate diagnosis and the development of a tailored treatment plan.
                          </Typography>

                          <Typography variant='h4'> 2. Choose a Wellness Plan </Typography>
                          <Typography variant='body1'>After the visit, select a wellness plan based on the tailored
                           treatment plan. Your child will begin their journey to well-being with a dedicated provider.
                          </Typography>
                        

                          <Typography variant='h4'> 3. Follow Your Wellness Plan </Typography>
                          <Typography variant='body1'>Receive prompt and convenient care according to the wellness plan 
                          you chose. Access psychiatry medication management and one-to-one therapy services through 
                          virtual or in-person visits.
                          </Typography>
                        

                          <Typography variant='h4'> 4. Equip Yourself with Self-Guided Tools </Typography>
                          <Typography variant='body1'>Utilize self-guided care tools to support your child&apos;s
                           progress towards mental and emotional wellness.
                          </Typography>

                          <Typography variant='h4'> 5. Monitor Your Child&apos;s Progress </Typography>
                          <Typography variant='body1'>Track and celebrate your child&apos;s progress with wellness 
                          surveys completed before each appointment. These surveys, along with feedback from your 
                          child&apos;s healthcare professional, provide a comprehensive view of their ongoing 
                          progress and the impact of their treatment plan.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}
                          alignItems={'flex-end'}
                          spacing={1}
                          direction="column"
                          justifyContent="flex-end"
                          sx={{ pl: { xs: '50px', md: '25px' } }}
                        >

                          <CardMedia
                            component={'img'}
                            src='images/heart.svg'
                            alt='card-img1'
                            sx={{ width: 160, height: 'auto' }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </StyleCard>
                </Grid>
                :null}
      

            </Grid>
          </Container>
        </Grid >
        
        < Grid item xs={12} sx={{ padding: '26px 0' }
        }>
          {/* <GotQuestions /> */}
        </Grid >
        <Grid item xs={12} sx={{ background: '#F6F9FF', padding: '35px 0' }}>
          <DashboardFooter />
        </Grid>
      </Grid >

      <Dialog open={displayModal} fullWidth={false}>
        <DialogContent>
          <Typography variant='h3' textAlign={'center'}>
            Profile completion
          </Typography>
          <Box m={1}>
            <Typography mt={2}>Kindly fill out your profile details before starting your test.</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button sx={{ marginTop: 4 }} onClick={() => navigate(ROUTE.PROFILE)}>
              Okay
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

Dashboard.propTypes = {};

export default Dashboard;

