import {useContext, useEffect, useState } from 'react';
import {Button, Container, Grid, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  gridClasses,
} from '@mui/x-data-grid';
import COLORS from 'theme/colors';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { getAllUsers } from 'store/slices/userManagementSlice';
import { ROUTE } from 'route/RouteEnums';
import { useAppDispatch } from 'store/hooks';
import { AuthContext } from 'utils/AuthContext';


dayjs.extend(utc);
dayjs.extend(timezone);

function UserManagement() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any[] | null>([]);
  const [loading, setLoading] = useState(true);
  const {user} = useContext(AuthContext);

  const columns: GridColDef[] = [   
    {
      field: 'vmaName',
      headerName: 'Name',
      flex:1,
      renderCell:(params:any)=>{
        return(
          <>
            {params?.row?.firstName + ' ' + params?.row?.lastName}
          </>
        );
      }
    },
    {
      field:'position',
      headerName:'Position',
      flex:0.7
    },
    {
      field:'roleType',
      headerName:'Role',
      filterable:false,
      sortable:false, 
      flex:0.5 
    },
    {
      field: 'assignedDate',
      headerName: 'Assigned Date',
      description: 'Registration Date',      
      flex:0.7,
      type:'date',
      valueFormatter: (params:any) => dayjs(params?.row?.assignedDate).utc().format('MM/DD/YYYY'),
      valueGetter:(params:any) => dayjs(params?.row?.assignedDate).utc().format('MM/DD/YYYY'),
      renderCell: (params: any) => {
        return params?.row?.assignedDate ?  
          <> {dayjs(params?.row?.assignedDate).utc().format('MM/DD/YYYY').substring(0,10)}
          </>: '';
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      renderCell:(params:any)=>{        
        return(
          <>
            <Typography 
              sx={{
                background: '#F6FEF9',
                color: params?.row?.status === 'Deactivated' ? '#B42318' : '#027A48',
                border: params?.row?.status === 'Deactivated' ? '1px solid #B42318' : '1px solid #027A48',
                borderRadius: '12px', padding: '2px 10px 0px 10px',
              }}> 
              {params?.row?.status}
            </Typography>
          </>
        );
      }
    },
  ];

  const rowSelect = (params: any) => {
    navigate(`${ROUTE.ADMIN_USER_CREATE}/${params.row.userId}`, { state: { userId: params.row.userId } });
  };
  

  useEffect(() => {

    if(!user?.permissions.includes('USER_MANAGEMENT')){
      navigate(ROUTE.ADMIN_DASHBOARD);
    }

    window.onpopstate = () => {
      navigate(ROUTE.ADMIN_DASHBOARD);
    };

    dispatch(getAllUsers({}))
      .unwrap()
      .then((res:any) => {
        if(res?.status === 200){
          setData(res.data);
          setLoading(false);
        }
      });
    //eslint-disable-next-line
  }, []);


  const handleCreateUSer=()=>{
    navigate(`${ROUTE.ADMIN_USER_CREATE}`);
  };

  return (
    <Container maxWidth='lg'>
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={6}>
          <Typography
            variant='h5'
            position={'relative'}
            width={'fit-content'}
            sx={{ paddingTop: 6, textTransform: 'capitalize' }}
          >
          User Management
          </Typography>
        </Grid>
        <Grid item xs={2}  >
          <Button sx={{ marginTop: 6, textTransform: 'capitalize' }} onClick={handleCreateUSer}>Create New User</Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ backgroundColor: COLORS.White }}>
           
        <DataGrid
          autoHeight
          loading={loading}
          rows={data ? data : []}          
          getRowId={(row: any) => row.userId}
          columns={columns}
          onRowClick={rowSelect}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          pageSizeOptions={[10, 25, 50]}
          rowSpacingType='margin'
          getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          style={{ cursor: 'pointer' }}
          sx={{
            border: 'none',
            backgroundColor: COLORS.LightYellow,
            fontSize: 14,
            fontWeight: 400,
            '.MuiDataGrid-toolbarContainer': {
              background: '#FFFBF1',
            },
            '.MuiDataGrid-columnHeaders': {
              marginBottom: 2,
              boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.16)',
            },
            '.MuiDataGrid-columnHeadersInner': {
              background: COLORS.White,
            },
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 600,
            },
            [`& .${gridClasses.row}.even`]: {
              backgroundColor: COLORS.White,
              '&:hover, &.Mui-hovered': {
                backgroundColor: COLORS.LightBlue,  
              },
            },
            [`& .${gridClasses.row}.odd`]: {
              backgroundColor: COLORS.LightGray,
              '&:hover, &.Mui-hovered': {
                backgroundColor: COLORS.LightBlue,
              },
            },
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
              outline: 'none !important',
            },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 400,
              fontSize: '16px',
              color: '#000000',
              lineHeight: '24px'
            },
          }}
        />        
      </Grid>
    </Container>
  );
}

export default UserManagement;

