// export const LANDING:string:"/";
export enum ROUTE {
  HOME = 'https://mindweal.com/',
  INSTALL = '/install',
  LOGIN = '/',
  SIGNUP = '/signup',
  MIGRATION_SIGNUP = '/migration/signup',
  TRANSFER_SIGNUP ='/transfer/signup',
  THEME = '/theme',
  FORGOTPASSWORD = '/forgotpassword',
  EMAILVERIFICATION = '/emailverification',
  WELCOME = '/welcome',
  RESETPASSWORD = '/resetpassword/:token',
  PREVERIFY = '/preverify',
  DASHBOARD = '/dashboard',
  SUCCESSEMAIL = '/successemail',
  SUCCESSPASSWORD = '/successpassword',
  PROFILE = '/profile',
  PATIENT_INFORMATION = '/profile/patient',
  GUARDIAN_INFORMATION = '/profile/guardian',
  PROVIDER_INFORMATION = '/profile/provider',
  PHARMACY_DETAILS = '/profile/pharmacy',
  INSURANCE_DETAILS = '/profile/insurance',
  SIGN_OUT = '/signout',
  VALIDATE_USER = '/verifyemail/:token',
  TREATMENT = '/treatment',
  REPORTS = '/reports',
  HELP = '/help',
  PROFILE_DASHBOARD = '/profile/dashboard',
  DOCUMENTS = '/documents',
  MCAT_TEST = '/m-cat',
  MCAT_INTRO = '/m-cat/intro',
  MCAT_ADVISORY = '/m-cat/advisory',
  MCAT_NAVIGATE='/m-cat/navigate',
  MCAT_ASSESSMENT = '/m-cat/assessment',
  WELLBEING = '/wellbeing',
  WELLBEING_ASSESSMENT = '/wellbeing/assessment',
  MEETINGS = '/meetings',
  DIY_POSTS = '/diyposts',
  FAQ = '/faq',
  PRIMARY_GOALS = '/primarygoals',
  WELLNESS_DASHBOARD='/profile/wellness',
  MCAT_DASHBOARD='/profile/mcat',
  MCAT_RESULT = '/profile/mcatresult',
  ACCOUNT_MANAGEMENT='/profile/request',
  UPDATE_EMAIL_PHONE='/profile/request/contact',
  TRANSFER_ACCOUNT_OWNERSHIP='/profile/request/transfer',
  DEACTIVATE_ACCOUNT ='/profile/request/deactivate',
  ADMIN_DASHBOARD = '/admin/dashboard',
  ADMIN_PATIENT_INFO = '/admin/patientinfo',
  ADMIN_PATIENT_PROFILE = '/admin/patientprofile',
  ADMIN_PATIENT_INFORMATION = '/admin/patientprofile/patient',
  ADMIN_GUARDIAN_INFORMATION = '/admin/patientprofile/guardian',
  ADMIN_PROVIDER_INFORMATION = '/admin/patientprofile/provider',
  ADMIN_PHARMACY_DETAILS = '/admin/patientprofile/pharmacy',
  ADMIN_INSURANCE_DETAILS = '/admin/patientprofile/insurance',
  ADMIN_MESSAGES = '/admin/messages',
  ADMIN_APPOINTMENT_DETAILS = '/admin/appointment',
  SYSTEM_VITALS = '/vitals',
  ADMIN_PROFILE = '/admin/profile',
  ADMIN_TASKLIST='/admin/tasklist',
  ADMIN_CONVERSATION_LIST = '/admin/conversations',
  ADMIN_ACCOUNT_MANAGEMENT_REQUEST='/admin/requests',
  ADMIN_UPDATE_EMAIL_PHONE='/admin/request/contact',
  ADMIN_TRANSFER_ACCOUNT_OWNERSHIP='/admin/request/transfer',
  ADMIN_DEACTIVATE_ACCOUNT ='/admin/request/deactivate',
  ADMIN_USER_MANAGEMENT='/admin/users',
  ADMIN_USER_CREATE='/admin/users/create'
}
