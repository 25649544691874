import { apiInterceptor } from './axios';
import { API_URL } from 'shared/api/Constants';

export const getGuardianDetailsAPI = (data:any) => {
  return apiInterceptor.get(`${API_URL.GET_GUARDIAN_DETAILS}/${data.patientId}/${data.requestType}`)
    .then((response: any) => {
      return response;
    });
};

export const checkDuplicateRequestAPI = (patientId:any) => {
  return apiInterceptor.get(`${API_URL.CHECK_DUPLICATE_REQUEST}/${patientId}`)
    .then((response: any) => {
      return response;
    });
};

export const sendRequestAPI=(data:any) => {
  return apiInterceptor.post(`${API_URL.SEND_REQUEST}`, data)
    .then((response:any) => {
      return response;
    });
};

export const getAllRequestsAPI=(patientId:any) => {
  return apiInterceptor.get(`${API_URL.GET_ALL_REQUESTS}/${patientId}`)
    .then((response:any) => {
      return response;
    });
};

export const getRequestsDetailsAPI=(ticketId:any) => {
  return apiInterceptor.get(`${API_URL.GET_ALL_REQUESTS}/service/${ticketId}`)
    .then((response:any) => {
      return response;
    });
};


export const getContactDetailsAPI=(data:any) => {
  return apiInterceptor.get(`${API_URL.GET_CONTACT_DETAILS}/${data?.patientId}/${data?.requestType}`)
    .then((response:any) => {
      return response;
    });
};

export const getAllRequestsForAdminAPI=() => {
  return apiInterceptor.get(`${API_URL.GET_ALL_REQUESTS}`)
    .then((response:any) => {
      return response;
    });
};

export const actionRequestAPI=(data:any)=>{
  return apiInterceptor.put(`${API_URL.SEND_REQUEST}`, data)
    .then((response: any) => {
      return response;
    });
};
