import { apiInterceptor } from './axios';
import { API_URL } from 'shared/api/Constants';

export const signUpAPI = (data: any, callback: any) => {
  apiInterceptor
    .post(API_URL.SIGN_UP, data)
    .then((response: any) => {
      callback(response);
    })
    .catch((error) => {
      callback(error);
    });
};

export const migrateAPI = (data: any, callback: any) => {
  apiInterceptor
    .post(API_URL.MIGRATE, data)
    .then((response: any) => {
      callback(response);
    })
    .catch((error) => {
      callback(error);
    });
};

export const transferAPI = (data: any, callback: any) => {
  apiInterceptor
    .post(API_URL.TRANSFER, data)
    .then((response: any) => {
      callback(response);
    })
    .catch((error) => {
      callback(error);
    });
};

export const signInAPI = (data: any) => {
  return apiInterceptor.post(API_URL.SIGN_IN, data).then((response: any) => {
    return response;
  });
};

export const getUserDetailsAPI = (userId: any) => {
  return apiInterceptor.get(`${API_URL.GET_USER_DETAILS}${userId}`).then((response: any) => {
    return response;
  });
};

export const sendQueryAPI = (data: any, callback: any) => {
  apiInterceptor
    .post(API_URL.SEND_QUERY, data)
    .then((response: any) => {
      callback(response);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const accountConfirmationAPI = (params: any, callback: any) => {
  const token = { emailToken: params };
  apiInterceptor
    .put(`${API_URL.ACCOUNT_CONFIRMATION}`, token)
    .then((response: any) => {
      callback(response);
    })
    .catch((error) => {
      callback(error);
    });
};

export const sendResetPasswordEmailAPI = (params: any, callback: any) => {
  const data = { email: params };
  apiInterceptor
    .post(`${API_URL.SEND_RESET_PASSWORD_EMAIL}`, data)
    .then((response: any) => {
      callback(response);
    })
    .catch((error) => {
      callback(error);
    });
};

export const getEmailAPI = (params: any, callback: any) => {
  const data = { emailToken: params };
  apiInterceptor
    .post(`${API_URL.GET_EMAIL_API}`, data)
    .then((response: any) => {
      callback(response);
    })
    .catch((error) => {
      callback(error);
    });
};

export const resetPasswordAPI = (data: any, callback: any) => {
  apiInterceptor
    .put(`${API_URL.RESET_PASSWORD}`, data)
    .then((response: any) => {
      callback(response);
    })
    .catch((error) => {
      callback(error);
    });
};

export const resendEmailVerificationLinkAPI = (params: any, callback: any) => {
  const data = { email: params };
  apiInterceptor
    .post(`${API_URL.RESEND_EMAIL_VERIFICATION_LINK}`, data)
    .then((response: any) => {
      callback(response);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getRegistraTionDetailsAPI = () => {
  return apiInterceptor.get(`${API_URL.GET_REGISTRATION_DETAILS}`).then((response: any) => {
    return response;
  });
};

export const getUserPermissionsAPI = () => {
  return apiInterceptor.get(`${API_URL.USER_PERMISSIONS}`).then((response: any) => {
    return response;
  });
};

export const validateTokenAPI = (token: any) => {
  const data = { emailToken: token };
  return apiInterceptor.post(`${API_URL.VALIDATE_TOKEN}`, data).then((response: any) => {
    return response;
  });
};

export const validateCaptchaAPI = (token: any) => {
  const data = {responseCode:token};
  return apiInterceptor.post(`${API_URL.VALIDATE_CAPTCHA}`, data).then((response: any) => {
    return response;
  });
};

export const refreshTokenAPI = (token: any) => {
  const data = { refreshToken: token };
  return apiInterceptor.post(`${API_URL.REFRESH_TOKEN}`, data).then((response: any) => {
    return response;
  });
};

export const validateZipcodeAPI=(zipcode:number)=>{
  return apiInterceptor.get(`${API_URL.VALIDATE_ZIP_CODE}/${zipcode}`).then((response:any) =>{
    return response;
  });
};
