import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPatientsListAPI, syncProfileAPI, getColumnsAPI, saveColumnsAPI } from 'services/adminServices';
import { handleMessage } from 'shared/api/ErrorMessages';

interface Error {
  data: any;
}

export const getPatientsList = createAsyncThunk('GET_PATIENTS_LIST', async (data:any,{ rejectWithValue }: any) => {
  try {
    const response = await getPatientsListAPI(data);
    return response;
  } catch (err: any) {
    const e = err as Error;
    const status = e?.data?.errorCode;
    const message = handleMessage(status);
    const error = {
      err: err.data,
      message,
      show: false,
    };
    return rejectWithValue(error);
  }
});

export const syncProfile = createAsyncThunk('SYNC_PROFILE', async (id:any,{ rejectWithValue }: any) => {
  try {
    const response = await syncProfileAPI(id.id);
    return response;
  } catch (err: any) {
    const e = err as Error;
    const status = e?.data?.errorCode;
    const message = handleMessage(status);
    const error = {
      err: err.data,
      message,
      show: false,
    };
    return rejectWithValue(error);
  }
});

export const getColumns = createAsyncThunk('GET_COLUMNS', async (id:number,{ rejectWithValue }: any) => {
  try {
    const response = await getColumnsAPI(id);
    return response;
  } catch (err: any) {
    const e = err as Error;
    const status = e?.data?.errorCode;
    const message = handleMessage(status);
    const error = {
      err: err.data,
      message,
      show: false,
    };
    return rejectWithValue(error);
  }
});

export const saveColumns = createAsyncThunk('SAVE_COLUMNS', async (data:any,{ rejectWithValue }: any) => {
  try {
    const response = await saveColumnsAPI(data);
    return response;
  } catch (err: any) {
    const e = err as Error;
    const status = e?.data?.errorCode;
    const message = handleMessage(status);
    const error = {
      err: err.data,
      message,
      show: false,
    };
    return rejectWithValue(error);
  }
});

interface Loader {
  isUserFetched: boolean;
}

interface StateI {
  loaders: Loader;
  error: undefined;
}

const initialState = {
  loaders: {
    isUserFetched: false,
  },
  error: undefined,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearError(state: StateI) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPatientsList.pending, (state: StateI) => {
      state.loaders.isUserFetched = true;
    });
    builder.addCase(getPatientsList.fulfilled, (state: StateI) => {
      state.loaders.isUserFetched = false;
    });
    builder.addCase(getPatientsList.rejected, (state: StateI) => {
      state.loaders.isUserFetched = false;
    });
  },
});

export default adminSlice.reducer;
